<template>
    <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
      <div class="container">
          <!-- <h1 class="heading">Prototype Generator</h1>
          <i class="fa-solid fa-circle-info info" @click="openPrototypeModal"></i> -->
          <div class="input_handlers">
            <div class="pick_color">
              <label for="shadow">Body Color</label>
              <input type="color" v-model="bodyColor" id="shadow" class="body_color">
            </div>
            <div class="pick_color">
              <p>Shadow Color</p>
              <input type="checkbox" id="shadowColor" @change="shadowColorChange" v-model="shadowColor" :disabled="outputOne=='' || outputTwo==''">
              <label for="shadowColor" class="toggleSwitch" :class="{'disableSlider': outputOne=='' || outputTwo==''}"></label>
            </div>
            <input class="slider range_slider" title="Upload 2 Images to change shadow Amount" type="range" v-model="shadowValue" min="0" max="100" step="2" @input="shadow" :disabled="outputOne=='' || outputTwo==''" :class="{'disableBtn': outputOne=='' || outputTwo==''}">
            <div class="added_text_customise">
              <input type="checkbox" v-model="showEditableText" id="textEdit" @change="editText">
              <label for="textEdit" class="textEdit">Add Text</label>
              <div class="edit_text_color" v-if="showEditableText">
                <input type="color" v-model="titleColor" id="title_color" class="title_color">
                <input type="color" v-model="paragraphColor" id="para_color" class="title_color para_color">
              </div>
            </div>
            <button @click="downloadAsImage" class="btn_primary btn" :disabled="outputOne=='' || outputTwo==''" :class="{'disableBtn': outputOne=='' || outputTwo==''}">Download</button>
            <i class="fa-solid fa-circle-info info" @click="openPrototypeModal"></i>
          </div>
  
          <div class="cover_proto">
            <div class="prototype_container" ref="downloadDiv" :class="{'add_gap': !showEditableText}">
              <div class="left_prototype prototype">
                <div class="image_preview" :class="{'img_preview_border':outputOne=='', 'heightUnset': outputOne!=''}">
                  <div class="input_image" v-if="outputOne==''">
                    <input type="file" ref="fileInputOne" class="upload_file" accept="image/*" :id="fileInputIdOne" @change="loadFileOne">
                    <label :for="fileInputIdOne" class="upload_label" id="upload_label_one">Upload <br>Image</label>
                  </div>
                  <div class="image_uploaded" v-else>
                    <img :src="outputOne" draggable="false" />
                  </div>
                </div>
                <img src="../assets/blurLayer.png" alt="" class="blur_layer" v-if="!shadowColor" draggable="false">
                <img src="../assets/blurLayerWhite.png" alt="" class="blur_layer" v-if="shadowColor" draggable="false">
              </div>
              <div class="add_text" v-if="showEditableText">
                <div class="text_added_top added_text">
                  <i class="fa-solid fa-caret-left caret caret_left"></i>
                  <h2 contenteditable="true" class="titleEditable">Your Title Here</h2>
                  <p contenteditable="true" class="paraEditable">Find & Download Free Graphic Resources for Mobile Mockup. 95000+ Vectors, Stock Photos & PSD files.</p>
                </div>
                <div class="text_added_bottom added_text">
                  <i class="fa-solid fa-caret-right caret caret_right"></i>
                  <h2 contenteditable="true" class="titleEditable">Your Title Here</h2>
                  <p contenteditable="true" class="paraEditable">Find & Download Free Graphic Resources for Mobile Mockup. 95000+ Vectors, Stock Photos & PSD files.</p>
                </div>
              </div>
              <div class="right_prototype prototype">
                <div class="image_preview" :class="{'img_preview_border':outputTwo=='', 'heightUnset': outputTwo!=''}">
                  <div class="input_image" v-if="outputTwo==''">
                    <input type="file" ref="fileInputTwo" class="upload_file" accept="image/*" :id="fileInputIdTwo" @change="loadFileTwo">
                    <label :for="fileInputIdTwo" class="upload_label" id="upload_label_two">Upload <br>Image</label>
                  </div>
                  <div class="image_uploaded" v-else>
                    <img :src="outputTwo" draggable="false" />
                  </div>
                </div>
                <img src="../assets/blurLayer.png" alt="" class="blur_layer" v-if="!shadowColor" draggable="false">
                <img src="../assets/blurLayerWhite.png" alt="" class="blur_layer" v-if="shadowColor" draggable="false">
              </div>
            </div>
          </div> 
        </div>
        <transition name="overlayPrototype">
          <div class="overLay" v-if="closePrototype" @click.self="closeModalPrototype">
              <div class="popUp">
                  <i class="fa-solid fa-xmark close" @click="closeModalPrototype"></i>
                  <h1>How to Use</h1>
                  <div class="scroll">
                    <p>Click on <span>Body Color</span> to change the background color of the canvas.</p>
                    <p>Upload two Images of <span>Aspect Ratio 9:16</span> to use the <span>Shadow Color</span> and <span>Slider</span></p>
                    <p>Use <span>Shadow Color</span> to Toggle between White and Black Shadow.</p>
                    <p>Drag the <span>Slider</span> to Change the amount of shadow.</p>
                    <p>Add additional Text by <span>Add Text</span> button.</p>
                    <p><span>Click on</span> the Title and Paragraph to edit it.</p>
                    <p>Change the color of the Title and Paragraph by the above <span>Color pickers</span>.</p>
                    <p class="last">Click on <span>Download</span> to download the whole canvas as an Image.</p>
                  </div>
              </div>
          </div>
        </transition>
    </section>
    <Footer />
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useHead } from '@vueuse/head'
  import Header from "../components/Header.vue"
  import html2canvas from 'html2canvas';
  import Footer from "../components/Footer.vue"
  export default {
    name:"ImageToPrototypeOld",
    components:{Header, Footer},
    computed: {
      user() {
        const store = useStore();
        return store.state.user;
      },
    },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Create beautiful mockups effortlessly from Image: UI Assembly',
      meta: [
        {
          name: `Create beautiful mockups effortlessly from Image: UI Assembly`,
          content: '"Create High-Resolution Mockups Seamlessly in Your Browser Across Multiple Devices. Enables you to create stunning high-resolution mockups right inside your browser within one interface across multiple devices.',
        },
        ],
      })
      return {
        user,
      };
    },
    data() {
      return {
        fromWebsitePerformance: true,
        shadowValue: 0,
        shadowColor: false,
        closePrototype: false,
        showEditableText: false,
        bodyColor: "#429EFF",
        titleColor: "#007bff",
        paragraphColor: "#009cb8",
        outputOne: "",
        outputTwo: "",
        fileInputIdOne: "file_1",
        fileInputIdTwo: "file_2",
        base64Image: null,
        translateX: 0,
        scaleY: 0,
        scaleX: 0
      };
    },
    mounted(){
      this.openPrototype()
    },
    methods: {
      loadFileOne(event) {
        let label = document.querySelector('#upload_label_one')
        const file = event.target.files[0];
        this.outputOne = URL.createObjectURL(file);
        label.style.display = 'none';
      },
      loadFileTwo(event) {
        let label = document.querySelector('#upload_label_two')
        const file = event.target.files[0];
        this.outputTwo = URL.createObjectURL(file);
        label.style.display = 'none';
      },
      shadow() {
        this.translateX = this.shadowValue/2;
        this.scaleY = Number(0.55+(this.shadowValue / 100));
        this.scaleX = Number(0.4+(this.shadowValue / 100));
        const shadowBody = document.querySelectorAll(".blur_layer");
        shadowBody.forEach((element) => {
           element.style.transform = `translatex(${this.translateX}px) scaleY(${this.scaleY}) scaleX(${this.scaleX})`
        });
      },
      shadowColorChange(){
        this.shadow();
        const shadowBody = document.querySelectorAll(".blur_layer");
        shadowBody.forEach((element) => {
            element.style.transform = `translatex(${this.translateX}px) scaleY(${this.scaleY}) scaleX(${this.scaleX})`
        });
      },
      async downloadAsImage() {
        const divToDownload = this.$refs.downloadDiv;
        const canvas = await html2canvas(divToDownload, { useCORS: true });
        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = 'div_as_image.png';
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      openPrototype(){
        setTimeout(() => {
            this.closePrototype = true
        }, 1000);
      },
      closeModalPrototype(){
        this.closePrototype = false
      },
      openPrototypeModal(){
        this.closePrototype = true
      },
      editText(){
        let textEditLabel = document.querySelector('.textEdit');
        if(this.showEditableText==true){
          textEditLabel.innerText = 'Remove Text'
        }else{
          textEditLabel.innerText = 'Add Text'
        }
      }
    },
    watch: {
      bodyColor() {
        const prototype = document.querySelector(".prototype_container");
        prototype.style.backgroundColor = this.bodyColor;
      },
      titleColor(){
        const titleEditable = document.querySelectorAll('.titleEditable');
        const caret = document.querySelectorAll('.caret');
        titleEditable.forEach(obj => {
          obj.style.color = this.titleColor;
        })
        caret.forEach(obj => {
          obj.style.color = this.titleColor;
        })
      },
      paragraphColor(){
        const paraEditable = document.querySelectorAll('.paraEditable');
        paraEditable.forEach(obj => {
          obj.style.color = this.paragraphColor;
        })
      }
    }
  };
  </script>
  
  <style scoped>
  .hero-section {
    cursor: default;
    background: rgba(255, 255, 255, 0.85);
    /* background: linear-gradient(120deg, rgba(255, 255, 255, 0.85) 40%, rgba(148, 255, 250, 0.85) 100%); */
    min-height: 82vh;
    position: relative;
    padding-bottom: 10rem;
  }
  .container{
    width: 95%;
    margin-top: 6rem;
  }
  .overLay{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popUp{
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 520px;
    height: 90vh;
    position: relative;
    width: 90%;
  }
  .popUp .scroll{
    overflow-y: auto;
    height: 90%;
  }
  .close{
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 24px;
    color: #007bff;
    cursor: pointer;
  }
  .popUp h1{
    margin: 0 0 20px 0;
    font-size: 2.4rem;
    border-bottom: 1px solid #c9c9c9;
    padding-bottom: 0.6rem;
  }
  .popUp p{
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
  .popUp .last{
    margin-bottom: 0;
  }
  .popUp span{
    color: #007bff;
    font-weight: 700;
  }
  .input_handlers{
    margin-block: 2rem;
    position: relative;
    max-width: 104rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 1rem;
    background-color: #3e676e;
    border-radius: 0.4rem;
  }
  .range_slider{
    width: 30%;
  }
  .range_slider{
    position: relative;
    display: inline-block;
  }
  
  .range_slider[title]:hover::after {
    content: attr(title);
    position: absolute;
    top: -2.4rem;
    left: 0;
  }
  .disableSlider{
    cursor: not-allowed !important;
  }
  .disableSlider::-webkit-slider-thumb {
    cursor: not-allowed;
  }
  .heading{
    display: inline-block;
    font-size: 3.2rem;
    margin-block: 2rem;
  }
  .info{
    font-size: 2.8rem;
    color: #93ceff;
    margin-left: 12px;
    cursor: pointer;
  }
  .pick_color{
    padding: 0.4rem 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #fff;
    gap: 1rem;
    height: 5rem;
  }
  .body_color {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    width: 3.9rem;
    height: 4.2rem;
    border: none;
    cursor: pointer;
  }
  #shadowColor {
    display: none;
  }
  .toggleSwitch {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 30px;
    background-color: #fff;
    border-radius: 20px;
    cursor: pointer;
    transition-duration: .2s;
  }
  .toggleSwitch::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    left: 5px;
    background-color: transparent;
    border-radius: 50%;
    transition-duration: .2s;
    border: 5px solid #429EFF;
  }
  #shadowColor:checked+.toggleSwitch::after {
    transform: translateX(100%);
    transition-duration: .2s;
    background-color: #429EFF;
  }
  /* Switch background change */
  #shadowColor:checked+.toggleSwitch {
    transition-duration: .2s;
  }
  .body_color::-webkit-color-swatch {
    border-radius: 50%;
    border: 2px solid #ffffff;
  }
  .body_color::-moz-color-swatch {
    border-radius: 50%;
    border: 7px solid #000000;
  }
  .cover_proto{
    overflow-x: auto;
  }
  .prototype_container {
    display: flex; 
    background-color: #fff;
    justify-content: center;
    align-items: center;
    width: 108rem;
    height: 72rem;
    margin: 0 auto;
    border: 1px solid #c8ebff;
    margin-top: 3.2rem;
    overflow: hidden;
  }
  .add_gap{
    column-gap: 16rem;
  }
  .left_prototype{
    border-radius: 3.2rem;
  }
  .right_prototype{
    border-radius: 3.2rem;
  }
  .btn{
    padding: 1rem 1.6rem;
    background-color: #007bff;
    color: #fff;
    margin-top: 0;
  }
  .left_prototype,
  .right_prototype {
    position: relative;
    width: 30rem;
    height: 60rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile_frame{
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .blur_layer{
    position: absolute;
    top: 0;
    left: 24px;
    transform: scaleY(0);
    width: 100%;
    height: auto;
  }
  
  .upload_file {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .input_image{ 
    width: 14rem;
    height: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload_label {
    border-radius: 50%;
    border: 1px dashed #007bff;
    color: #007bff;
    padding: 5rem 4.5rem;
    font-size: 1.6rem;
    text-align: center;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    animation: blink 2.5s ease-in-out infinite forwards;
  }
  @keyframes blink {
    0%{
        background-color: #d8ebff;
    }
    25%{
        background-color: #ffffff;
    }
    50%{
        background-color: #d8ebff;
    }
    75%{
        background-color: #ffffff;
    }
    100%{
        background-color: #d8ebff;
    }
  }
  .image_uploaded{
    width: 100%;
    height: 100%;
    border-radius: 1.6rem;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
  }
  .image_preview {
    width: 91.5%;
    height: 96.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.6rem;
    background-color: #ffffff;
    position: relative;
    z-index: 5;
  }
  .img_preview_border{
      border: 1px dashed #9d9d9d;
  }
  .heightUnset{
    height: unset;
  }
  .image_uploaded img {
    width: 29rem;
    height: 59rem;
    object-fit: cover;
    object-position: center;
  }
  .frame_image {
    width: auto;
    height: 90%;
    z-index: 2;
  }
  .overlayPrototype-enter-from,
  .overlayPrototype-leave-to{
  opacity: 0;
  visibility: hidden;
  }
  .overlayPrototype-enter-active,
  .overlayPrototype-leave-active {
  transition: all 0.5s ease;
  }
  .disableBtn{
    cursor: not-allowed;
  }
  .add_text{
  /* position: absolute; */
  width: 22rem;
  margin: 0 1rem;
  }
  .text_added_top{
  margin-bottom: 10rem;
  }
  .added_text{
  position: relative;
  }
  .added_text h2{
  font-size: 2rem;
  margin-bottom: 1rem;
  }
  .added_text h2:active,
  .added_text h2:focus,
  .added_text p:active,
  .added_text p:focus{
  border: none;
  outline: none;
  }
  .added_text p{
  font-size: 1.6rem;
  line-height: 150%;
  }.added_text .caret{
  position: absolute;
  font-size: 2.8rem;
  opacity: 0.8;
  color: #007bff;
  }
  .caret_left{
  left: -1.6rem;
  }
  .caret_right{
  right: -1.6rem;
  }
  .text_added_bottom h2{
  text-align: right;
  }
  .added_text h2{
  color: #007bff;
  }
  .added_text p{
  text-align: justify;
  color: #009cb8;
  }
  #textEdit{
  display: none;
  }
  .textEdit{
  font-size: 1.6rem;
  color: #fff;
  padding: 0.8rem 1.6rem;
  border: 1px solid #fff;
  border-radius: 0.8rem;
  cursor: pointer;
  }
  #textEdit:checked+label{
  border-color: #fa6969;
  color: #fa6969;
  }
  .added_text_customise{
  position: relative;
  }
  .title_color{
  appearance: none;
  background-color: transparent;
  width: 4rem;
  height: 3rem;
  /* border: 1px solid #fff; */
  /* border-radius: 50%; */
  cursor: pointer;
  }
  .para_color{
  margin-left: 1rem;
  }
  .edit_text_color{
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 1rem;
  position: absolute;
  top: 3.4rem;
  right: 0;
  }
  @media screen and (max-width: 600px) {
    .input_handlers{
      row-gap: 3.2rem;
    }
      .range_slider{
        width: 70%;
      }
  }
  @media screen and (max-width: 425px) {
    .pick_color{
      height: 7rem;
      font-size: 2.4rem;
    }
    .body_color{
      width: 5.5rem;
      height: 6rem;
    }
    .textEdit{
      font-size: 2rem;
    }
    .popUp{
      height: 70vh;
    }
    .popUp h1{
      font-size: 3rem;
    }
    .popUp p{
      font-size: 2.4rem;
    }
  }
  </style>