<template>
    <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
        <div class="container">
            <div class="header">
                <!-- <i class="fa-solid fa-arrow-left arr"></i> -->
                <h1 class="heading">Compress Image</h1>
            </div>
            <div class="copy">
                <p>Enhance your digital assets effortlessly. Our tool optimizes <span>JPG</span> ,<span>PNG</span> ,<span>WEBP</span> files with premium quality and compression. Streamline image sizes in a single click, ensuring faster loading times without sacrificing visual excellence.
                </p>
            </div>
            <div class="compress_container">
                <div class="upload_image">
                    <div class="images_container">
                        <input type="file" id="uploadJpeg" class="uploadFile" ref="fileInput" @change="updateImage" accept="image/*">
                        <div class="labels_compress">
                            <i class="fa-solid fa-cloud-arrow-up upload"></i>
                            <label for="uploadJpeg" class="upload_here">Drag and Drop to Upload</label>
                            <label for="uploadJpeg" class="upload_here_type"><span>or Click here</span></label>
                        </div>
                    </div>
                    <label class="compress_label" for="compressionSlider">Compression:</label><br>
                    <input class="slider compress_slider" type="range" id="compressionSlider" :disabled="!uploadedImage" v-model="compressionPercentage" min="20" max="80" step="5">
                    
                    <button class="btn_primary btn" @click="compressImage" :class="{'disable_btn': !uploadedImage}" :disabled="!uploadedImage">Compress Image</button>
                </div>

            
                <!-- Compression Slider -->
                <div class="compressed_image">
                    <div class="img_compressed">
                        <img v-if="showImage" :src="showImage" alt="Compressed Image">
                    </div>
                    <a v-if="compressedImage" class="download_image" :href="compressedImage" download="compressed_image.jpg">
                        Download Image
                    </a>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
  export default {
    name: "ImageCompress",
    components:{Header, Footer},
    computed: {
      user() {
        const store = useStore();
        return store.state.user;
      },
    },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Optimize Image Compression for Top-Quality Results in Seconds.',
      meta: [
        {
          name: `Optimize Image Compression for Top-Quality Results in Seconds.`,
          content: 'Choose multiple JPG, PNG or GIF images and compress them in seconds for free! You can adjsut the compression amount according to your need.',
        },
        ],

      })
      return {
        user,
      };
    },
    data() {
      return {
        fromWebsitePerformance: true,
        uploadedImage: null,
        compressedImage: null,
        compressionPercentage: 20, // Default compression percentage
        showImage: null, // Controls image display
      };
    },
    methods: {
      updateImage(event) {
        const file = event.target.files[0];
        if (file) {
          this.uploadedImage = file;
          this.showImage = URL.createObjectURL(file); // Show the uploaded image
        }
      },
      async compressImage() {
        if (!this.uploadedImage) {
          return;
        }
  
        // Calculate the target compression quality based on the user's chosen percentage
        const targetQuality = 1-(this.compressionPercentage / 100);
        
  
        try {
          const compressedBlob = await this.compress(this.uploadedImage, targetQuality);
          // Create a URL for the compressed image blob
          this.compressedImage = URL.createObjectURL(compressedBlob);
          this.showImage = this.compressedImage; // Show the compressed image
        } catch (error) {
          console.error('Error compressing image:', error);
        }
      },
      compress(imageFile, quality) {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = URL.createObjectURL(imageFile);
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);
  
            canvas.toBlob(
              (blob) => {
                resolve(blob);
              },
              'image/jpeg',
              quality
            );
          };
        });
      },
    },
  };
  </script>  
<style scoped>
.container{
    width: 90%;
    margin-bottom: 4rem;
}
  .header{
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .arr{
    font-size: 2rem;
    background-color: #ffffff;
    padding: 1rem 1.1rem;
    border-radius: 50%;
    color: #007bff;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .heading{
    font-size: 3rem;
    margin: 2rem 0;
  }
  .copy{
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: #f7f7f7;
    border-radius: 0.5rem;
    box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.5);
  }
  .copy p{
    font-size: 2rem;
    line-height: 160%;
  }
  .copy span{
    color: #007bff;
    text-decoration: underline;
  }
  .compress_container{
    margin-top: 4rem;
    display: flex;
    gap: 1rem;
  }
  .upload_image{
      flex: 1;
  }
  .images_container{
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    position: relative;
    margin-bottom: 2rem;
}
.labels_compress{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  text-align: center;
  border: 1px dashed #007bff;
  padding: 6.4rem 5.8rem;
  border-radius: 0.5rem;
}
.upload_here_type{
    font-size: 2rem;
    color: #007bff;
}
.upload_here{
    font-size: 1.6rem;
    margin-block: 0.4rem;
}
.compressed_image{
    flex: 1;
}
.img_compressed{
    width: 100%;
    max-height: 32rem;
    overflow: auto;
    margin-bottom: 3rem;
}
.img_compressed img{
    width: 100%;
    height: auto;
    object-position: top;
    object-fit: cover;
}
.compress_label{
    font-size: 1.6rem;
}
.compress_slider{
    margin-top: 0.8rem;
    width: 72%;
    background: #9cccff;
}
.btn {
  background-color: #007bff;
  margin-top: 4rem;
  color: #fff;
  padding-inline: 2rem;
  margin-right: 1rem;
}
.disable_btn{
    opacity: 0.5;
    cursor: not-allowed;
}
.download_image{
    color: #007bff;
    border: 1px solid #007bff;
    padding: 0.6rem 2rem;
    background-color: transparent;
    text-decoration: none;
    border-radius: 0.4rem;
    font-size: 2rem;
}
@media screen and (max-width: 440px) {
  .compress_container{
    flex-direction: column;
  }
  .compress_slider{
    margin-top: 2rem;
    width: 90%;
  }
  .copy p{
    font-size: 2.4rem;
    line-height: 160%;
  }
}
</style>