<template>
  <div class="component_content">
    <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
    <section class="hero-section">
      <div class="container">
        <adsense
          :adClient="googleAdClient"
          :adSlot="googleAdSlot"
        />
        <div class="navigation">
          <input type="radio" id="favorites" name="navigation" v-model="navigation" value="favorites">
          <label for="favorites" class="nav_links favorite_tab" :class="{'noUser': !user}">Favorites</label>
          <div class="navigation_links">
            <input type="radio" id="accordion" name="navigation" v-model="navigation" value="accordion">
            <label for="accordion" class="nav_links">Accordion</label>

            <input type="radio" id="button" name="navigation" v-model="navigation" value="button">
            <label for="button" class="nav_links">Buttons</label>

            <input type="radio" id="card" name="navigation" v-model="navigation" value="card">
            <label for="card" class="nav_links">Cards</label>

            <input type="radio" id="carousel" name="navigation" v-model="navigation" value="carousel">
            <label for="carousel" class="nav_links">Carousel</label>

            <!-- <input type="radio" id="collpase" name="navigation" v-model="navigation" value="collpase">
            <label for="collpase" class="nav_links">Collpase</label> -->
            
            <!-- <input type="radio" id="dropdown" name="navigation" v-model="navigation" value="dropdown">
            <label for="dropdown" class="nav_links">Dropdown</label> -->

            <input type="radio" id="form" name="navigation" v-model="navigation" value="form">
            <label for="form" class="nav_links">Forms</label>

            <input type="radio" id="modal" name="navigation" v-model="navigation" value="modal">
            <label for="modal" class="nav_links">Modals</label>

            <!-- <input type="radio" id="navigation" name="navigation" v-model="navigation" value="navigation">
            <label for="navigation" class="nav_links">Nav&nbsp;Bar</label> -->

            <input type="radio" id="radioButton" name="navigation" v-model="navigation" value="radioButton">
            <label for="radioButton" class="nav_links">Radio&nbsp;Buttons</label>

            <input type="radio" id="spinners" name="navigation" v-model="navigation" value="spinners">
            <label for="spinners" class="nav_links">Spinners</label>

            <input type="radio" id="tooltips" name="navigation" v-model="navigation" value="tooltips">
            <label for="tooltips" class="nav_links">Tooltips</label>

            <input type="radio" id="table" name="navigation" v-model="navigation" value="table">
            <label for="table" class="nav_links">Tables</label>

          </div>
          <button class="arrow_btn left_btn" @click="scrollLeft"><i class="fa-solid fa-caret-left"></i></button>
          <button class="arrow_btn right_btn" @click="scrollRight"><i class="fa-solid fa-caret-right"></i></button>
        </div>
          <Buttons v-if="navigation=='button'" :user="user"/>
          <Accordion v-if="navigation=='accordion'" :user="user"/>
          <Favorites v-if="navigation=='favorites'" :user="user"/>
          <Cards v-if="navigation=='card'" :user="user"/>
          <Carousel v-if="navigation=='carousel'" :user="user"/>
          <Forms v-if="navigation=='form'" :user="user"/>
          <Cards v-if="navigation=='dropdown'" :user="user"/>
          <Modals v-if="navigation=='modal'" :user="user"/>
          <RadioButtons v-if="navigation=='radioButton'" :user="user"/>
          <Spinners v-if="navigation=='spinners'" :user="user"/>
          <Tooltip v-if="navigation=='tooltips'" :user="user"/>
          <Table v-if="navigation=='table'" :user="user"/>
          <!-- <Cards v-if="navigation=='form'"/> -->
          <!-- <Cards v-if="navigation=='card'"/>
          <Cards v-if="navigation=='card'"/>
          <Cards v-if="navigation=='card'"/> -->
        </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import Buttons from "./Components/Buttons.vue"
import Favorites from "./Components/Favorites.vue"
import Cards from "./Components/Cards.vue"
import Carousel from "./Components/Carousel.vue"
import Accordion from "./Components/Accordions.vue"
import Forms from "./Components/Forms.vue"
import Modals from "./Components/Modals.vue"
import RadioButtons from "./Components/RadioButtons.vue"
import Spinners from "./Components/Spinners.vue"
import Tooltip from "./Components/Tooltip.vue"
import Table from "./Components/Table.vue"
import { onMounted, ref } from "vue";
import VueAdsense from 'vue-adsense'
import { db } from "./../main"
import { getAuth } from 'firebase/auth';
import { collection, getDocs, addDoc, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";

export default {
  name:"AllComponents",
  components:{Header,Footer, Buttons, Cards, Accordion, Carousel, Forms, Modals, RadioButtons, Spinners, Tooltip, Table, Favorites, 'adsense': VueAdsense},
  setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    const buttons = ref([
          {
            currentIndex: 1,
            favorite: false,
            mode: false,
            htmlCode:`<div class="table-container"><table class="styled-table"><thead><tr><th>Header 1</th><th>Header 2</th><th>Header 3</th><th>Header 4</th></tr></thead><tbody><tr><td>Data 1</td><td>Data 2</td><td>Data 3</td><td>Data 4</td></tr><tr><td>Data 5</td><td>Data 6</td><td>Data 7</td><td>Data 8</td></tr><tr><td>Data 9</td><td>Data 10</td><td>Data 11</td><td>Data 12</td></tr></tbody></table></div>`,
            style: `.styled-table{border-collapse:collapse;width:100%;margin:0 auto;font-family:Arial,sans-serif}.styled-table th,.styled-table td{padding:12px 15px;text-align:center}.styled-table th{background-color:#3498db;color:#fff;font-weight:700}.styled-table tr:nth-child(even){background-color:#f2f2f2}.styled-table tr:nth-child(odd){background-color:#fff}.styled-table tr:hover{background-color:#ddd}.table-container{text-align:center}`
          },
          {
            currentIndex: 2,
            favorite: false,
            mode: false,
            htmlCode:`<div class="table-container"><table class="styled-table"><thead><tr><th>Header 1</th><th>Header 2</th><th>Header 3</th><th>Header 4</th></tr></thead><tbody><tr><td>Data 1</td><td>Data 2</td><td>Data 3</td><td>Data 4</td></tr><tr><td>Data 5</td><td>Data 6</td><td>Data 7</td><td>Data 8</td></tr><tr><td>Data 9</td><td>Data 10</td><td>Data 11</td><td>Data 12</td></tr></tbody></table></div>`,
            style: `.styled-table{border-collapse:collapse;width:100%;margin:0 auto;font-family:Arial,sans-serif;background:linear-gradient(275deg,#36cecc 0%,#10b281 100%);color:#fff;border-radius:12px;overflow:hidden}.styled-table th,.styled-table td{padding:12px 15px;text-align:center;border-bottom:1px solid rgba(255,255,255,.4)}.styled-table th{background-color:rgba(0,0,0,.3);font-weight:700}.styled-table tr:hover{background-color:rgba(255,255,255,.1)}.table-container{text-align:center}`
          }
    ])
  useHead({
    // Can be static or computed
    title: 'Unleash the power of CSS components! Get beautiful Buttons, Cards, Forms, Modals, Loaders and many more.',
    meta: [
      {
        name: `Unleash the power of CSS components! Get beautiful Buttons, Cards, Forms, Modals, Loaders and many more.`,
        content: 'Access a rich selection of CSS web components to transform your websites effortlessly. You can customize them according to your need and all the components are responsive.',
      },
      ],

    })
    onMounted(()=>{
      // addDocs()
    })
    const addDocs = async() =>{
      for(let i=0; i<2; i++){
        await setDoc(doc(db, "tables", `table00${i+1}`), {
          currentIndex: `table00${i+1}`,
          htmlCode: buttons.value[i].htmlCode,
          style: buttons.value[i].style,
          price: "Free",
          react: "",
          tailwindcss: ""
        });
      }
    }
    return {
      buttons,
      user,
      addDocs,
    };
  },
  data() {
    return {
      navigation:'accordion',
      fromWebsitePerformance: true,
      googleAdClient: 'ca-pub-9625497794661172',
      googleAdSlot: 'DIRECT',
    };
  },
  methods: {
    scrollRight(){
      let cont = document.querySelector('.navigation_links');
      cont.scrollBy({
        top: 0,
        left: +120,
        behavior: 'smooth'
      });
    },
    scrollLeft(){
      let cont = document.querySelector('.navigation_links');
      cont.scrollBy({
        top: 0,
        left: -120,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
  .component_content{
    /* position: relative; */
  }
  .hero-section {
    cursor: default;
    background: rgba(255, 255, 255, 0.85);
    /* background: linear-gradient(120deg, rgba(255, 255, 255, 0.85) 40%, rgba(148, 255, 250, 0.45) 100%); */
    /* position: relative; */
    min-height: 90vh;
  }
  .container{
    padding-top: 5rem;
    margin-bottom: 5rem;
    width: 90%;
    /* position: relative; */
  }
  input[type='radio']{
    display: none;
  }
  .navigation{
    position: relative;
  }
  .favorite_tab{
    position: absolute !important;
    right: 0;
    top: 0;
    z-index: 2;
    background-color: #FFF;
    box-shadow: 0 4px 4px 2px rgba(0,0,0,0.1);
  }
  .favorite_tab::before{
    display: none !important;
  }
  .navigation_links{
    border-bottom: 1.5px solid #c7c7c7;
    padding: 0 16rem 2rem 11rem;
    margin-bottom: 2.4rem;
    position: relative;
    /* top: 100px;
    left: 0; */
    display: flex;
    overflow: hidden;
  }
  .noUser{
    pointer-events: none;
  }
  .navigation::before{
    content: "";
    position: absolute;
    width: 12rem;
    height: 6rem;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
    left: 0;
    bottom: 2px;
    z-index: 1;
  }
  .navigation::after{
    content: "";
    position: absolute;
    width: 17.4rem;
    height: 6rem;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 15%);
    right: 0;
    bottom: 2px;
    z-index: 1;
  }
  .left_btn{
    left: 0;
    top: -0.5rem;
  }
  .right_btn{
    left: 5rem;
    top: -0.5rem;
  }
  .arrow_btn{
    background-color:#288DD0;
    color: #fff;
    padding: 0.6rem 1.6rem;
    border-radius: 0.4rem;
    font-size: 3.2rem;
    border: none;
    position: absolute;
    z-index: 2;
    cursor: pointer;
  }
  .nav_links{
    font-size: 2rem;
    border-radius: 0.4rem;
    padding: 0.6rem 1.6rem;
    cursor: pointer;
    position: relative;
    transform-origin: center;
    transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .nav_links::before{
    content: "";
    position: absolute;
    bottom: -1.6rem;
    left: 1rem;
    height: 0.3rem;
    width: 0%;
    border-radius: 2rem;
    background-color: #268ACD;
    transition: all 0.2s ease-in-out;
  }
  .nav_links:hover::before{
    width: 85%;
  }
  input[type='radio']:checked+.nav_links{
    color: #fff;
    background-color: #288DD0;
  }
  input[type='radio']:checked+.nav_links:hover::before{
    display: none;
  }
  p{
    font-size: 2rem;
    line-height: 150%;
  }
  @media screen and (max-width: 425px) {
      .nav_links{
        font-size: 2.4rem;
      }
  }
</style>