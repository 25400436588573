<template>
    <div class="countdown_container">
      <div class="timer">
        <div class="timer_now">
          <span class="timer__part timer__part--minutes">{{ minutes }}</span>
          <span class="timer__part">:</span>
          <span class="timer__part timer__part--seconds">{{ seconds }}</span>
        </div>
        <div class="countdown_btn">
          <button type="button" :class="['timer__btn', 'timer__btn--control', controlClass]" @click="toggleTimer">
            <img v-if="showPlay" src="../assets/Pause.png" alt="">
            <img v-if="!showPlay" src="../assets/Play.png" alt="">
            <span class="icons">{{ controlIcon }}</span>
          </button>
          <button type="button" class="timer__btn timer__btn--reset" @click="resetTimer">
            <img src="../assets/Alarm.png" alt="">
            <span class="icons">Set Time</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        minutes: 0,
        seconds: 0,
        interval: null,
        remainingSeconds: 0,
        isRunning: false,
        showPlay: false
      };
    },
    computed: {
      controlIcon() {
        return this.isRunning ? 'Pause' : 'Play';
      },
      controlClass() {
        return this.isRunning ? 'timer__btn--stop' : 'timer__btn--start';
      },
    },
    methods: {
      toggleTimer() {
        if (!this.isRunning) {
          this.startTimer();
          this.showPlay=true;
        } else {
          this.stopTimer();
          this.showPlay=false;
        }
      },
      startTimer() {
        if (this.remainingSeconds === 0) return;
  
        this.isRunning = true;
  
        this.interval = setInterval(() => {
          this.remainingSeconds--;
          this.updateInterfaceTime();
  
          if (this.remainingSeconds === 0) {
            this.stopTimer();
          }
        }, 1000);
      },
      stopTimer() {
        clearInterval(this.interval);
        this.interval = null;
        this.isRunning = false;
      },
      resetTimer() {
        const inputMinutes = prompt("Enter number of minutes:");
  
        if (inputMinutes < 60) {
          this.stopTimer();
          this.remainingSeconds = inputMinutes * 60;
          this.updateInterfaceTime();
        }
      },
      updateInterfaceTime() {
        const minutes = Math.floor(this.remainingSeconds / 60);
        const seconds = this.remainingSeconds % 60;
  
        this.minutes = minutes.toString().padStart(2, "0");
        this.seconds = seconds.toString().padStart(2, "0");
      },
    },
    mounted() {
      this.updateInterfaceTime();
    },
  };
  </script>
  
  <style scoped>
  .countdown_container,
  .countdown_btn{
    display: flex;
    justify-content: center;
  }
  .countdown_btn{
    margin-top: 5rem;
  }
  .countdown_btn button{
    display: flex;
    align-items: center;
  }
  .timer {
    display: inline-block;
    padding: 2.4rem 3.2rem;
    border-radius: 3rem;
  }
  .countdown_btn button img{
    width: 4rem;
    margin-right: 1rem;
  }
  .timer__part {
    font-size: 16rem;
    letter-spacing:0.1em;
    text-align: center;
    font-weight: 800;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0);
    text-shadow: 6px 6px #16a2ff, 12px 12px #000000, 16px 16px 12px #ffffff;
  }

  .timer__btn--reset{
    margin-left: 1rem;
  }
  .timer__btn {
    border: none;
    color: white;
    background: #ff781e;
    padding: 3.2rem 8rem;
    font-size: 2.4rem;
    cursor: pointer;
  }
  
  .timer__btn--start {
    background: #00b84c;
  }
  
  .timer__btn--stop {
    background: #ff0256;
  }
  @media screen and (max-width: 51rem) {
  .countdown_btn{
    flex-direction: column;
  }
  .countdown_btn button{
    justify-content: center;
    font-size: 4.8rem;
  }
  .countdown_btn button img{
    width: 8rem;
  }
  .timer__btn--reset{
    margin-left: 0;
    margin-top: 2.4rem;
  }
}
  </style>
  