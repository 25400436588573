<template>
  <div>
    <vue-image-crop-upload
      v-model="croppedImage"
      :height="500"
      :width="700"
      :accept="'image/*'"
      :output-type="'jpeg'"
      :crop-options="cropOptions"
    ></vue-image-crop-upload>
  </div>
</template>

<script>
import VueImageCropUpload from 'vue-image-crop-upload';

export default {
  name: "ImageEditor",
  components: {
    VueImageCropUpload,
  },
  data() {
    return {
      croppedImage: null,
      cropOptions: {
        cropperOptions: {
          aspectRatio: 7 / 5,
        },
      },
    };
  },
};
</script>

<style>
/* Add any styles here */
</style>
