<template>
  <div>
    <section class="hero-section">
      <Header/>
      <!-- <div class="header_container">
        <Header/>
      </div> -->
      <div class="container timer_container">
        <div class="input_radio">
          <input type="radio" id="stopwatch" name="timer" value="stopWatch" v-model="countDownTimer">
          <label for="stopwatch" class="label_stopwatch">Stopwatch</label>
          <input type="radio" id="countDown" name="timer" value="countDown" v-model="countDownTimer">
          <label for="countDown" class="label_timer">Count Down</label>
          <span class="glider"></span>
        </div>
        <div class="show_timer">
          <StopWatch v-if="countDownTimer=='stopWatch'"/>
          <TimerClock v-else/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../components/Header.vue"
import StopWatch from "./StopWatch.vue";
import TimerClock from "./TimerClock.vue";
export default {
    name:"CountDownTimer",
    props: [],
    components: {StopWatch, TimerClock, Header},
    data() {
        return {
          countDownTimer: 'stopWatch'
        }
    },
    mounted(){
      
    },
    methods: {
      
    }
}
</script>

<style scoped>
  .header_container{
    background-color: #292929;
  }
  .hero-section {
    cursor: default;
    background-image: url("../assets/TimeTravel.jpg");
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    /* background-size: 100% 100%; */
    background-position: center;
    background-size: cover;
    height: 98vh;
    position: relative;
    overflow: hidden;
    font-size: 5rem;
}
.input_radio{
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  border-radius: 0.8rem;
  width: 100%;
  /* height: 6.5rem; */
  background-color: #0c0c0e;
}
input[type='radio']{
  display: none;
}
.input_radio label{
  width: 50%;
  z-index: 10;
  text-align: center;
  cursor: pointer;
}
.timer_container{
  background-color: rgba(255, 255, 255, 0.4);
  /* background-color: rgba(0, 0, 0, 0.4); */
  backdrop-filter: blur(5px);
  border-radius: 1rem;
  overflow: hidden;
  padding: 0.5rem;
}
.glider{
  height: 8.5rem;
  width: 50%;
  left: 0.5rem;
  position: absolute;
  top: 0.5rem;
  z-index: 1;
  background: url("../assets/Slider.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 0.25s ease-out;
}
.input_radio input[id="stopwatch"]:checked ~ .glider {
  transform: translateX(0%);
  border-radius: 0.5rem 0 0 0.5rem;
}

.input_radio input[id="countDown"]:checked ~ .glider {
  transform: translateX(98%);
  border-radius: 0 0.5rem 0.5rem 0;
}
.input_radio input[type="radio"]:checked + label{
  color: #fff;
}
.input_radio input[type="radio"]:not(:checked) + label{
  font-weight: 600;
  /* background: linear-gradient(to right, #21bdf3, #b5ff08, #cf23cf); */
  background-color: #b1b1b1;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.show_timer{
  padding: 0 5rem;
}
</style>