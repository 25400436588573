<template>
  <div>
    <div class="message_container" :class="{'greenAlert': fromLogin}">
        <p class="message">{{message}}</p>
        <button class="button"  @click="closeAlert"><i class="fa-solid fa-xmark"></i></button>
    </div>
  </div>
</template>

<script>
export default {
    name: "AlertMessage",
    props: {
        message: String,
        closeAlert: Function,
        fromLogin: Boolean
    },
    methods: {
        closeAlert() {
            this.closeAlert();
        },
    },
}
</script>

<style scoped>
    .message_container{
        width: 330px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 4px 4px 12px;
        border-radius: 8px;
        background-color: rgb(233, 61, 61);
    }
    .greenAlert{
        background-color: rgb(29, 228, 105);
    }
    .message_container .message{
        color: #FFF;
        font-size: 16px;
    }
    .message_container .button{
        background-color: transparent;
        border: 0;
        padding: 6px 8px;
        border-radius: 4px;
    }
    .message_container .button:hover{
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
    }
    .message_container .button i{
        font-size: 16px;
        color: #FFF;
        font-weight: 900;
    }
    
</style>