<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <!-- <Header/> -->
  <router-view/>
  <!-- <div class="footer">
    <Footer/>
  </div> -->
</template>

<script>
import {ref, onMounted} from "vue"
import {computed, reactive } from 'vue'
import Header from "./components/Header.vue"
import { useHead } from '@vueuse/head'
import DisableDevtool from 'disable-devtool';
export default {
  setup() {
    const siteData = reactive({
      title: `UI Assembly: Get beautiful customised Web components made of CSS.`,
      description: `Discover a treasure trove of exquisite CSS web components on our platform. Empower your web development and design projects by seamlessly integrating these captivating elements into your websites.`,
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `UI Assembly: Get beautiful customised Web components made of CSS.`,
          content: computed(() => siteData.description),
        },
      ],
    })
    onMounted(()=>{
      if(screen.width > 821){
        DisableDevtool();
      }
      // window.addEventListener('contextmenu', (event) => {
      //   event.preventDefault();
      // })
    })
    // document.addEventListener('keydown', (event) => {
    //   const key = event.key || event.keyCode;

    //   if (key === 'F12' || (event.ctrlKey && event.shiftKey && (key === 'i' || key === 'I' || key === 'j' || key === 'J'))) {
    //     event.preventDefault();
    //   }
    // })
  },
  components: {
    Header,
  }
}
</script>
<style src="./assets/customCss.css"></style>
<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #2c3e50;
  overflow-x: hidden;
  position: relative;
}
.container {
  max-width: 1080px;
  margin: 0 auto;
}
nav {
  padding: 24px;
}
.modal_overLay{
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  display: flex;
  justify-content: flex-end;
}

nav a {
  font-size: 2rem;
  /* color: #fff; */
  font-weight: bold;
  text-decoration: none;
  padding: 0.2rem 2rem;
}
nav a:not(.logo_image)::before {
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 4.2rem;
  left: 0;
  transform: skew(0deg) scale(0);
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.tool-list a.router-link-exact-active::before {
  background-color: transparent;
  display: none;
}
nav a.router-link-exact-active::before {
  background-color: rgba(255, 255, 255, 0.15);
  transform: skew(20deg) scale(1);
  color: #42b983 !important;
}
.footer{
  background-color: #04151E;
  padding: 2rem 0;
  margin-top: auto;
}
.btn_primary {
 margin-top: 2rem;
 padding: 2rem 0;
 border-radius: 0.5rem;
 border: 0;
 background-color: white;
 box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
 letter-spacing: 1.5px;
 text-transform: uppercase;
 font-size: 15px;
 transition: all .5s ease;
 cursor: pointer;
}

.btn_primary:hover {
 letter-spacing: 2px;
 background-color: #2c88c5;
 color: hsl(0, 0%, 100%);
}

.btn_primary:active {
 letter-spacing: 2px;
 background-color: #2c88c5;
 color: hsl(0, 0%, 100%);
 transform: translateY(2px);
 transition: 100ms;
}
.btn_secondary{
  border: 2px solid #152028;
  cursor: pointer;
  border-radius: 0.5rem;
  color: #152028;
  padding: 1.5rem 0;
  font-size: 2rem;
  background-color: transparent;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #fff;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 16px;
  background: #0686ee;
  border-radius: 0.21rem;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}
.pointer{
  cursor: pointer;
}

.image-converter {
    max-width: 1080px;
    width: 94%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .converted_image{
    max-width: 1080px;
    width: 94%;
    height: 64%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: flex-start;
    /* flex-wrap: wrap; */
  }
  .img_container{
    width: 42%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .spinner{
    width: 50%;
    height: auto;
  }
  .img_container img {
    width: auto;
    height: auto;
  }
  .details{
    width: 50%;
  }
  .details p{
    font-size: 1.6rem;
    color: #fff;
    margin-bottom: 1.2rem;
  }
  .details span{
    font-weight: 700;
    color: #007bff;
  }
  .btn{
    background-color: #007bff;
    color: #fff;
    font-size: 1.6rem;
    letter-spacing: 2px;
    padding: 1.6rem;
    border: none;
    border-radius: 0.4rem;
  }
  .fileName{
    max-width: 400px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .uploadFile{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    cursor: pointer;
}
.labels{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  text-align: center;
  border: 1px dashed #007bff;
  border-radius: 50%;
  padding: 6.4rem 5.8rem;
}
.upload{
  font-size: 6.4rem;
  color: #007bff;
}
.upload_label1,
.upload_label2{
    text-align: center;
    display: block;
    margin-right: 0;
    color: #fff;
    font-size: 1.8rem;
    margin-top: 1rem;
  }
  .upload_label1 span{
    color: #007bff;
  }
  .upload_label2{
    font-size: 1.2rem;
  }
  button {
    /* margin-top: 10px; */
  }
</style>
