<template>
  <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance"/>
      <div class="container">
        <div class="header">
          <div class="header_">
            <!-- <i class="fa-solid fa-arrow-left arr"></i> -->
            <h1 class="heading">Speech to Text Generator</h1>
          </div>
          <button class="btn_primary btn" @click="toggleListening" v-if="!listening"><i class="fa-solid fa-microphone-lines mic"></i>Start Listening</button>
          <button class="btn_stop" @click="stopListening" v-else><img src="../assets/wave.gif" alt="" srcset=""> Stop Recording</button>
        </div>
        <div class="generated_text">
          <span v-for="(sentence, index) in sentences" :key="index">{{ sentence }}&nbsp;</span>
        </div>
    </div>
    </section>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head'
  import Header from "../components/Header.vue"
  export default {
    name: "TextToSpeech",
    components:{Header},
    setup() {

    useHead({
      // Can be static or computed
      title: 'Speech to Text Generator: UI Assembly',
      meta: [
        {
          name: `Speech to Text Generator: UI Assembly`,
          content: 'Convert speech to text online. Dictate your voice in real time and get it transcribed automatically in no time.',
        },
        ],

      })
    },
    data() {
      return {
        fromWebsitePerformance: true,
        listening: false,
        transcript: '',
        sentences: [],
        recognition: null,
        restartTimeout: null,
      };
    },
    mounted() {
      if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
        this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        this.recognition.continuous = true;
        this.recognition.interimResults = true;
  
        this.recognition.onstart = () => {
          this.listening = true;
        };
  
        this.recognition.onresult = (event) => {
          let interimTranscript = '';
          for (let i = event.resultIndex; i < event.results.length; i++) {
            interimTranscript += event.results[i][0].transcript + ' ';
          }
  
          if (event.results[event.results.length - 1].isFinal) {
            this.transcript += interimTranscript;
            const sentences = this.transcript.toLowerCase().split('. ');
            this.sentences = sentences.filter(sentence => sentence.trim() !== '');
          }
        };
  
        this.recognition.onend = () => {
          this.listening = false;
          // Restart recognition after a delay
          clearTimeout(this.restartTimeout);
          this.restartTimeout = setTimeout(() => {
            if (this.listening) {
              this.recognition.stop();
              this.recognition.start();
            }
          }, 5000); // Restart after 5 seconds (adjust as needed)
        };
      } else {
        alert("Your browser doesn't support the Speech Recognition API.");
      }
    },
    methods: {
      toggleListening() {
        if (!this.listening) {
          this.transcript = '';
          this.sentences = [];
          this.recognition.start();
        } else {
          this.recognition.stop();
        }
      },
      stopListening() {
        if (this.listening) {
          this.recognition.stop();
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .hero-section {
    cursor: default;
    /* background-color: #000000;
    background-image: linear-gradient(180deg, #02071a 0%, #022a44 74%); */
    position: relative;
    min-height: 100vh;
  }
  .container{
    width: 90%;
    margin-bottom: 2rem;
  }
  .generated_text{
    min-height: 80vh;
    overflow-y: auto;
    border: 1px solid #abd4ff;
    padding: 2rem;
    border-radius: 0.58em;
    background-color: #f3f9ff;
  }
  .generated_text span{
    font-size: 1.8rem;
    line-height: 160%;
  }
  .header_{
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .arr{
    font-size: 2rem;
    background-color: #ffffff;
    padding: 1rem 1.1rem;
    border-radius: 50%;
    color: #007bff;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .heading {
    font-size: 3.2rem;
    margin: 2rem 2rem 2rem 0;
  }
  .btn_stop{
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    background-color: #ff3f3f;
    box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-size: 1.6rem;
    transition: all .5s ease;
    cursor: pointer;
    color: #fff;
    letter-spacing: 2px;
    border: none;
    display: flex;
    align-items: center;
  }
  .btn_stop img{
    width: 3rem;
    mix-blend-mode: multiply;
    margin-right: 1rem;
  }
  .btn {
    background-color: #007bff;
    margin-top: 0;
    color: #fff;
    padding-inline: 2rem;
  }
  .btn .mic{
    font-size: 2rem;
    margin-right: 1rem;
  }
  </style>  