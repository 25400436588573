<template>
    <div>
      <h1 class="heading">Accordions 👇</h1>
      <div class="buttons_group" v-if="getArray.length">
        <div v-for="(button,index) in getArray" :key="index" class="button_container" :class="{'dark': button.dataSource.mode==true}">
          <iframe :srcdoc="generateIframeContent(button.dataSource)" class="button-iframe"></iframe>
          <div class="float_btn">
            <div v-if="button.loaderShow"><Loader :size="sizeButtons"/></div>
            <i class="fa-regular fa-heart heart_icon" @click="favorite('accordion', index, button.favorite)" v-if="!button.favorite && !button.loaderShow"></i>
            <i class="fa-solid fa-heart heart_icon filled" @click="favorite('accordion', index, button.favorite)" v-else-if="button.favorite && !button.loaderShow"></i>
            <span class="get_code pointer" @click="getCode(index)"><i class="fa-solid fa-code"></i>Get Code</span>

            <div class="modeDiv">
              <input type="checkbox" :id="'mode' + button.dataSource.currentIndex" class="mode" @change="shadowColorChange" v-model="button.dataSource.mode">
              <label :for="'mode' + button.dataSource.currentIndex" class="colorMode"></label>
            </div>
          </div>
          <div class="crown" v-if="button.price=='Premium'">
            <i class="fa-solid fa-crown"></i>
          </div>
        </div>
      </div>
      <div v-else class="absent_favorite">
        <Loader/>
      </div>
      <transition name="overlay">
        <div class="modal_overLay" @click.self="closeModal" v-if="openComponentDetails">
          <ComponentModal :buttonData="getArray" :index="currentIndex" @closeModal="closeModal" :user="user"/>
        </div>
      </transition>
      <transition name="alert" mode="out-in">
        <div v-if="showAlert" class="alert">
          <AlertMessage :message="'Log in to Save this component'" v-if="showAlert" :closeAlert="closeAlert"/>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from "vue";
  import Loader from "./../../components/Loader.vue"
  import ComponentModal from "../Modals/ComponentModal.vue"
  import AlertMessage from '../../components/AlertMessage.vue';
  import { db } from "./../../main"
  import { getAuth } from 'firebase/auth';
  import { collection, getDocs, addDoc, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
  export default {
      name:"Accordion",
      props:['user'],
      components:{ComponentModal, Loader, AlertMessage},
      setup(props){
        const getArray = ref([])
        const showAlert = ref(false)
        const user = ref(props.user);
        const auth = getAuth();
        const sizeButtons = ref(true) 
        const openComponentDetails = ref(false)
        const currentIndex = ref(0)
        onMounted(async () => {
          if (!user.value) {
            // User is not logged in, fetch buttons and push mode: false
            const querySnapshot = await getDocs(collection(db, 'accordions'));
            querySnapshot.forEach((doc) => {
              const buttonData = doc.data();
              getArray.value.push({
                dataSource: buttonData,
                favorite: false, // Set favorite to false for all buttons
                mode: false,
                loaderShow: false,
                price: buttonData.price,
              });
            });
          } else {
            const auth = getAuth();
            const userUid = auth.currentUser.uid;
            const userDocRef = doc(db, 'users', userUid);

            // Fetch the user's savedposts array from Firestore
            const userDocSnapshot = await getDoc(userDocRef);
            const userSavedPosts = userDocSnapshot.data().savedposts || [];

            const querySnapshot = await getDocs(collection(db, 'accordions'));
            querySnapshot.forEach((doc) => {
              const buttonData = doc.data();

              const isFavorite = userSavedPosts.some((item) => {
                return item.index === buttonData.currentIndex && item.favorite === true;
              });

              getArray.value.push({
                dataSource: buttonData,
                favorite: isFavorite,
                mode: false,
                loaderShow: false,
                price: buttonData.price,
              });
            });
          }
        })
        const favorite = async (component, i, flag) => {
          if (!user.value) {
            showAlert.value = true;
          }else{
            getArray.value[i].loaderShow = true;
            let componentIndex = String(`${i + 1}`.padStart(3, 0));
            let componentFavorite = `${component}${componentIndex}`;

            // Fetch the current savedposts array from Firestore
            const docRef = doc(db, "users", auth.currentUser.uid);
            const docSnap = await getDoc(docRef);
            let currentSavedPosts = docSnap.data().savedposts || [];
            const indexToRemove = currentSavedPosts.findIndex((item) => item.index === componentFavorite);

            if (flag) {
              if (indexToRemove !== -1) {
                currentSavedPosts.splice(indexToRemove, 1);
              }
            } else {
              const favoriteObject = {
                dataSource: getArray.value[i].dataSource,
                index: componentFavorite,
                favorite: true,
                price: getArray.value[i].price
              };

              // Check if the componentFavorite object is not already in the array
              if (indexToRemove === -1) {
                currentSavedPosts.push(favoriteObject);
              }
            }
            await updateDoc(docRef, {
              savedposts: currentSavedPosts,
            });
            getArray.value[i].loaderShow = false;
            getArray.value[i].favorite = !flag;

            console.log(currentSavedPosts);
          }
        }
        const getCode = (i) =>{
          currentIndex.value = i; 
          openComponentDetails.value=true;
        }
        const closeModal = () =>{
          openComponentDetails.value=false;
        }
        const closeAlert = () =>{
          showAlert.value = false;
        }
        const generateIframeContent = (button) => {
          return `
            <html>
            <head>
              <style>
                ${button.style}
                body {
                margin: 0; /* Remove default body margin */
                display: flex;
                justify-content: center;
                align-items: center;
                height: 80vh;
              }
              </style>
            </head>
            <body>
              ${button.htmlCode}
            </body>
            </html>
          `;
        }
        return{
          getArray,
          sizeButtons,
          getCode,
          currentIndex,
          openComponentDetails,
          generateIframeContent,
          closeModal,
          showAlert,
          favorite,
          closeAlert
        }
      }
  }
  </script>
  
  <style scoped>
  .heading{
    font-size: 2.8rem;
    margin-bottom: 2rem;
  }
  .slideIn{
    transition: all 0.3s 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    max-width: 490px;
    width: 95%;
    opacity: 1;
    visibility: visible;
  }
  .buttons_group{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(274px,1fr));
    grid-gap: 20px 14px;
    align-content: stretch;
    align-items: stretch;
  }
  .button_container{
    height: 42rem;
    max-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    box-shadow: 0.2rem 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
    position: relative;
  }
  .dark{
    background-color: #1d1e22;
  }
  .float_btn{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
  }
  .float_btn i{
    font-size: 2rem;
    cursor: pointer;
  }
  .heart_icon{
    background-color: #f2faff;
    border: 1px solid #d0edff;
    padding: 1rem;
    border-radius: 0.4rem;
  }
  .filled{
    color: #429EFF;
  }
  .get_code{
    padding: 1rem;
    border-radius: 0.4rem;
    background-color: #f2faff;
    border: 1px solid #d0edff;
    font-size: 1.6rem;
  }
  .get_code i{
    margin-right: 1rem;
  }
  .modeDiv{
    margin-left: auto;
  }
  .mode{
    display: none;
  }
  .colorMode {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 30px;
    /* background-color: rgb(82, 82, 82); */
    background-color: #e0f3ff;
    border: 1px solid #b6e3ff;
    border-radius: 20px;
    cursor: pointer;
    transition-duration: .2s;
  }
  .colorMode::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    left: 5px;
    background-color: transparent;
    border-radius: 50%;
    transition-duration: .2s;
    /* box-shadow: 5px 2px 7px rgba(8, 8, 8, 0.26); */
    border: 5px solid #429EFF;
  }
  .mode:checked+.colorMode::after {
    transform: translateX(100%);
    transition-duration: .2s;
    background-color: #429EFF;
    /* border: none; */
  }
  /* Switch background change */
  .mode:checked+.colorMode {
    background-color: rgba(255, 255, 255, 0.1);
    transition-duration: .2s;
  }
  
  
  
  .overlay-enter-from,
  .overlay-leave-to{
    opacity: 0;
    visibility: hidden;
  }
  .overlay-enter-active,
  .overlay-leave-active {
    transition: all 0.5s ease;
  }
  .button-iframe {
    width: 100%;
    height: 100%; /* Set the desired iframe height */
    border: none;
  }
  @media screen and (max-width: 500px) {
    .float_btn > *,
    .float_btn i{
        font-size: 2.8rem;
    }
  }
  .alert {
  position: fixed;
  top: 80px;
  z-index: 2;
  right: 50px;
}
.alert-enter-active,
.alert-leave-active {
  transition: all 0.5s ease;
}

.alert-enter-from,
.alert-leave-to {
  right: -100px;
  opacity: 0;
}

.alert-enter-to,
.alert-leave-from {
  right: 50px;
  opacity: 1;
  visibility: visible;
}
  </style>
  