<template>
  <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
  <section class="hero-section">
    <div class="container">
      <div class="input_radio">
        <input type="radio" id="passwordGenerate" name="password" value="passwordGenerate" v-model="passwordApp">
        <label for="passwordGenerate" class="label_passwordGenerate first_child">Generate Password</label>
        <input type="radio" id="passwordStrength" name="password" value="passwordStrength" v-model="passwordApp">
        <label for="passwordStrength" class="label_passwordStrength">Check Password Strength</label>
        <span class="glider"></span>
      </div>

      <div class="password_container" >
        <div v-if="passwordApp=='passwordGenerate'" class="password_generate">
          <h1>Password Generator</h1>
          <label for="strength" class="label_strength">Select password strength:</label>
          <select id="strength" v-model="passwordStrength">
            <option value="weak">Weak</option>
            <option value="medium">Medium</option>
            <option value="strong">Strong</option>
          </select>
          <button class="btn_primary btn" @click=generatePassword>Generate Password</button><br>
          <input class="generated_password" type="text" id="password" v-model="passwordGenerated" readonly>
          <button @click=copyPassword class="btn_secondary secondary" :disabled="passwordGenerated==''" :class="{'add': passwordGenerated==''}">Copy</button>
        </div>
      
        <div v-if="passwordApp=='passwordStrength'" class="password_detect" :class="{'red': passwordStrengthDetect =='Weak password', 'yellow': passwordStrengthDetect =='Medium password', 'green': passwordStrengthDetect =='Strong password'}">
          <h1>Password Strength Checker</h1>
          <label for="passwordCheck" class="label_strength">Enter a password:</label>
          <input type="password" id="passwordCheck" v-model="enterPassword" @input=checkStrength>
          <button @click=clearPassword class="secondary btn_secondary">Clear</button>
          <p id="password-strength">{{ passwordStrengthDetect }}</p>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
export default {
  name:"PasswordGenerator",
  components: {
    Header,
    Footer
  },
  computed: {
    user() {
      const store = useStore();
      return store.state.user;
    },
  },
  setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Password Generator and Strength Checker: UI Assembly',
      meta: [
        {
          name: `Password Generator and Strength Checker: UI Assembly`,
          content: 'Enhance Security: Generate Strong Passwords and also check strength of existing passwords Safely with our Password Tool.',
        },
        ],

      })
      return {
        user,
      };
    },
  data() {
    return {
      fromWebsitePerformance: true,
      passwordApp: 'passwordGenerate',
      passwordStrength:'weak',
      passwordGenerated: '',
      enterPassword: '',
      passwordStrengthDetect: ''

    };
  },
  methods: {
    generatePassword() {
      let strength = this.passwordStrength;
      console.log(strength);

      let password = "";

      if (strength === "weak") {
        password = this.generateWeakPassword();
      } else if (strength === "medium") {
        password = this.generateMediumPassword();
      } else if (strength === "strong") {
        password = this.generateStrongPassword();
      }

      this.passwordGenerated = password;
    },
    generateWeakPassword() {
      const characters = "abcdefghijklmnopqrstuvwxyz";
      return this.generateRandomPassword(characters, 8);
    },
    generateMediumPassword() {
      const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
      return this.generateRandomPassword(characters, 10);
    },
    generateStrongPassword() {
      const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
      return this.generateRandomPassword(characters, 12);
    },
    generateRandomPassword(characters, length) {
      let password = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
      }
      return password;
    },

    copyPassword() {
      const passwordInput = document.getElementById("password");
      passwordInput.select();
      document.execCommand("copy");
    },
    checkStrength() {
      const passwordStrength = document.getElementById("password-strength");
      const password = this.enterPassword;
      const uppercaseCount = (password.match(/[A-Z]/g) || []).length;
      const specialCharCount = (password.match(/[^A-Za-z0-9]/g) || []).length;

      if(password.length>0){
        if (password.length >= 12 && uppercaseCount >= 2 && specialCharCount >= 1) {
          this.passwordStrengthDetect = "Strong password";
          passwordStrength.style.color = "green";
        } else if (password.length > 8 && (uppercaseCount >= 2 || specialCharCount >= 1)) {
          this.passwordStrengthDetect = "Medium password";
          passwordStrength.style.color = "orange";
        } else {
          this.passwordStrengthDetect = "Weak password";
          passwordStrength.style.color = "red";
        }
      }
    },

    clearPassword() {
      this.enterPassword = "";
      this.passwordStrengthDetect = "";
    }

  }
}
</script>

<style scoped>
  .hero-section {
    cursor: default;
    /* background-image: url("../assets/signature.jpg"); */
    background: rgba(255, 255, 255, 0.85);
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.85) 40%, rgba(148, 255, 250, 0.85) 100%);
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    /* background-size: 100% 100%; */
    background-position: top right;
    background-size: cover;
    height: 98vh;
    position: relative;
    /* overflow: hidden; */
    /* font-size: 5rem; */
  }
  .container{
    /* background-color: aquamarine; */
    margin-top: 5rem;
    width: 90%;
  }
  .password_container{
    /* background-color: #fff; */
    /* border-top: 0.2rem solid #696969; */
  }
  .password_generate,
  .password_detect{
    padding: 2.4rem 2rem 2rem;
    border-top: 1px solid #007bff;
    
  }
  .input_radio{
    /* margin-bottom: 2.4rem; */
    transform: translateY(-0.8rem);
  }
  .input_radio input{
    display: none;
    visibility: hidden;
  }
  .input_radio label{
    padding: 0.8rem 1.6rem;
    font-size: 2rem;
    border-radius: 1.2rem 1.2rem 0 0;
    cursor: pointer;
    border: 1px solid #007bff;
  }
  .first_child{
    margin-right: 2px;
  }
  .input_radio input:checked + label{
    color: #fff;
    background-color: #091b41;
    border: 1px solid transparent;
  }
  .input_radio input:not(:checked) + label{
    color: #2c2c2c;
    background-color: #ffffff;
  }
  .passwordGenerate{
    background-color: #fff;
  }
  h1{
    color: #3f3f3f;
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
  .label_strength{
    font-size: 1.6rem;
    display: block;
    margin-bottom: 1.2rem;
    /* color: ; */
  }
  #strength{
    width: 24rem;
    border-radius: 0.8rem;
    padding: 1.6rem;
    font-size: 2rem;
    /* display: block; */
    margin-bottom: 1.2rem;
  }
  @media screen and (max-width: 450px) {
    #strength{
      width: 90%;
    }
    .btn{
      margin-left: 0 !important;
    }
    .generated_password{
      width: 90% !important;
    }
    .secondary{
      width: 90% !important;
      margin: 1rem 0 0 0 !important;
    }
  }
  #strength:focus{
    outline: none;
  }
  .btn{
    background-color: #007bff;
    margin: 0 0 2rem;
    /* display: block; */
    margin-left: 2rem;
    color: #fff;
    padding-inline: 2rem;
  }
  .generated_password{
    padding: 1.6rem 0.8rem;
    border-radius: 0.5rem;
    /* display: block; */
    font-size: 2rem;
    color: #3f3f3f;
    border: 1px solid #3f3f3f;
    width: 24rem;
  }
  .generated_password:focus{
    outline: none;
  }
  .secondary{
    margin-left: 2rem;
    width: 24rem;
    /* padding-inline: 2rem; */
  }
  .add{
    opacity: 0.5;
    cursor: not-allowed;
  }
  #passwordCheck{
    margin-bottom: 1rem;
    border: 2px solid #ccc;
    border-radius: 5px;
    color: #555;
    outline: none;
    width: 32rem;
    height: 6rem;
    font-size: 2rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
  }

  #passwordCheck:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  #password-strength{
    font-size: 2.4rem;
    margin-top: 1.6rem;
  }
  .red{
    background-color: #ff8f8f;

  }
  .yellow{
    background-color: #fff48f;
  }
  .green{
    background-color: #8fffd4;
  }
</style>