<template>
    <div class="image-converter" v-if="!convertedPng">
        <input type="file" id="uploadWebp" ref="imageInput" class="uploadFile" accept=".webp" @change="convertToPNG">
        <div class="labels">
            <i class="fa-solid fa-cloud-arrow-up upload"></i>
            <label for="uploadWebp" class="upload_label1">Upload File</label>
            <label for="uploadWebp" class="upload_label1"><span>(WEBP to PNG)</span></label>
            <label for="uploadWebp" class="upload_label2"> Drag and Drop to Upload</label>
        </div>
    </div>
    <div class="converted_image" v-if="convertedPng">
      <div class="img_container">
        <img v-if="convertedPng" :src="convertedPng" alt="Converted Image">
      </div>
      <div class="details">
        <img src="../../assets/loaderSpin.gif" alt="" v-if="spinner" class="spinner">
        <div v-if="!spinner">
          <p class="fileName" :title="fileName"><span>File Name:</span> {{ fileName }}</p>
          <p><span>File Size: </span>{{ fileSize }}</p>
          <a v-if="convertedPng" :href="convertedPng" download="converted.png">
              <button class="btn pointer">Download PNG</button>
          </a>
        </div>
      </div>
    </div>



    <!-- <div>
      <h1>WebP to PNG Converter</h1>
      <input type="file" accept=".webp" @change="convertToPNG">
      
      <div v-if="convertedPng">
        <h2>Converted Image</h2>
        <img :src="convertedPng" alt="Converted PNG">
        <a :href="convertedPng" download="converted.png"><button>Download PNG</button></a>
      </div>
    </div> -->
  </template>
  
  <script>
  export default {
    data() {
      return {
        convertedPng: null,
        spinner: true,
        fileName: '',
        fileSize: 0,
      };
    },
    methods: {
      async convertToPNG(event) {
        const selectedFile = event.target.files[0];
        this.fileName = selectedFile.name;
        this.fileSize = selectedFile.size > 1024 ? `${Number((selectedFile.size / 1024).toFixed(2))}KB` : `${selectedFile.size}B`
        if (!selectedFile) {
          return;
        }
        
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const arrayBuffer = fileReader.result;
          
          try {
            const convertedBlob = await this.convertWebpToPng(arrayBuffer);
            this.convertedPng = URL.createObjectURL(convertedBlob);
          } catch (error) {
            console.error('Conversion failed:', error);
          }
        };
        
        fileReader.readAsArrayBuffer(selectedFile);
      },
      async convertWebpToPng(webpBuffer) {
        return new Promise((resolve, reject) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          const img = new Image();
          img.src = URL.createObjectURL(new Blob([webpBuffer], { type: 'image/webp' }));
          
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            setTimeout(() => {
                this.spinner = false;
              }, 4000);
            
            canvas.toBlob(resolve, 'image/png');
          };
          
          img.onerror = reject;
        });
      },
    },
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  