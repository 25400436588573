<template>
  <!-- <div>
    <h1 class="heading">Register User</h1>
    <input type="text" placeholder="Enter your Name" v-model="name" />
    <input type="email" placeholder="Enter your Email" v-model="email" />
    <input type="password" placeholder="Enter your password" v-model="password" />
    <button @click="register">Register</button>
    <button @click="signInGoogle">Sign In with Google</button>
  </div> -->

  <div class="body">
    <div class="logo_cont">
      <router-link to="/"><img class="logo" src="../assets/imgToMock.png" alt="" draggable="false"><span>UI Assembly</span></router-link>
    </div>
    <div class="form-container">
      <div class="loader-div">
        <Loader v-if="loaderShow"/>
      </div>
      <h1 class="heading">Registration</h1>
      <form class="form" @submit.prevent="handleSubmit">
        <div class="input-group">
          <label for="name">Name</label>
          <input type="text" id="name" placeholder="Enter your Name" v-model="name"  autocomplete="off"/>
        </div>
        <div class="input-group input_password">
          <label for="email">Email</label>
          <input type="email" id="email" placeholder="Enter your Email" v-model="email" autocomplete="off"/>
        </div>
        <div class="input-group input_password">
          <label for="password">Password</label>
          <input type="password" id="password" placeholder="Enter your password" v-model="password" />
        </div>
        <button class="sign" @click="register" :disabled="email=='' || password=='' || name==''" :class="{'disable_signin': email=='' || password=='' || name==''}">Register</button>
      </form>
      <p class="signup">Already have an account?
        <router-link to="/login" rel="noopener noreferrer">Login</router-link>
      </p>
    </div>
    <transition name="alert" mode="out-in">
      <div v-if="showAlert" class="alert">
        <AlertMessage :message="message" v-if="showAlert" :fromLogin="fromLogin" :closeAlert="closeAlert"/>
      </div>
    </transition>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { db } from "../main"
import { collection, getDocs, addDoc, doc, setDoc } from "firebase/firestore";
import AlertMessage from "./AlertMessage.vue";
import Loader from "./Loader.vue";

export default {
  name: "Registration",
  props: [],
  components: { AlertMessage, Loader },
  setup() {
    const fromWebsitePerformance = ref(true);
    const name = ref("");
    const showAlert = ref(false);
    const loaderShow = ref(false);
    const message = ref("")
    const fromLogin = ref(true)
    const email = ref("");
    const password = ref("");
    const auth = getAuth();

    onMounted(async()=>{
      const querySnapshot = await getDocs(collection(db, "users"))
      // console.log(querySnapshot)
    })

    const register = () => {
      loaderShow.value = true;
      createUserWithEmailAndPassword(auth, email.value, password.value)
        .then((userCredential) => {
          const user = userCredential.user;
          updateProfile(auth.currentUser, { displayName: name.value })
            .then(() => {
              message.value = "Registratioin Successful"
              showAlert.value = true;
              loaderShow.value = false;
              sendEmailVerification(auth.currentUser)
                .then(() => {
                  message.value = "Verification Email send"
                  dataCreatedForUser();
                });
            })
            .catch((error) => {
              console.error("Error updating user profile:", error);
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
        });
    };

    const signInGoogle = () => {
      
    };
    const handleSubmit = () =>{
      console.log()
    }
    const closeAlert = () =>{
        showAlert.value = false;
    }
    const dataCreatedForUser = async() =>{
      await setDoc(doc(db, "users", auth.currentUser.uid), {
        id: auth.currentUser.uid,
        image: "",
        additional: '',
        bio: "",
        courses: [],
        location: "",
        savedposts: [],
        website: "",
        purchasedcomponents: [],
        usertype: "Free"
      });
    }

    return {
      name,
      email,
      password,
      auth,
      fromWebsitePerformance,
      register,
      dataCreatedForUser,
      signInGoogle,
      showAlert,
      message,
      loaderShow,
      fromLogin,
      closeAlert
    };
  },
};
</script>

<style scoped>
.body{
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo_cont a{
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  text-decoration: none;
  color: #111827;
}
.logo_cont img{
  width: 6.4rem;
}
.logo_cont span{
  font-size: 1.8rem;
  display: block;
  margin-left: 1rem;
  font-weight: 800;
}
.form-container {
  max-width: 320px;
  width: 90%;
  border-radius: 0.75rem;
  background-color: rgba(17, 24, 39, 1);
  padding: 2rem;
  color: rgba(243, 244, 246, 1);
  position: relative;
}
.loader-div{
  position: absolute;
  right: 14px;
  top: 14px;
}
.heading{
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
}
.form {
  margin-top: 1.5rem;
}
.input-group {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.input_password{
  margin-top: 2rem;
}
.input-group label {
  display: block;
  font-size: 1.6rem;
  color: rgba(156, 163, 175, 1);
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgba(55, 65, 81, 1);
  outline: 0;
  background-color: rgba(17, 24, 39, 1);
  padding: 0.75rem 1rem;
  color: rgba(243, 244, 246, 1);
}

.input-group input:focus {
  border-color: rgba(83,138,214,1);
}

.forgot {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba(156, 163, 175,1);
  margin: 8px 0 14px 0;
}

.forgot a,.signup a {
  color: rgba(243, 244, 246, 1);
  text-decoration: none;
  font-size: 14px;
}

.forgot a:hover, .signup a:hover {
  text-decoration: underline rgba(167, 139, 250, 1);
}

.sign {
  cursor: pointer;
  display: block;
  width: 100%;
  background-image: radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );
  padding: 1.2rem;
  text-align: center;
  font-size: 1.6rem;
  color: rgba(17, 24, 39, 1);
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  margin-top: 2rem;
}

.social-message {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.line {
  height: 1px;
  flex: 1 1 0%;
  background-color: rgba(55, 65, 81, 1);
}

.social-message .message {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(156, 163, 175, 1);
}
.google{
  cursor: pointer;
}
.twitter,
.disable_signin{
  cursor: not-allowed;
}

.signup {
  margin-top: 1.2rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1rem;
  color: rgba(156, 163, 175, 1);
}
@media screen and (max-width: 600px) {
    .body .logo_cont span{
      font-size: 4rem;
    }
    .heading{
      font-size: 3.2rem;
      margin-bottom: 8rem;
    }
    .input-group label{
      font-size: 2.4rem;
    }
    .input-group input{
      height: 40px;
      font-size: 2.4rem;
    }
    .sign{
      padding: 2rem;
      font-size: 3.2rem;
      margin-bottom: 3.2rem;
    }
    .social-message .message{
      font-size: 2.4rem;
    }
    .social-icons{
      margin-top: 2.4rem;
    }
    .social-icons .icon svg{
      width: 4.8rem;
      height: 4.8rem;
    }
    .signup{
      margin-top: 2rem;
      font-size: 2rem;
    }
}
.alert {
  position: fixed;
  top: 80px;
  z-index: 2;
  right: 50px;
}
.alert-enter-active,
.alert-leave-active {
  transition: all 0.5s ease;
}

.alert-enter-from,
.alert-leave-to {
  right: -100px;
  opacity: 0;
}

.alert-enter-to,
.alert-leave-from {
  right: 50px;
  opacity: 1;
  visibility: visible;
}
</style>