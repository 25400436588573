<template>
    <div>
      <div class="buttons_group" v-if="favoriteComponents.length">
          <div v-for="(favorites,index) in favoriteComponents" :key="index" class="button_container" :class="{'dark': favorites.mode==true}">
              <iframe :srcdoc="generateIframeContent(favorites.dataSource.htmlCode, favorites.dataSource.style)" class="button-iframe"></iframe>
              <div class="float_btn">
              <span class="get_code pointer" @click="getCode(index)"><i class="fa-solid fa-code"></i>Get Code</span>
              <div class="delete_fav pointer" @click="openDeletePopup(index)"><i class="fa-solid fa-trash-can"></i></div>
              <div class="modeDiv">
                  <input type="checkbox" :id="'mode' + favorites.index" class="mode" @change="shadowColorChange" v-model="favorites.mode">
                  <label :for="'mode' + favorites.index" class="colorMode"></label>
              </div>
              </div>
              <div class="crown" v-if="favorites.price=='Premium'">
                <i class="fa-solid fa-crown"></i>
              </div>
          </div>
          <transition name="overlay">
            <div class="modal_overLay" @click.self="closeModal" v-if="openComponentDetails">
                <ComponentModal :buttonData="favoriteComponents" :index="currentIndex" @closeModal="closeModal" :user="user"/>
            </div>
          </transition>
          <transition name="delete_popup" v-if="openDeleteModal">
              <div class="delete_content" @click.self="closeModalDelete()">
                <div class="delete_msg">
                  <div v-if="!showLoader">
                    <p>This Favorite Component will be Deleted.</p>
                    <p>You can add it Later.</p>
                    <button @click="closeModalDelete()" class="cancel pointer">Cancel</button>
                    <button @click="deleteFavorite(delCurrentIndex)" class="delete pointer">Delete</button>
                  </div>
                  <div v-if="showLoader">
                    <Loader/>
                  </div>
                </div>
              </div>
          </transition>
      </div>
      <div v-else class="absent_favorite">
        <div v-if="!isLoadingFavorites">
          <Loader :size="sizeButtons" />
        </div>
        <div v-else>
          <p>No Components added in Favorites</p>
        </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Loader from "./../../components/Loader.vue"
import ComponentModal from "../Modals/ComponentModal.vue"
import { db } from "./../../main"
import { getAuth } from 'firebase/auth';
import { collection, getDocs, addDoc, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
export default {
    name:"Buttons",
    components:{ComponentModal, Loader},
    props:['user'],
    setup(props){
      const favoriteComponents = ref([])
      const user = ref(props.user);
      const auth = getAuth();
      const currentIndex = ref(0)
      const delCurrentIndex = ref(0)
      const isLoadingFavorites = ref(false)
      const showLoader = ref(false)
      const openDeleteModal = ref(false)
      const openComponentDetails = ref(false)

      onMounted(async () => {
        const auth = getAuth();
        const userUid = auth.currentUser.uid;
        const userDocRef = doc(db, 'users', userUid);

        // Fetch the user's savedposts array from Firestore
        const userDocSnapshot = await getDoc(userDocRef);
        // const userSavedPosts = userDocSnapshot.data().savedposts || [];
        favoriteComponents.value = userDocSnapshot.data().savedposts || [];
        favoriteComponents.value.map(obj =>{
            obj.mode = false,
            obj.loaderShow = false
        })
        isLoadingFavorites.value = true;
        // const querySnapshot = await getDocs(collection(db, 'buttons'));
        // querySnapshot.forEach((doc) => {
        //   const buttonData = doc.data();

        //   const isFavorite = userSavedPosts.some((item) => {
        //     return item.index === buttonData.currentIndex && item.favorite === true;
        //   });

        //         favoriteComponents.value.push({
        //             dataSource: buttonData,
        //             favorite: isFavorite,
        //             mode: false,
        //             loaderShow: false
        //         });
        //         });
          console.log(favoriteComponents.value);
        });
        const getCode = (i) =>{
          currentIndex.value = i; 
          openComponentDetails.value=true;
        }
        const closeModal = () =>{
          openComponentDetails.value =false;
        }
        const openDeletePopup = (i) =>{
          delCurrentIndex.value = i;
          console.log(delCurrentIndex.value)
          openDeleteModal.value = true
        }
        function closeModalDelete(){
          openDeleteModal.value = false
        }
        const deleteFavorite = async(index) =>{
          showLoader.value = true;
          const indexToRemove = index;
          const docRef = doc(db, "users", auth.currentUser.uid);
          const docSnap = await getDoc(docRef);
          let currentSavedPosts = docSnap.data().savedposts || [];
          const updated = currentSavedPosts.filter((item, index) => index !== indexToRemove);
          await updateDoc(docRef, {
            savedposts: updated,
          });
          showLoader.value = false;
          favoriteComponents.value = updated;
          openDeleteModal.value = false
        }
        return{
            favoriteComponents,
            auth,
            getCode,
            currentIndex,
            delCurrentIndex,
            openComponentDetails,
            closeModal,
            openDeleteModal,
            isLoadingFavorites,
            openDeletePopup,
            closeModalDelete,
            showLoader,
            deleteFavorite
        }
    },
    methods:{
      generateIframeContent(html, css) {
      return `
        <html>
        <head>
          <style>
            ${css}
            body {
            margin: 0; /* Remove default body margin */
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80vh;
          }
          </style>
        </head>
        <body>
          ${html}
        </body>
        </html>
      `;
    },
  }

}
</script>

<style>
.heading{
  font-size: 2.8rem;
  margin-bottom: 2rem;
}
.slideIn{
  transition: all 0.3s 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  max-width: 490px;
  width: 95%;
  opacity: 1;
  visibility: visible;
}
.buttons_group{
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(274px,1fr));
  grid-gap: 20px 14px;
  align-content: stretch;
  align-items: stretch;
}
.close_remark{
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  padding: 6px;
  background-color: rgba(255, 255, 255, 0.4);
  color: #1d1e22;
}
.button_container{
  height: 56rem;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0.2rem 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  position: relative;
}
.dark{
  background-color: #1d1e22;
}
.delete_fav{
  border: 1px solid #ff7272;
  padding: 10px 12px;
  border-radius: 4px;
}
.delete_fav i{
  color: #ff7272;
}
.crown{
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px;
  border-radius: 4px;
  background: linear-gradient(40deg, #f4ff61 0%, #ff7d52 50%, #f4ff61 100%);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.crown i{
  font-size: 2rem;
  color: #fff;
}
.float_btn{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}
.float_btn i{
  font-size: 2rem;
  cursor: pointer;
}
.heart_icon{
  background-color: #f2faff;
  border: 1px solid #d0edff;
  padding: 1rem;
  border-radius: 0.4rem;
}
.absent_favorite{
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.absent_favorite p{
  font-size: 20px;
  color: #1d1e22;
}
.filled{
  color: #429EFF;
}
.get_code{
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: #f2faff;
  border: 1px solid #d0edff;
  font-size: 1.6rem;
}
.get_code i{
  margin-right: 1rem;
}
.modeDiv{
  margin-left: auto;
}
.mode{
  display: none;
}
.colorMode {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50px;
  height: 30px;
  /* background-color: rgb(82, 82, 82); */
  background-color: #e0f3ff;
  border: 1px solid #b6e3ff;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: .2s;
}
.colorMode::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  left: 5px;
  background-color: transparent;
  border-radius: 50%;
  transition-duration: .2s;
  /* box-shadow: 5px 2px 7px rgba(8, 8, 8, 0.26); */
  border: 5px solid #429EFF;
}
.mode:checked+.colorMode::after {
  transform: translateX(100%);
  transition-duration: .2s;
  background-color: #429EFF;
  /* border: none; */
}
/* Switch background change */
.mode:checked+.colorMode {
  background-color: rgba(255, 255, 255, 0.1);
  transition-duration: .2s;
}



.overlay-enter-from,
.overlay-leave-to,
.delete_popup-enter-from,
.delete_popup-leave-to{
  opacity: 0;
  visibility: hidden;
}
.overlay-enter-active,
.overlay-leave-active,
.delete_popup-enter-active,
.delete_popup-leave-active {
  transition: all 0.5s ease;
}
.delete_content{
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete_msg{
  height: 240px;
  background-color: #FFF;
  padding: 16px;
  max-width: 400px;
  width: 90%;
  border-radius: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete_msg div{
  position: relative;
}
.delete_msg div p{
  font-size: 20px;
  color: #1d1e22;
}
.delete_msg div p:first-child{
  margin-bottom: 16px;
}
.delete_msg div .delete{
  background-color: #ff7272;
  color: #fff;
  padding: 12px 30px;
  border-radius: 6px;
  border: none;
  font-size: 20px;
  margin-top: 20px;
}
.delete_msg div .cancel{
  background-color: transparent;
  border: none;
  color: #1d1e22;
  border: 1px solid #1d1e22;
  padding: 12px 30px;
  border-radius: 6px;
  font-size: 20px;
  margin-top: 20px;
  margin-right: 12px;
}
.button-iframe {
  width: 100%;
  height: 100%; /* Set the desired iframe height */
  border: none;
}
@media screen and (max-width: 500px) {
    .float_btn > *,
    .float_btn i{
        font-size: 2.8rem;
    }
  }
</style>