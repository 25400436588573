<template>
  <div class="image-converter">
    <input type="file" ref="imageInput" accept="image/png" @change="convertImage">
    <button @click="convertImage">Convert to JPEG</button>
    <img v-if="convertedImageUrl" :src="convertedImageUrl" alt="Converted Image">
    <a v-if="downloadUrl" :href="downloadUrl" download="converted_image.jpeg">
      <button>Download Converted Image</button>
    </a>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
export default {
  name: "WhiteBoard",
  setup() {

  useHead({
    // Can be static or computed
    title: 'CSS Gradient Generator',
    meta: [
      {
        name: `CSS Gradient Generator`,
        content: 'Unlock the magic of CSS gradients! Explore essential UX designer and developement tools for crafting unique, captivating designs. Use the gradient anywhere.',
      },
      ],

    })
  },
  data() {
    return {
      convertedImageUrl: '',
      downloadUrl: ''
    };
  },
  methods: {
    convertImage() {
      const file = this.$refs.imageInput.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (event) => {
        const imgDataUrl = event.target.result;
        const img = new Image();
        img.src = imgDataUrl;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            const convertedUrl = URL.createObjectURL(blob);
            this.convertedImageUrl = convertedUrl;
            
            // Set download link
            this.downloadUrl = convertedUrl;
          }, "image/jpeg", 0.8);
        };
      };

      reader.readAsDataURL(file);
    }
  }
};
</script>

<style scoped>
.image-converter {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

button {
  margin-top: 10px;
}

img {
  max-width: 100%;
  margin-top: 20px;
}
</style>
