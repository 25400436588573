<template>
  <div class="footer">
    <div class="container footer_container">
        <div class="left_container">
            <router-link to="/"><img class="logo" src="../assets/UIAssemblyLogo.png" alt=""></router-link>
            <p class="copyright">&copy;{{ currentDate }} All Rights Reserved</p>
        </div>
        <div class="right_container">
            <a href="#" class="link">Tools</a>
            <a href="#" class="link">Components</a>
            <a href="#" class="link">Blog</a>
            <a href="#" class="link">About</a>
        </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
      name: "Footer",
      components: {
          
      },
      data(){
        return{
            currentDate: '',
        }
    },
    methods:{
        
    },
    mounted(){
        let date = new Date();
        this.currentDate = date.getFullYear()
    },
  }
  </script>
  
  <style scoped>
  .footer_container{
    width: 90%;
    /* background-color: #04151E; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right_container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4rem;
  }
  .right_container a{
    display: block;
    color: #e0e0e0;
    font-size: 1.6rem;
    text-decoration: none;
    margin-bottom: 1rem;
  } 
  .copyright{
    font-size: 1.6rem;
    color: #e0e0e0;
  }
    .logo {
        height: 2.4rem;
        margin-bottom: 1rem;
    }
  </style>