<template>
  <div class="body">
    <div class="logo_cont">
      <router-link to="/"><img class="logo" src="../assets/imgToMock.png" alt="" draggable="false"><span>UI Assembly</span></router-link>
    </div>
    <div class="form-container">
      <h1 class="heading">Login</h1>
      <div class="loader-div">
        <Loader v-if="loaderShow"/>
      </div>
      <form class="form" @submit.prevent="handleSubmit">
        <div class="input-group">
          <label for="email">Email</label>
          <input type="email" id="email" placeholder="Enter your Email" v-model="email" autocomplete="off">
        </div>
        <div class="input-group input_password">
          <label for="password">Password</label>
          <input type="password" id="password" placeholder="Enter your password" v-model="password">
          <!-- <div class="forgot">
            <a rel="noopener noreferrer" href="#">Forgot Password ?</a>
          </div> -->
          <p v-if="errMessage" class="errMessage">{{ errMessage }}</p>
        </div>
        <button class="sign" @click="login" :disabled="email=='' || password==''" :class="{'disable_signin': email=='' || password==''}">Login</button>
      </form>
      <div class="social-message">
        <div class="line"></div>
        <p class="message">Login with social accounts</p>
        <div class="line"></div>
      </div>
      <div class="social-icons">
        <button aria-label="Log in with Google" class="icon google" @click="signInGoogle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-5 h-5 fill-current">
            <path d="M16.318 13.714v5.484h9.078c-0.37 2.354-2.745 6.901-9.078 6.901-5.458 0-9.917-4.521-9.917-10.099s4.458-10.099 9.917-10.099c3.109 0 5.193 1.318 6.38 2.464l4.339-4.182c-2.786-2.599-6.396-4.182-10.719-4.182-8.844 0-16 7.151-16 16s7.156 16 16 16c9.234 0 15.365-6.49 15.365-15.635 0-1.052-0.115-1.854-0.255-2.651z"></path>
          </svg>
        </button>
        <button aria-label="Log in with GitHub" class="icon twitter" disabled>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-5 h-5 fill-current">
            <path d="M16 0.396c-8.839 0-16 7.167-16 16 0 7.073 4.584 13.068 10.937 15.183 0.803 0.151 1.093-0.344 1.093-0.772 0-0.38-0.009-1.385-0.015-2.719-4.453 0.964-5.391-2.151-5.391-2.151-0.729-1.844-1.781-2.339-1.781-2.339-1.448-0.989 0.115-0.968 0.115-0.968 1.604 0.109 2.448 1.645 2.448 1.645 1.427 2.448 3.744 1.74 4.661 1.328 0.14-1.031 0.557-1.74 1.011-2.135-3.552-0.401-7.287-1.776-7.287-7.907 0-1.751 0.62-3.177 1.645-4.297-0.177-0.401-0.719-2.031 0.141-4.235 0 0 1.339-0.427 4.4 1.641 1.281-0.355 2.641-0.532 4-0.541 1.36 0.009 2.719 0.187 4 0.541 3.043-2.068 4.381-1.641 4.381-1.641 0.859 2.204 0.317 3.833 0.161 4.235 1.015 1.12 1.635 2.547 1.635 4.297 0 6.145-3.74 7.5-7.296 7.891 0.556 0.479 1.077 1.464 1.077 2.959 0 2.14-0.020 3.864-0.020 4.385 0 0.416 0.28 0.916 1.104 0.755 6.4-2.093 10.979-8.093 10.979-15.156 0-8.833-7.161-16-16-16z"></path>
          </svg>
        </button>
      </div>
      <p class="signup">Don't have an account?
        <router-link to="/registration" rel="noopener noreferrer">Register</router-link>
      </p>
    </div>
    <transition name="alert" mode="out-in">
      <div v-if="showAlert" class="alert">
        <AlertMessage :message="'Login Successful. Taking you to Homepage.'" v-if="showAlert" :fromLogin="fromLogin" :closeAlert="closeAlert"/>
      </div>
    </transition>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { db } from "../main"
import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, addDoc, doc, setDoc, getDoc } from "firebase/firestore";
import AlertMessage from "./AlertMessage.vue";
import Loader from "./Loader.vue";

export default {
  components: {
    AlertMessage,
    Loader
  },
  setup() {
    const fromWebsitePerformance = ref(true);
    const fromLogin = ref(true);
    const loaderShow = ref(false);
    const showAlert = ref(false);
    const store = useStore();
    const router = useRouter();
    const email = ref("");
    const loggedUser = ref("")
    const password = ref("");
    const errMessage = ref("");
    return {
      email,
      password,
      fromLogin,
      showAlert,
      fromWebsitePerformance,
      errMessage,
      loggedUser,
      loaderShow,
      login() {
        loaderShow.value = true;
        store
          .dispatch('loginUser', { email: email.value, password: password.value })
          .then((response) => {
            loaderShow.value = false;
            // loggedUser.value = response.email;
            // const user = response.users;
            // console.log(response.emailVerified);
            showAlert.value = true;
            setTimeout(() => {
              router.push({ name: 'home', query: { user: loggedUser.value } });
            }, 3000);
            // commit('setUser', response.email);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            switch (error.code) {
              case "auth/invalid-email":
                errMessage.value = "Invalid Email";
                break;
              case "auth/user-not-found":
                errMessage.value = "No Account with this email Exists";
                break;
              case "auth/wrong-password":
                errMessage.value = "Incorrect Password";
                break;
              default:
              errMessage.value = "Email or Password was incorrect";
              break;
            }
          });
      },

      async signInGoogle() {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        try {
          const result = await signInWithPopup(auth, provider);
          // const result = await signInWithRedirect(auth, provider);
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;

          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (!docSnap.exists()) {
            // Create a new user document if it doesn't exist
            await setDoc(doc(db, "users", user.uid), {
              id: user.uid,
              image: "",
              additional: '',
              bio: "",
              courses: [],
              location: "",
              savedposts: [],
              website: ""
            });
          }
          router.push({ name: 'home', query: { user: user.email } });
        } catch (error) {
          console.error("Google Sign-In Error:", error);
        }
      },
      closeAlert(){
        showAlert.value = false;
      },
      handleSubmit() {
        console.log()
      }
    };
  },
};
</script>

<style scoped>
.body{
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo_cont a{
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  text-decoration: none;
  color: #111827;
}
.logo_cont img{
  width: 6.4rem;
}
.logo_cont span{
  font-size: 1.8rem;
  display: block;
  margin-left: 1rem;
  font-weight: 800;
}
.form-container {
  max-width: 320px;
  width: 90%;
  border-radius: 0.75rem;
  background-color: rgba(17, 24, 39, 1);
  padding: 2rem;
  color: rgba(243, 244, 246, 1);
  position: relative;
}
.loader-div{
  position: absolute;
  right: 14px;
  top: 14px;
}
.heading{
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
}
.form {
  margin-top: 1.5rem;
}
.errMessage{
  margin-top: 4px;
  font-size: 1.2rem;
}
.input-group {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.input_password{
  margin-top: 2rem;
}
.input-group label {
  display: block;
  font-size: 1.6rem;
  color: rgba(156, 163, 175, 1);
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgba(55, 65, 81, 1);
  outline: 0;
  background-color: rgba(17, 24, 39, 1);
  padding: 0.75rem 1rem;
  color: rgba(243, 244, 246, 1);
}

.input-group input:focus {
  border-color: rgba(83,138,214,1);
}

.forgot {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba(156, 163, 175,1);
  margin: 8px 0 14px 0;
}

.forgot a,.signup a {
  color: rgba(243, 244, 246, 1);
  text-decoration: none;
  font-size: 14px;
}

.forgot a:hover, .signup a:hover {
  text-decoration: underline rgba(167, 139, 250, 1);
}

.sign {
  cursor: pointer;
  display: block;
  width: 100%;
  background-image: radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );
  padding: 1.2rem;
  text-align: center;
  font-size: 1.6rem;
  color: rgba(17, 24, 39, 1);
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  margin-top: 2rem;
}

.social-message {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.line {
  height: 1px;
  flex: 1 1 0%;
  background-color: rgba(55, 65, 81, 1);
}

.social-message .message {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(156, 163, 175, 1);
}
.google{
  cursor: pointer;
}
.twitter,
.disable_signin{
  cursor: not-allowed;
}
.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons .icon {
  border-radius: 0.125rem;
  padding: 0.75rem;
  border: none;
  background-color: transparent;
  margin-left: 8px;
}

.social-icons .icon svg {
  height: 2rem;
  width: 2rem;
  fill: #fff;
}

.signup {
  margin-top: 1.2rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1rem;
  color: rgba(156, 163, 175, 1);
}
@media screen and (max-width: 600px) {
    .body .logo_cont span{
      font-size: 4rem;
    }
    .heading{
      font-size: 3.2rem;
      margin-bottom: 8rem;
    }
    .input-group label{
      font-size: 2.4rem;
    }
    .input-group input{
      height: 40px;
      font-size: 2.4rem;
    }
    .sign{
      padding: 2rem;
      font-size: 3.2rem;
      margin-bottom: 3.2rem;
    }
    .social-message .message{
      font-size: 2.4rem;
    }
    .social-icons{
      margin-top: 2.4rem;
    }
    .social-icons .icon svg{
      width: 4.8rem;
      height: 4.8rem;
    }
    .signup{
      margin-top: 2rem;
      font-size: 2rem;
    }
}
.alert {
  position: fixed;
  top: 80px;
  z-index: 2;
  right: 50px;
}
.alert-enter-active,
.alert-leave-active {
  transition: all 0.5s ease;
}

.alert-enter-from,
.alert-leave-to {
  right: -100px;
  opacity: 0;
}

.alert-enter-to,
.alert-leave-from {
  right: 50px;
  opacity: 1;
  visibility: visible;
}
</style>