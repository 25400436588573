<template>
    <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
      <div class="editor_container">
        <div class="editor">
            <div class="code-section">
            <!-- HTML code textarea -->
                <div class="html_section">
                    <div class="html_header">
                        <h2 class="html_heading">HTML</h2>
                        <button @click="formatHtml" class="format">Format HTML</button>
                    </div>
                    <div class="parent">
                      <div class="code-editor">
                          <div class="line-numbers">
                              <div v-for="(line, index) in htmlCodeLines" :key="index" class="line-number">{{ index + 1 }}</div>
                          </div>
                          <textarea
                          id="html-code"
                          class="form-control code-textarea"
                          rows="8"
                          placeholder="Enter HTML code"
                          v-model="htmlCode"
                          @input="updatePreview"
                          ></textarea>
                      </div>
                    </div>
                </div>

                <!-- CSS code textarea -->
                <div class="html_section css_editor">
                    <div class="html_header">
                        <h2 class="html_heading">CSS</h2>
                        <button @click="formatCss" class="format">Format CSS</button>
                    </div>
                    <div class="parent">
                      <div class="code-editor">
                          <div class="line-numbers">
                          <div v-for="(line, index) in cssCodeLines" :key="index" class="line-number">{{ index + 1 }}</div>
                          </div>
                          <textarea
                          id="css-code"
                          class="form-control code-textarea"
                          rows="8"
                          placeholder="Enter CSS code"
                          v-model="cssCode"
                          @input="updatePreview"
                          ></textarea>
                      </div>
                    </div>
                </div>
            </div>
            <div class="preview-section">
            <!-- Live preview iframe -->
            <iframe id="preview" ref="previewFrame"></iframe>
            </div>
        </div>

    </div>
    </section>
    <Footer />
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useHead } from '@vueuse/head'
  import Header from "../components/Header.vue"
  import beautify from 'js-beautify';
  import Footer from "../components/Footer.vue"
  export default {
    name: "CodeEditor",
    components:{Header, beautify, Footer},
    computed: {
      user() {
        const store = useStore();
        return store.state.user;
      },
    },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Online Code editor for Frontend Developers: Write HTML and CSS',
      meta: [
        {
          name: `Online Code editor for Frontend Developers: Write HTML and CSS`,
          content: 'An online code editor for Front-end developers. Start web development using HTML, CSS and JavaScript code snippets, projects, and web applications.',
        },
        ],
      })
      return {
        user,
      };
    },
    data() {
      return {
        fromWebsitePerformance: true,
        htmlCode: '',
        cssCode: ''
      };
    },
    computed: {
    htmlCodeLines() {
      return this.htmlCode.split('\n');
    },
    cssCodeLines() {
      return this.cssCode.split('\n');
    }
  },
  methods: {
    updatePreview() {
      const combinedCode = this.htmlCode + '<style>' + this.cssCode + '</style>';
      this.$refs.previewFrame.contentDocument.documentElement.innerHTML = combinedCode;
    },
    formatHtml() {
      this.htmlCode = beautify.html(this.htmlCode, { indent_size: 2 });
      this.updatePreview();
    },
    formatCss() {
      this.cssCode = beautify.css(this.cssCode, { indent_size: 2 });
      this.updatePreview();
    }
  },
  mounted() {
    this.updatePreview();
  }
}
</script>
  
<style scoped>
  .hero-section{
    background-color: #2C3333;
    /* height: 100vh; */
  }
  .editor_container{
    width: 98%;
    margin: 2.4rem auto;
  }
  .html_header{
    display: flex;
    justify-content: space-between;
    background-color: #141414;
    padding: 1.2rem;
  }
  .css_editor{
    margin-top: 2rem;
  }
.code-editor {
  display: flex;
  overflow-y: auto;
  max-height: 32rem;
}
.parent{
  min-height: 5rem;
  resize: vertical;
  overflow: auto;
  border-top: none;
  border-left:  1px solid #5c5c5c;
  border-right:  1px solid #5c5c5c;
  border-bottom:  1px solid #5c5c5c;
}
.code-editor::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.code-editor{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.html_heading{
    font-size: 2rem;
    letter-spacing: 2px;
    color: #cccccc;
}
.line-numbers {
  padding: 10px;
  /* background-color: #f7f7f7; */
  text-align: right;
}

.line-number {
  margin-bottom: 5px;
  color: #007bff;
}
.editor {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  display: flex;
}

/* Code section styling */
.code-section {
  flex: 1;
  /* padding: 20px; */
  /* background-color: #f7f7f7; */
}

textarea {
  width: 100%;
  padding: 10px;
  border: none;
  font-family: 'Courier New', monospace;
  resize: vertical;
  background-color: #2c3333;
  margin-bottom: 10px;
  color: #fff;
  font-size: 1.6rem;
  height: 4000rem;
  overflow-y: hidden;
}
textarea::placeholder{
  color: #bbbbbb;
}
textarea:focus,
textarea:active{
  border: none;
  outline: none;
}
/* Preview section styling */
.preview-section {
  flex: 1.5;
  padding: 0 1rem;
  min-height: 100vh;
}

iframe {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.format{
  padding: 0.6rem 1.6rem;
  background-color: #007bff2f;
  border: 1px solid #007bff;
  border-radius: 4px;
  color: #007bff;
  cursor: pointer;
}
/* Media query for responsiveness */
@media (max-width: 768px) {
  .editor {
    flex-direction: column;
  }

  .code-section,
  .preview-section {
    border: none;
    padding: 20px;
  }

  .code-section {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 425px) {
    textarea{
      font-size: 2.4rem;
      line-height: 130%;
    }
    .line-number {
      font-size: 2rem;
    }
}
  </style>
  