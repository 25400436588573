import { createRouter, createWebHistory } from 'vue-router'
import Login from "../components/Login.vue"
import UserProfile from "../components/UserProfile.vue"
import Registration from "../components/Registration.vue"
import HomeView from '../views/HomeView.vue'
import QrCodeGenerator from '../views/QrCodeGenerator.vue'
import CountDownTimer from '../views/CountDown.vue'
import SipCalculator from '../views/SipCalculator.vue'
import LyricsFinder from '../views/LyricsFinder.vue'
import BmiCalculator from '../views/BmiCalculator.vue'
import SignatureMaker from '../views/SignatureMaker.vue'
import PasswordGenerator from '../views/PasswordGenerator.vue'
import WebsitePerformance from '../views/WebsitePerformance.vue'
import CssGradient from '../views/CssGradient.vue'
import WhiteBoard from '../views/WhiteBoard.vue'
import ImageConverter from '../views/ImageConverter.vue'
import ImageToPrototype from '../views/ImageToPrototype.vue'
import ImageToPrototypeOld from '../views/ImageToPrototypeOld.vue'
import ImageEditor from '../views/ImageEditor.vue'
import AllComponents from '../views/AllComponents.vue'
import UrlShorten from '../views/UrlShorten.vue'
import CodeEditor from '../views/CodeEditor.vue'
import MinifyCss from '../views/MinifyCSS.vue'
import MinifyJs from '../views/MinifyJavascript.vue'
import TextToSpeech from '../views/TextToSpeech.vue'
import CheckDiff from '../views/CheckDiff.vue'
import ColorPalette from '../views/ColorPalette.vue'
import ImageCompress from '../views/CompressImg.vue'
import JsonFormat from '../views/JsonFormatter.vue'
import CssAnimation from '../views/PlaygroundAnimation.vue'
import Blog from '../views/Blog.vue'
import ComponentsMainPage from '../views/ComponentsMainPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/qrcodegenerator',
    name: 'qrcodegenerator',
    component: QrCodeGenerator
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/userprofile',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration
  },
  {
    path: '/countdowntimer',
    name: 'countdowntimer',
    component: CountDownTimer
  },
  {
    path: '/sipcalculator',
    name: 'sipcalculator',
    component: SipCalculator
  },
  {
    path: '/lyricsfinder',
    name: 'lyricsfinder',
    component: LyricsFinder
  },
  {
    path: '/bmicalculator',
    name: 'bmicalculator',
    component: BmiCalculator
  },
  {
    path: '/signaturemaker',
    name: 'signaturemaker',
    component: SignatureMaker
  },
  {
    path: '/passwordgenerator',
    name: 'passwordgenerator',
    component: PasswordGenerator
  },
  {
    path: '/websiteperformance',
    name: 'websiteperformance',
    component: WebsitePerformance
  },
  {
    path: '/cssGradient',
    name: 'cssGradient',
    component: CssGradient
  },
  {
    path: '/whiteboard',
    name: 'whiteboard',
    component: WhiteBoard
  },
  {
    path: '/imageConverter',
    name: 'imageConverter',
    component: ImageConverter
  },
  {
    path: '/imagetoprototype',
    name: 'imagetoprototype',
    component: ImageToPrototype
  },
  {
    path: '/imagetoprototypeold',
    name: 'imagetoprototypeold',
    component: ImageToPrototypeOld
  },
  {
    path: '/imageeditor',
    name: 'imageeditor',
    component: ImageEditor
  },
  {
    path: '/urlShorten',
    name: 'urlShorten',
    component: UrlShorten
  },
  {
    path: '/codeEditor',
    name: 'codeEditor',
    component: CodeEditor
  },
  {
    path: '/minifyCss',
    name: 'minifyCss',
    component: MinifyCss
  },
  {
    path: '/minifyJs',
    name: 'minifyJs',
    component: MinifyJs
  },
  {
    path: '/textToSpeech',
    name: 'textToSpeech',
    component: TextToSpeech
  },
  {
    path: '/checkDiff',
    name: 'checkDiff',
    component: CheckDiff
  },
  {
    path: '/colorpalette',
    name: 'colorPalette',
    component: ColorPalette
  },
  {
    path: '/imageCompress',
    name: 'ImageCompress',
    component: ImageCompress
  },
  {
    path: '/allComponents',
    name: 'allComponents',
    component: AllComponents
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/cssanimation',
    name: 'CssAnimation',
    component: CssAnimation
  },
  {
    path: '/jsonformat',
    name: 'jsonformat',
    component: JsonFormat
  },
  {
    path: '/componentsmainpage',
    name: 'componentsMainPage',
    component: ComponentsMainPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
