import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import store from './store';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
// import { registerLicense } from '@syncfusion/ej2-vue-image-editor'
import { createHead } from '@vueuse/head'
const head = createHead()

// registerLicense()
const firebaseConfig = {
  apiKey: "AIzaSyA9dGc0n852m-s-Sf8g0ZpJE8Pf-SnfhYM",
  authDomain: "uiassemblynew.firebaseapp.com",
  projectId: "uiassemblynew",
  storageBucket: "uiassemblynew.appspot.com",
  messagingSenderId: "79567641579",
  appId: "1:79567641579:web:5c55df553e6541ce47d7ae",
  measurementId: "G-JS4MH2XC8M"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp);
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, store user data in Vuex
    store.dispatch('setUser', user); // Define a 'setUser' action in your store
  } else {
    // User is signed out, clear user data in Vuex
    store.dispatch('clearUser'); // Define a 'clearUser' action in your store
  }
});
// initializeApp(firebaseConfig);
export {db}
createApp(App).use(head).use(store).use(router).mount('#app')
