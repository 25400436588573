<template>
    <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance"/>
      <div class="container">
        <div class="header">
          <i class="fa-solid fa-arrow-left arr"></i>
          <h1 class="heading">JavaScript Minifier</h1>
        </div>
        <div class="css_minify">
            <div class="css_input">
                <textarea
                    v-model="inputJs"
                    placeholder="Enter your JavaScript code here..."
                ></textarea>
                <button class="btn_primary btn"  @click="minify">Minify JavaScript</button>
            </div>
            <div class="css_minified">
                <button class="copy"  @click="copyToClipboard($event)" :disabled="minifiedJs === ''" :class="{'disCopy': minifiedJs === ''}">Copy</button>
                <textarea
                    v-model="minifiedJs"
                    placeholder="Minified JavaScript will appear here..."
                    readonly
                ></textarea>
            </div>
        </div>
       </div>
    </section>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head'
  import Header from "../components/Header.vue"
  export default {
    name: "MinifyJs",
    components:{Header},
    setup() {

    useHead({
      // Can be static or computed
      title: 'JavaScript Minifier: UI Assembly',
      meta: [
        {
          name: `JavaScript Minifier: UI Assembly`,
          content: 'Boost Website Speed: Optimize JavaScript Code and Reduce File Size with JavaScript Minifier.',
        },
        ],

      })
    },
    data() {
      return {
        inputJs: "",
        minifiedJs: "",
        fromWebsitePerformance: true,
      };
    },
    methods: {
      minify() {
        // Minify the JavaScript using a simple regex
        const minified = this.inputJs.replace(/\/\/.*/g, "").replace(/\/\*[\s\S]*?\*\//g, "").replace(/\s+/g, " ").trim();
        this.minifiedJs = minified;
      },
      copyToClipboard(e) {
        // Create a text area element to copy the text
        const textArea = document.createElement("textarea");
        textArea.value = this.minifiedJs;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        e.target.innerText = "Copied";
        setTimeout(() => {
          e.target.innerText = "Copy";
        }, 2000);
      },
    },
  };
  </script>
  
  <style scoped>
  .container{
    margin-bottom: 2rem;
    width: 90%;
  }
  button {
    margin-bottom: 10px;
  }
  .header{
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .arr{
    font-size: 2rem;
    background-color: #ffffff;
    padding: 1rem 1.1rem;
    border-radius: 50%;
    color: #007bff;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .heading{
    font-size: 3rem;
    margin: 2rem 0;
  }
  .css_minify{
    display: flex;
    gap: 1rem;
  }
  .css_input{
    flex: 1;
  }
  .css_minified{
    flex: 1;
    position: relative;
  }
  .copy{
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: #e3f1ff;
    color: #007bff;
    padding: 0.6rem 2rem;
    border-radius: 0.4rem;
    border: 1px solid #007bff;
    cursor: pointer;
    opacity: 1;
  }
  .disCopy{
    opacity: 0.5;
    cursor: not-allowed;
  }
  textarea {
  width: 100%;
  padding: 10px;
  border: none;
  font-family: 'Courier New', monospace;
  resize: vertical;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  color: #1b1b1b;
  font-size: 1.8rem;
  min-height: 50vh;
}
textarea::placeholder{
  font-size: 1.6rem;
  color: #6e6e6e;
}
textarea:focus,
textarea:active{
  border: none;
  outline: none;
}
.btn {
  background-color: #007bff;
  margin-top: 0;
  color: #fff;
  padding-inline: 2rem;
}
@media (max-width: 560px) {
  .css_minify {
    flex-direction: column;
  }
}
  </style>
  