<template>
    <div class="image-converter" v-if="!downloadPdfUrl">
        <input type="file" id="uploadPng" ref="imageInputPng" class="uploadFile" accept="image/png" @change="convertToPdf">
        <div class="labels">
            <i class="fa-solid fa-cloud-arrow-up upload"></i>
            <label for="upload" class="upload_label1">Upload File</label>
            <label for="upload" class="upload_label1"><span>(PNG to PDF)</span></label>
            <label for="uploadPng" class="upload_label2"> Drag and Drop to Upload</label>
        </div>
    </div>
    <div class="converted_image" v-if="downloadPdfUrl">
      <div class="img_container">
        <img v-if="convertedPdfUrl" :src="output" alt="Converted Image">
      </div>
      <div class="details">
        <img src="../../assets/loaderSpin.gif" alt="" v-if="spinner" class="spinner">
        <div v-if="!spinner">
          <p class="fileName" :title="fileName"><span>File Name:</span> {{ fileName }}</p>
          <p><span>File Size: </span>{{ fileSize }}</p>
          <a v-if="downloadPdfUrl" :href="downloadPdfUrl" download="converted_image.pdf">
              <button class="btn pointer">Download Converted PDF</button>
          </a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import jsPDF from 'jspdf';
  
  export default {
    name: "PngToPdf",
    data() {
      return {
        convertedPdfUrl: '',
        downloadPdfUrl: '',
        fileName: '',
        fileSize: 0,
        spinner: true
      };
    },
    methods: {
      convertToPdf() {
        const file = this.$refs.imageInputPng.files[0];
        console.log(file);
        this.fileName = file.name;
        this.fileSize = file.size > 1024 ? `${Number((file.size / 1024).toFixed(2))}KB` : `${file.size}B`
        this.output = URL.createObjectURL(file);
        if (!file) return;
  
        const reader = new FileReader();
        reader.onload = (event) => {
          const imgDataUrl = event.target.result;
          const pdf = new jsPDF();
  
          const img = new Image();
          img.src = imgDataUrl;
  
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
  
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
  
            const imageData = canvas.toDataURL("image/jpeg");
            setTimeout(() => {
                this.spinner = false;
              }, 4000);
  
            pdf.addImage(imageData, "JPEG", 10, 10, 190, 0);
            const pdfUrl = pdf.output("datauristring");
  
            this.convertedPdfUrl = pdfUrl;
            this.downloadPdfUrl = pdfUrl;
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Styles remain the same */
  </style>
  