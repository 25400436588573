<template>
  <section class="hero-section">
    <Header :user="user"/>
    <div class="container">
      <div class="input_fields">
        <h1>BMI Calculator</h1>
        <label for="weight">Weight:</label>
        <input type="number" id="weight" placeholder="Enter your weight" v-model="weight">
        <select id="weightUnit" v-model="weightUnit">
            <option value="kg">kg</option>
            <option value="lbs">lbs</option>
        </select>
        <label for="height" class="height_label">Height:</label>
        <input type="number" id="height" placeholder="Enter your height" v-model="height">
        <select id="heightUnit" v-model="heightUnit">
            <option value="cm">cm</option>
            <option value="ft">ft</option>
        </select>
        <button @click="calculateBMI" class="btn_primary btn">Calculate BMI</button>
      </div>
      <div class="result_bmi" id="result_bmi" v-show="bmiCalculated">
        <!-- v-show="bmiCalculated" -->
        <p v-if="bmi" class="bmi_value">Your BMI: <strong>{{ bmi }}</strong></p>
        <p v-else class="bmi_value">Please enter valid weight and height</p>
        <div class="value_list">
          <table class="bmi_table">
              <thead>
                <td>Category</td>
                <td>BMI (Kg/m<sup>2</sup>)</td>
                <td>BMI Prime</td>
              </thead>
              <tbody>
                <tr>
                  <td>Underweight</td>
                  <td>17.0 - 18.4</td>
                  <td>0.68 - 0.73</td>
                </tr>
                <tr>
                  <td>Normal range</td>
                  <td>18.5 - 24.9</td>
                  <td>0.74 - 0.99</td>
                </tr>
                <tr>
                  <td>Overweight (Pre-obese)</td>
                  <td>25.0 - 29.9</td>
                  <td>1.00 - 1.19</td>
                </tr>
                <tr>
                  <td>Obese (Class I)</td>
                  <td>30.0 - 34.9</td>
                  <td>1.20 - 1.39</td>
                </tr>
              </tbody>
          </table>
        </div> 
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
export default {
  name:"BmiCalculator",
  components:{Header, Footer},
  computed: {
    user() {
      const store = useStore();
      return store.state.user;
    },
  },
  setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
  useHead({
    // Can be static or computed
    title: 'Calculate you Body Mass Index.',
    meta: [
      {
        name: `Calculate you Body Mass Index.`,
        content: 'A simple BMI Calculator. Enter your Body weight and Height to calculate your BMI.',
      },
      ],

    })
    return {
      user,
    };
  },
  data(){
    return{
      weight: 0,
      height: 0,
      weightInKg: 0,
      heightInCm: 0,
      weightUnit: 'kg',
      heightUnit: 'cm',
      bmi:'',
      progress: 0,
      bmiCalculated: false
    }
  },
  methods:{
    calculateBMI() {
      this.weight = parseFloat(document.getElementById("weight").value).toFixed(2);
      this.weightUnit = document.getElementById("weightUnit").value;
      this.height = parseFloat(document.getElementById("height").value).toFixed(2);
      this.heightUnit = document.getElementById("heightUnit").value;
      
      if (this.weight && this.height) {
        if(this.weight>0 && this.height>0){
          this.bmiCalculated = true;
        }
        if (this.weightUnit === "lbs") {
          this.weightInKg = (this.weight * 0.453592).toFixed(2); // convert pounds to kilograms
          this.weightUnit = 'kg';
        } else {
          this.weightInKg = this.weight;
        }

        if (this.heightUnit === "ft") {
          this.heightInCm = (this.height * 30.48).toFixed(2); // convert feet to centimeters
          this.heightUnit = 'cm';
        } else {
          this.heightInCm = this.height;
        }

        this.bmi = (this.weightInKg / ((this.heightInCm / 100) ** 2)).toFixed(2); // calculate BMI and round to 2 decimal places
        this.makeProgress();
      } else {
        this.bmi = "Please enter valid weight and height.";
      }
    },
    makeProgress() {
      if(this.bmi < 100) {
        this.progress = this.bmi;
        this.progressTrim = parseInt(this.progress).toFixed(0);
      }else{
        this.progress = 94;
        this.progressTrim = parseInt(this.bmi).toFixed(0);
      }
    }
  }
}
</script>

<style scoped>
.hero-section {
    cursor: default;
    background-image: url("../assets/bmiCzlculator.png");
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    /* background-size: 100% 100%; */
    background-position: top right;
    background-size: cover;
    height: 98vh;
    position: relative;
    /* overflow: hidden; */
    /* font-size: 5rem; */
}
  .container {
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    width: 90%;
  }
  @media screen and (max-width: 550px) {
    .container{
      width: 90%;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .input_fields{
      /* width: 100%; */
    }
    .result_bmi{
      width: 100% !important;
    }
  }
  .input_fields{
    padding: 2rem;
    background: rgb(78,162,240);
    background: linear-gradient(146deg, rgba(78,162,240,1) 0%, rgba(245,245,245,1) 100%);
    border-radius: 0.8rem;
  }

  h1 {
    font-size: 2.4rem;
    color: #e6f5ff;
    margin: 0 0 1.6rem 0;
  }

  input[type='number'],
  select{
    margin-bottom: 1rem;
    border: 2px solid #ccc;
    border-radius: 5px;
    color: #555;
    outline: none;
    width: 100%;
    height: 5rem;
    font-size: 2rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
  }

  input[type='number']:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }
  label {
    display: block;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
 .height_label{
    margin-top: 3rem;
  }
  .btn{
    width: 100%;
    background-color: #007bff;
    color: #fff;
  }

.result_bmi{
  padding: 2rem;
  background-color: #DFEAF4;
  width: 50%;
  border-radius: 0.8rem;
}
.bmi_value{
  font-size: 2rem;
}
.bmi_value strong{
  color:#007bff; 
}
.value_list{
  background-color: #fff;
  /* padding: 1.6rem; */
  border-radius: 0.6rem;
  margin-block: 2rem;
}
table thead td{
  font-size: 1.6rem;
  font-weight: 600;
  color: #007bff;
}
table td{
  font-size: 1.4rem;
}
table {
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
  /* transform: translateX(); */
}
table tr:hover {background-color: #f0faff;}
table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>