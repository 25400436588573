<template>
    <div class="image-converter" v-if="!convertedPdf">
        <input type="file" id="uploadJpeg" ref="imageInputPng" class="uploadFile" accept=".jpg, .jpeg" @change="convertToPDF">
        <div class="labels">
            <i class="fa-solid fa-cloud-arrow-up upload"></i>
            <label for="uploadJpeg" class="upload_label1">Upload File</label>
            <label for="uploadJpeg" class="upload_label1"><span>(JPEG to PDF)</span></label>
            <label for="uploadJpeg" class="upload_label2"> Drag and Drop to Upload</label>
        </div>
    </div>
    <div class="converted_image" v-if="convertedPdf">
      <div class="img_container">
        <img v-if="convertedPdf" :src="output" alt="Converted Image">
      </div>
      <div class="details">
        <img src="../../assets/loaderSpin.gif" alt="" v-if="spinner" class="spinner">
        <div v-if="!spinner">
          <p class="fileName" :title="fileName"><span>File Name:</span> {{ fileName }}</p>
          <p><span>File Size: </span>{{ fileSize }}</p>
          <a v-if="convertedPdf" :href="convertedPdf" download="converted_image.pdf">
              <button class="btn pointer">Download Converted PDF</button>
          </a>
        </div>
      </div>
    </div>



    <!-- <div>
      <h1>JPEG to PDF Converter</h1>
      <input type="file" accept=".jpg, .jpeg" @change="convertToPDF">
      
      <div v-if="convertedPdf">
        <h2>Converted PDF</h2>
        <embed :src="convertedPdf" type="application/pdf" width="100%" height="600px" />
        <a :href="convertedPdf" download="converted.pdf">
          <button>Download PDF</button>
        </a>
      </div>
    </div> -->
  </template>
  
  <script>
  import jsPDF from 'jspdf';
  
  export default {
    data() {
      return {
        convertedPdf: null,
        convertedPdfUrl: '',
        fileName: '',
        fileSize: 0,
        spinner: true
      };
    },
    methods: {
      async convertToPDF(event) {
        const selectedFile = event.target.files[0];
        this.fileName = selectedFile.name;
        this.fileSize = selectedFile.size > 1024 ? `${Number((selectedFile.size / 1024).toFixed(2))}KB` : `${selectedFile.size}B`
        this.output = URL.createObjectURL(selectedFile);
        if (!selectedFile) {
          return;
        }
  
        const pdf = new jsPDF();
        const img = new Image();
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          
          const dataURL = canvas.toDataURL('image/jpeg');
          setTimeout(() => {
            this.spinner = false;
            }, 4000);
          pdf.addImage(dataURL, 'JPEG', 10, 10, 190, 0);
          this.convertedPdf = pdf.output('datauristring');
        };
  
        img.src = URL.createObjectURL(selectedFile);
      },
    },
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  