<template>
    <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
        <div class="container">
            <div class="header">
                <!-- <i class="fa-solid fa-arrow-left arr"></i> -->
                <h1 class="heading">Difference Checker</h1>
            </div>
        <!-- Show input text fields when not in comparison mode -->
            <div v-if="!isComparing">
                <div class="textarea-container">
                    <div class="textarea_left">
                        <textarea placeholder="Enter Your Text" v-model="text1"></textarea>
                    </div>
                    <div class="textarea_right">
                        <textarea placeholder="Enter Your Text" v-model="text2"></textarea>
                    </div>
                </div>
                <button class="btn_primary btn" @click="startComparison" :disabled="text1=='' || text2==''">Compare Text</button>
                <button class="clear" @click="clearText" >Clear</button>
            </div>
    
        <!-- Show comparison and "New Comparison" button when in comparison mode -->
            <div v-else>
                <div class="comparison-container">
                    <div class="highlighted-text">
                        <div class="highlight_one">
                            <div class="line-numbers">
                                <div v-for="(line, index) in lineNumbers" :key="index">{{ line }}</div>
                            </div>
                            <div class="highlighted-textarea" v-html="highlightedDiffText1"></div>
                        </div>
                        <div class="highlight_one">
                            <div class="line-numbers">
                                <div v-for="(line, index) in lineNumbers" :key="index">{{ line }}</div>
                            </div>
                            <div class="highlighted-textarea" v-html="highlightedDiffText2"></div>
                        </div>
                    </div>
                </div>
                <button class="clear" @click="startNewComparison">New Comparison</button>
            </div>
        </div>
    </section>
    <Footer />
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useHead } from '@vueuse/head'
  import Header from "../components/Header.vue"
  import { diff_match_patch } from 'diff-match-patch';
  import Footer from "../components/Footer.vue"
  
  export default {
    name: "CheckDiff",
    components:{Header, Footer},
    computed: {
      user() {
        const store = useStore();
        return store.state.user;
      },
    },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Online Text Comparison Tool: Discover Differences in Two Text Files: UI Assembly',
      meta: [
        {
          name: `Online Text Comparison Tool: Discover Differences in Two Text Files: UI Assembly`,
          content: 'Easily Compare Text Files: Paste your files, click to find differences. Streamline text comparison effortlessly.',
        },
        ],

      })
      return {
        user,
      };
    },
    data() {
      return {
        fromWebsitePerformance: true,
        text1: "",
        text2: "",
        highlightedDiffText1: "",
        highlightedDiffText2: "",
        isComparing: false,
      };
    },
    computed: {
      lineNumbers() {
        // Generate line numbers based on the number of lines in text1 or text2, whichever is longer
        const lines1 = this.text1.split("\n");
        const lines2 = this.text2.split("\n");
        const maxLines = Math.max(lines1.length, lines2.length);
  
        return Array.from({ length: maxLines }, (_, i) => i + 1);
      },
    },
    methods: {
      startComparison() {
        const dmp = new diff_match_patch();
        const diffs = dmp.diff_main(this.text1, this.text2);
        dmp.diff_cleanupSemantic(diffs);
  
        const { highlightedText1, highlightedText2 } = this.highlightDifferences(diffs);
        this.highlightedDiffText1 = highlightedText1;
        this.highlightedDiffText2 = highlightedText2;
        
        // Enter comparison mode
        this.isComparing = true;
      },
      clearText(){
        this.text1 = "";
        this.text2 = "";
      },
      startNewComparison() {
        // Clear the input text fields and exit the comparison mode
        // this.text1 = "";
        // this.text2 = "";
        this.highlightedDiffText1 = "";
        this.highlightedDiffText2 = "";
        this.isComparing = false;
      },
      highlightDifferences(diffs) {
        let highlightedText1 = "";
        let highlightedText2 = "";
  
        for (const [operation, text] of diffs) {
          if (operation === 0) {
            // No difference, keep the text as-is
            highlightedText1 += text;
            highlightedText2 += text;
          } else if (operation === -1) {
            // Text removed, highlight in red
            highlightedText1 += `<span style="background-color: #ff8888">${text}</span>`;
            highlightedText2 += `<span></span>`; // Empty span to align with text2
          } else if (operation === 1) {
            // Text added, highlight in green
            highlightedText1 += `<span></span>`; // Empty span to align with text1
            highlightedText2 += `<span style="background-color: #88ff88">${text}</span>`;
          }
        }
  
        return { highlightedText1, highlightedText2 };
      },
    },
  };
  </script>
  
  <style scoped>
  .container{
    margin-top: 2rem;
    margin-bottom: 4rem;
    width: 90%;
  }
  .textarea-container {
    display: flex;
    gap: 1rem;
  }
  .header{
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .arr{
    font-size: 2rem;
    background-color: #ffffff;
    padding: 1rem 1.1rem;
    border-radius: 50%;
    color: #007bff;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .heading{
    font-size: 3rem;
    margin: 2rem 0;
  }
  .textarea_left,
  .textarea_right {
    flex: 1;
  }
  
  .comparison-container {
    display: flex;
  }
  
  .line-numbers {
    padding-right: 10px;
    text-align: right;
  }
  
  .line-numbers div {
    color: #007bff;
    margin-bottom: 2.5px;
  }
  
  .highlighted-text {
    flex: 1;
    display: flex;
    gap: 2rem;
  }
  .highlight_one{
    display: flex;
    flex: 1;
    padding: 1rem;
    font-size: 1.6rem;
    border: 1px solid #e9e9e9;
    box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.1);
    min-height: 64vh;
  }
  
  .highlighted-textarea {
    flex: 1;
    white-space: pre-wrap;
    line-height: 140%;
  }
  textarea {
  width: 100%;
  padding: 10px;
  border: none;
  font-family: 'Courier New', monospace;
  resize: vertical;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  color: #1b1b1b;
  font-size: 1.8rem;
  min-height: 64vh;
}
textarea::placeholder{
  font-size: 1.6rem;
  color: #6e6e6e;
}
textarea:focus,
textarea:active{
  border: none;
  outline: none;
}
.btn {
  background-color: #007bff;
  margin-top: 0;
  color: #fff;
  padding-inline: 2rem;
  margin-right: 1rem;
}
.clear{
  background-color: #fff;
  border: 1px solid #007bff;
  color: #007bff;
  padding: 1.2rem 2rem;
  font-size: 2rem;
  border-radius: 0.4rem;
  margin-top: 2rem;
  cursor: pointer;
}
@media (max-width: 560px) {
  .highlighted-text,
  .textarea-container{
    flex-direction: column;
  }
  textarea{
    min-height: 40vh;
  }
}
@media screen and (max-width: 425px) {
  textarea{
    font-size: 2.4rem;
  }
  textarea::placeholder{
    font-size: 2.4rem;
  }
}
  </style>
  