<template>
    <div class="component_content">
      <Header :fromWebsitePerformance="fromWebsitePerformance"/>
      <section class="hero-section">
        <div class="container">
          <h1 class="heading">Tools that impact in your Productivity😀</h1>
          <div class="all_components">
            <div class="compose text_composer">
                <h2 class="sub_heading">Text Managing Tools</h2>
                <div class="all_tools">
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Word Counter</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Uppercase to Lowercase</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  
                </div>
            </div>
            <div class="compose image_composer">
                <h2 class="sub_heading">Image Conversion Tools</h2>
                <div class="all_tools">
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Word Counter</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Uppercase to Lowercase</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                </div>
            </div>
            <div class="compose for_designer">
                <h2 class="sub_heading">For Designers</h2>
                <div class="all_tools">
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Word Counter</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Uppercase to Lowercase</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  
                </div>
            </div>
            <div class="compose for_developers">
                <h2 class="sub_heading">For Developers</h2>
                <div class="all_tools">
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Word Counter</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Uppercase to Lowercase</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  
                </div>
            </div>
            <div class="compose password_mgmt">
                <h2 class="sub_heading">Password Management</h2>
                <div class="all_tools">
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Word Counter</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Uppercase to Lowercase</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  
                </div>
            </div>
            <div class="compose calculation_tools">
                <h2 class="sub_heading">Calculation Tools</h2>
                <div class="all_tools">
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Word Counter</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Uppercase to Lowercase</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  
                </div>
            </div>
            <div class="compose website_management">
                <h2 class="sub_heading">Website Management Tools</h2>
                <div class="all_tools">
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Word Counter</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Uppercase to Lowercase</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>Text to Speech</p>
                  </div>
                  <div class="tools">
                    <i class="fa-regular fa-file-lines"></i>
                    <p>MD5 Generate</p>
                  </div>
                  
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head'
  import Header from "../components/Header.vue"
  export default {
    name:"ComponentsMainPage",
    components:{Header},
    setup() {

    useHead({
      // Can be static or computed
      title: 'CSS Gradient Generator',
      meta: [
        {
          name: `CSS Gradient Generator`,
          content: 'Unlock the magic of CSS gradients! Explore essential UX designer and developement tools for crafting unique, captivating designs. Use the gradient anywhere.',
        },
        ],

      })
    },
    data() {
      return {
        fromWebsitePerformance: true,
      };
    },
    methods: {

    }
  }
  </script>
  
  <style scoped>
    .component_content{
      /* position: relative; */
    }
    .hero-section {
      cursor: default;
      background: rgba(255, 255, 255, 0.85);
      /* background: linear-gradient(120deg, rgba(255, 255, 255, 0.85) 40%, rgba(148, 255, 250, 0.45) 100%); */
      /* position: relative; */
      min-height: 90vh;
    }
    .container{
      padding-top: 5rem;
      margin-bottom: 5rem;
      width: 90%;
      /* position: relative; */
    }
    .heading{
        font-size: 2.8rem;
        margin-bottom: 3.2rem;
    }
    .sub_heading{
        font-size: 2rem;
        z-index: 1;
        position: relative;
        color: #485e8d;
        text-align: center;
        margin-top: 0.4rem;
    }
    .compose{
      /* height: 26rem;/ */
      padding: 1rem 1rem 2.4rem 1rem;
      border-radius: 0.6rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      /* border: 1px solid #ececec; */
      /* background: linear-gradient(135deg, #fffef5 0%, #fff9f5 100%); */
      background-color: #fff;
      position: relative;
      overflow: hidden;
    }
    .compose::before{
      /* content: ""; */
      position: absolute;
      bottom: 80%;
      right: -63%;
      width: 120rem;
      height: 120rem;
      z-index: 0;
      border-radius: 50%;
    }
    /* .text_composer{
      background-color: #f5f6fa;
    }
    .image_composer{
      background-color: #fcfaf2;
    }
    .for_designer{
      background-color: #fffff4;
    }
    .for_developers{
      background-color: #fffbf8;
    } */
    .text_composer i{
      color: #9098d4;
    }
    .image_composer i{
      color: #eedc99;
    }
    .for_designer i{
      color: #dee056;
    }
    .for_developers i{
      color: #ffbb7c;
    }
    .text_composer::before{
      background-color: #cdd2e9;
    }
    .image_composer::before{
      background-color: #f3e7bc;
    }
    .for_designer::before{
      background-color: #faffcd;
    }
    .for_developers::before{
      background-color: #ffefd8;
    }
    .password_mgmt::before{
      background-color: #c5fff0;
    }
    .calculation_tools::before{
      background-color: #ccdcf0;
    }
    .website_management::before{
      background-color: #fddbe4;
    }
    .all_tools{
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
    }
    .tools{
      cursor: pointer;
      font-size: 1.6rem;
      text-align: center;
      max-width: 16rem;
      min-height: 10rem;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.05);
      padding: 1.2rem;
      border-radius: 0.6rem;
    }
    .text_composer .tools{
      box-shadow: #9098d4 2.4px 2.4px 0;
    }
    .image_composer .tools{
      box-shadow: #e2d08d 2.4px 2.4px 0;
    }
    .for_designer .tools{
      box-shadow: #dee056 2.4px 2.4px 0;
    }
    .for_developers .tools{
      box-shadow: #f7d09b 2.4px 2.4px 0;
    }
    .text_composer .tools{
      box-shadow: #9098d4 2.4px 2.4px 0;
    }
    .text_composer .tools{
      box-shadow: #9098d4 2.4px 2.4px 0;
    }
    .text_composer .tools{
      box-shadow: #9098d4 2.4px 2.4px 0;
    }
    .text_composer .tools{
      box-shadow: #9098d4 2.4px 2.4px 0;
    }
    .tools i{
      margin-bottom: 1rem;
      font-size: 3.2rem;
    }
    .all_components{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }
  </style>