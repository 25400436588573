<template>
    <div class="main_content">
        <div class="content">
            <i class="fa-solid fa-xmark" @click="closeModal"></i>
            <div class="html_container" v-if="user && user.emailVerified">
                <div class="code_header">
                    <div class="selection">
                        <input type="radio" name="code" value="preview" id="preview" v-model="checkedCode">
                        <label for="preview" class="heading">View</label>
                        <input type="radio" name="code" value="html" id="html" v-model="checkedCode" v-if="buttonData[index].price=='Free'">
                        <label for="html" class="heading" v-if="buttonData[index].price=='Free'">HTML</label>
                        <input type="radio" name="code" value="css" id="css" v-model="checkedCode" v-if="buttonData[index].price=='Free'">
                        <label for="css" class="heading" v-if="buttonData[index].price=='Free'">CSS</label>
                    </div>
                    <button class="btn btn_html" v-if="checkedCode=='html'" @click="copyText(buttonData[index].dataSource.htmlCode, 'html')"><span>Copy</span><i class="fa-solid fa-check tickSolid" v-if="copiedHtml"></i></button>
                    <button class="btn btn_css" v-if="checkedCode=='css'" @click="copyText(buttonData[index].dataSource.style, 'css')"><span>Copy</span><i class="fa-solid fa-check tickSolid" v-if="copiedCss"></i></button>
                </div>
                <div class="preview_block" v-if="checkedCode=='preview'">
                    <iframe :srcdoc="generateIframeContent(buttonData[index].dataSource)" class="button-iframe"></iframe>
                </div>
                <div class="code_block" v-show="checkedCode=='html'" v-if="buttonData[index].price=='Free'">
                    <pre class="code">{{ beautifyHtmlCode(buttonData[index].dataSource.htmlCode) }}</pre>
                </div>
                <div class="css_code_block" v-show="checkedCode=='css'" v-if="buttonData[index].price=='Free'">
                    <pre class="code">{{ beautifyStyleCode(buttonData[index].dataSource.style) }}</pre>
                </div>
            </div>
            <div v-else class="login_container">
                <div class="login">
                    <p>Login to View the source code😊</p>
                    <button @click="$router.push({ name: 'login' })">Login</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import beautify from 'js-beautify';
export default {
  name:"ComponentModal",
  components:{beautify},
  props:['buttonData', 'index', 'user'],
  data() {
    return {
        copiedHtml: false,
        copiedCss: false,
        checkedCode: 'preview',
    }
  },
  mounted(){
    // console.log('buttonData:', this.buttonData[this.index]);
    // setTimeout(() => {
    //     this.buttonData[this.index].dataSource.htmlCode = beautify.html(this.buttonData[this.index].dataSource.htmlCode, { indent_size: 2 });
    //     this.buttonData[this.index].dataSource.style = beautify.html(this.buttonData[this.index].dataSource.style, { indent_size: 2 });
    // }, 1000);
  },
  methods:{
    generateIframeContent(button) {
        return `
          <html>
          <head>
            <style>
              ${button.style}
              body {
              margin: 0; /* Remove default body margin */
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2rem;
              height: 80vh;
            }
            </style>
          </head>
          <body>
            ${button.htmlCode}
          </body>
          </html>
        `;
    },
    beautifyHtmlCode(code) {
      return beautify.html(code, { indent_size: 2 });
    },
    beautifyStyleCode(code) {
      return beautify.css(code, { indent_size: 2 });
    },
    closeModal(){
        this.$emit('closeModal');
    },
    copyText(value, click) {
        navigator.clipboard.writeText(value)
        .then(() => {
            if(click=='html'){
                this.copiedHtml= true;
                setTimeout(() => {
                    this.copiedHtml= false;
                }, 2000);
            }else if(click=='css'){
                this.copiedCss = true;
                setTimeout(() => {
                    this.copiedCss= false;
                }, 2000);
            }
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    },
  }

}
</script>

<style scoped>
.tickSolid{
    font-size: 1.2rem;
    color: #0edb8c;
    margin-left:0.4rem 
}
    .main_content{
        height: 100%;
        background-color: #fff;
        max-width: 690px;
        width: 95%;
        opacity: 1;
        visibility: visible;
        position: relative;
        transition: all 0.3s 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
    }
    /* .slideIn{
        transition: all 0.3s 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        max-width: 490px;
        width: 95%;
        opacity: 1;
        visibility: visible;
    } */
    .fa-xmark{
        font-size: 3.2rem;
        padding: 1rem;
        cursor: pointer;
        position: absolute;
        left: -50px;
        top: 0px;
        color: #fff;
    }
    input[type='radio']{
        display: none;
    }
    input[type='radio']:checked+.heading{
        background-color: rgba(0, 0, 0, 0.4);
    }
    .html_container{
        background-color: #fff;
        border-radius: 0.8rem;
        width: 90%;
        overflow: hidden;
        box-shadow: 0rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.1);
    }
    .login_container{
        height: 90vh;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login_container p{
        font-size: 2.4rem;
        max-width: 320px;
        text-align: center;
        width: 95%;
    }
    .login_container .login{
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;
    }
    .login_container button{
        background-color: transparent;
        border: none;
        font-size: 2.4rem;
        color: #fff;
        cursor: pointer;
        background-color: #111827;
        border-radius: 6px;
        padding: 1.2rem 0.6rem;
    }
    .css{
        margin-top: 2rem;
    }
    .code_header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #36425e;
        padding: 0.4rem 2rem 0.4rem 0.5rem;
    }

    .heading{
        font-size: 2rem;
        color: #fff;
        padding: 1rem 2rem;
        margin-bottom: 0;
        border-radius: 0.4rem;
    }
    .selection{
        display: flex;
        gap: 1rem;
    }
    .preview_block{
        width: 100%;
        height: 85vh;
    }
    .preview_block iframe{
        width: 100%;
        height: 100%; /* Set the desired iframe height */
        border: none;
    }
    .code_block{
        height: 100%;
        max-height: 56rem;
        height: 80vh;
        overflow-y: auto;
        padding: 1.2rem;
        overflow-x: hidden;
    }
    .css_code_block{
        height: 100%;
        max-height: 56rem;
        height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 1.2rem;
    }
    .code{
        font-size: 1.6rem;
    }
    .code::selection{
        background-color: #98b9ff;
    }
    .btn{
        background-color: #FFFFFF;
        border: 1px solid rgb(209,213,219);
        border-radius: .5rem;
        color: #111827;
        font-size: 1.2rem;
        font-weight: 600;
        padding: .75rem 1.6rem;
        cursor: copy;
    }
    .btn:focus {
        outline: 2px solid rgba(255, 255, 255, 0.411);
        outline-offset: 2px;
    }
    @media screen and (max-width: 425px) {
        .heading{
            font-size: 2.4rem;
        }
        .btn{
            font-size: 2rem;
        }
        .code{
            font-size: 2.4rem;
        }
    }
</style>