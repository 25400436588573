<template>
    <div class="image-converter" v-if="!convertedImg">
        <input type="file" id="uploadJpeg" class="uploadFile" accept="image/jpeg" @change="convertImage">
        <div class="labels">
            <i class="fa-solid fa-cloud-arrow-up upload"></i>
            <label for="uploadJpeg" class="upload_label1">Upload File</label>
            <label for="uploadJpeg" class="upload_label1"><span>(JPEG to PNG)</span></label>
            <label for="uploadJpeg" class="upload_label2"> Drag and Drop to Upload</label>
        </div>
    </div>
    <div class="converted_image" v-if="convertedImg">
      <div class="img_container">
        <img v-if="convertedImg" :src="convertedImg" alt="Converted Image">
      </div>
      <div class="details">
        <img src="../../assets/loaderSpin.gif" alt="" v-if="spinner" class="spinner">
        <div v-if="!spinner">
          <p class="fileName" :title="fileName"><span>File Name:</span> {{ fileName }}</p>
          <p><span>File Size: </span>{{ fileSize }}</p>
          <a v-if="convertedImg" :href="convertedImg" download="converted_image.png">
              <button class="btn pointer">Download Converted Image</button>
          </a>
          <!-- <button v-if="convertedImg" @click="downloadImage">Download PNG</button> -->
        </div>
      </div>
    </div>

    <!-- <div>
      <input type="file" accept="image/jpeg" @change="convertImage" />
      <img v-if="convertedImg" :src="convertedImg" alt="Converted Image" width="200"/>
      <button v-if="convertedImg" @click="downloadImage">Download PNG</button>
    </div> -->
  </template>
  
  <script>
  export default {
    name: "JpgToPng",
    data() {
      return {
        convertedImg: null,
        fileName: '',
        fileSize: 0,
        spinner: true
      };
    },
    methods: {
      convertImage(event) {
        const file = event.target.files[0];
        this.fileName = file.name;
        this.fileSize = file.size > 1024 ? `${Number((file.size / 1024).toFixed(2))}KB` : `${file.size}B`
        if (!file || file.type !== 'image/jpeg') return;
  
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
  
          image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);
            setTimeout(() => {
                this.spinner = false;
              }, 4000);
  
            const convertedImageUrl = canvas.toDataURL('image/png');
            this.convertedImg = convertedImageUrl;
          };
        };
  
        reader.readAsDataURL(file);
      },
      downloadImage() {
        if (this.convertedImg) {
          const a = document.createElement('a');
          a.href = this.convertedImg;
          a.download = 'converted_image.png';
          a.click();
        }
      }
    }
  };
  </script>
  