<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <section class="hero-section">
      <div class="glowing-circle"></div>
            <div class="night">
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
            </div>
            <Header :user="user"/>
            <div class="hero-container container">
                <h1 class="hero-title">The only place for Web Components</h1>
                <p class="hero-description">Use beautiful custom elements made with CSS.</p>
                <div class="btn-container">
                    <router-link to="/allComponents" class="link"><button class="cta-btn">Get Started</button></router-link>
                    <!-- <img class="github-img" src="../assets/bi_github.png" alt=""> -->
                </div>
            </div>
        </section>


    
<section class="section-features">

<svg class="feature-img" width="745" height="489" viewBox="0 0 745 489" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="undraw_options_re_9vxh 1">
        <path id="Vector"
            d="M103.105 488.788C29.4792 358.555 170.695 291.858 188.872 429.381C189.792 411.633 190.767 393.6 196.663 376.835C205.616 342.056 254.266 316.854 277.502 352.06C289.786 373.874 273.496 404.475 287.622 425.146C296.532 373.122 372.069 385.326 364.116 437.525C358.144 462.603 331.648 477.198 310.499 488.543L103.105 488.788Z"
            fill="#F2F2F2" />
        <path id="Vector_2"
            d="M353.296 406.013C315.923 418.14 269.217 438.567 265.428 483.216C265.32 484.986 262.55 484.999 262.659 483.216C263.627 467.45 271.311 453.023 281.95 441.613C293.614 429.103 308.73 420.559 324.337 413.93C327.387 413.224 359.148 398.198 353.296 406.013Z"
            fill="white" />
        <path id="Vector_3"
            d="M258.874 340.708C233.009 383.897 220.703 433.854 223.547 484.115C223.649 485.896 220.88 485.89 220.777 484.115C218.573 444.611 225.608 405.136 241.325 368.826C243.09 365.576 257.33 331.354 258.874 340.708Z"
            fill="white" />
        <path id="Vector_4"
            d="M123.332 356.615C141.565 397.61 152.851 441.352 156.729 486.05C156.883 487.825 154.113 487.815 153.959 486.05C150.943 451.584 143.494 417.65 131.796 385.09C131.829 381.463 115.867 352.026 123.332 356.615Z"
            fill="white" />
        <path id="Vector_5"
            d="M444.116 486.548C423.039 449.268 463.464 430.175 468.667 469.542C468.931 464.462 469.21 459.299 470.897 454.5C473.46 444.544 487.387 437.33 494.038 447.408C497.555 453.653 492.892 462.412 496.935 468.33C499.486 453.437 521.109 456.931 518.832 471.873C517.123 479.052 509.538 483.23 503.484 486.478L444.116 486.548Z"
            fill="#F2F2F2" />
        <path id="Vector_6"
            d="M515.735 462.853C505.037 466.324 491.667 472.172 490.582 484.953C490.551 485.46 489.758 485.463 489.789 484.953C490.198 480.464 492.149 476.255 495.312 473.044C498.758 469.583 502.891 466.883 507.445 465.119C508.318 464.917 517.41 460.616 515.735 462.853Z"
            fill="white" />
        <path id="Vector_7"
            d="M488.706 444.159C481.302 456.522 477.779 470.823 478.593 485.21C478.622 485.72 477.83 485.718 477.8 485.21C477.169 473.902 479.183 462.602 483.682 452.208C484.188 451.277 488.264 441.481 488.706 444.159Z"
            fill="white" />
        <path id="Vector_8"
            d="M449.906 448.712C455.125 460.447 458.356 472.969 459.466 485.764C459.51 486.272 458.717 486.269 458.673 485.764C457.809 475.898 455.677 466.184 452.329 456.863C452.338 455.825 447.769 447.399 449.906 448.712Z"
            fill="white" />
        <path id="Vector_9"
            d="M460.799 19H743.799C744.064 19 744.319 18.8946 744.506 18.7071C744.694 18.5196 744.799 18.2652 744.799 18C744.799 17.7348 744.694 17.4804 744.506 17.2929C744.319 17.1054 744.064 17 743.799 17H460.799C460.534 17 460.28 17.1054 460.092 17.2929C459.905 17.4804 459.799 17.7348 459.799 18C459.799 18.2652 459.905 18.5196 460.092 18.7071C460.28 18.8946 460.534 19 460.799 19Z"
            fill="#3F3D56" />
        <path id="vector1" class="vector1"
            d="M519 34C509.611 34 502 26.3888 502 17C502 7.61116 509.611 0 519 0C528.389 0 536 7.61116 536 17C536 26.3888 528.389 34 519 34Z"
            fill="#0E5D84" />
        <path id="Vector_10"
            d="M460.799 83H743.799C744.064 83 744.319 82.8947 744.506 82.7071C744.694 82.5196 744.799 82.2652 744.799 82C744.799 81.7348 744.694 81.4804 744.506 81.2929C744.319 81.1054 744.064 81 743.799 81H460.799C460.534 81 460.28 81.1054 460.092 81.2929C459.905 81.4804 459.799 81.7348 459.799 82C459.799 82.2652 459.905 82.5196 460.092 82.7071C460.28 82.8947 460.534 83 460.799 83Z"
            fill="#3F3D56" />
        <path id="vector2" class="vector2"
            d="M677 98C667.611 98 660 90.3888 660 81C660 71.6112 667.611 64 677 64C686.389 64 694 71.6112 694 81C694 90.3888 686.389 98 677 98Z"
            fill="#0E5D84" />
        <path id="Vector_11"
            d="M460.799 147H743.799C744.064 147 744.319 146.895 744.506 146.707C744.694 146.52 744.799 146.265 744.799 146C744.799 145.735 744.694 145.48 744.506 145.293C744.319 145.105 744.064 145 743.799 145H460.799C460.534 145 460.28 145.105 460.092 145.293C459.905 145.48 459.799 145.735 459.799 146C459.799 146.265 459.905 146.52 460.092 146.707C460.28 146.895 460.534 147 460.799 147Z"
            fill="#3F3D56" />
        <path id="vector3" class="vector3"
            d="M610 162C600.611 162 593 154.389 593 145C593 135.611 600.611 128 610 128C619.389 128 627 135.611 627 145C627 154.389 619.389 162 610 162Z"
            fill="#0E5D84" />
        <path id="hand1" class="hand1"
            d="M439.406 173.744C439.028 174.258 438.69 174.801 438.397 175.368L381.526 181.763L373.254 170.588L354.757 180.866L371.04 208.011L440.714 189.726C442.487 191.43 444.737 192.555 447.165 192.949C449.593 193.344 452.083 192.99 454.304 191.935C456.526 190.88 458.374 189.174 459.602 187.043C460.83 184.912 461.381 182.458 461.18 180.006C460.979 177.555 460.037 175.223 458.479 173.32C456.921 171.417 454.82 170.034 452.456 169.354C450.093 168.674 447.578 168.73 445.247 169.514C442.916 170.298 440.879 171.774 439.406 173.744Z"
            fill="#FFB8B8" />
        <path id="Vector_12"
            d="M358.799 190.588C359.257 190.387 359.684 190.12 360.066 189.797L382.923 170.543C383.472 170.08 383.92 169.508 384.237 168.864C384.554 168.219 384.734 167.516 384.765 166.798C384.797 166.081 384.679 165.364 384.419 164.694C384.159 164.025 383.764 163.416 383.257 162.907L362.515 142.08C361.644 141.198 360.485 140.659 359.25 140.56C358.014 140.462 356.784 140.811 355.785 141.544L340.1 152.934C339.121 153.646 338.421 154.676 338.121 155.849C337.821 157.021 337.939 158.261 338.456 159.355L352.026 188.046C352.603 189.266 353.633 190.214 354.897 190.689C356.162 191.163 357.561 191.127 358.799 190.588Z"
            fill="#0E5D84" />
        <path id="Vector_13"
            d="M269.648 462.909L283.3 466.623L304.122 415.736L283.975 410.254L269.648 462.909Z"
            fill="#FFB8B8" />
        <path id="Vector_14"
            d="M295.188 465.665L267.784 458.209L263.274 474.786L306.178 486.459L306.471 485.382C307.589 481.271 307.029 476.885 304.913 473.187C302.797 469.489 299.299 466.784 295.188 465.665Z"
            fill="#2F2E41" />
        <path id="Vector_15"
            d="M386.351 473.326L400.498 473.325L407.229 418.756L386.348 418.757L386.351 473.326Z"
            fill="#FFB8B8" />
        <path id="Vector_16"
            d="M411.72 469.282H383.319V486.461H427.783V485.345C427.783 481.085 426.09 477 423.078 473.987C420.065 470.975 415.98 469.282 411.72 469.282Z"
            fill="#2F2E41" />
        <path id="Vector_17"
            d="M344.997 104.73C333.928 93.6616 333.928 75.7159 344.997 64.6473C356.065 53.5787 374.011 53.5787 385.08 64.6473C396.148 75.7159 396.148 93.6617 385.08 104.73C374.011 115.799 356.065 115.799 344.997 104.73Z"
            fill="#FFB8B8" />
        <path id="Vector_18"
            d="M387.217 434.418H406.55C407.872 434.416 409.143 433.911 410.106 433.006C411.07 432.101 411.653 430.864 411.738 429.545C417.499 337.964 414.129 272.27 401.133 222.806C401.076 222.59 401.006 222.377 400.923 222.169L399.887 219.581C399.505 218.615 398.84 217.787 397.98 217.205C397.12 216.622 396.104 216.313 395.066 216.316H355.309C354.559 216.315 353.819 216.477 353.138 216.79C352.458 217.103 351.853 217.56 351.366 218.129L345.966 224.43C345.86 224.553 345.762 224.678 345.667 224.81C321.317 258.766 299.495 325.153 274.221 414.383C273.848 415.68 273.996 417.071 274.634 418.261C275.272 419.45 276.349 420.343 277.635 420.75L298.413 427.398C299.605 427.774 300.894 427.709 302.042 427.215C303.191 426.721 304.125 425.831 304.673 424.707L358.59 312.878C358.967 312.097 359.586 311.457 360.355 311.055C361.123 310.652 362.001 310.508 362.858 310.644C363.715 310.779 364.506 311.187 365.113 311.807C365.721 312.427 366.112 313.225 366.23 314.085L382.072 429.928C382.246 431.17 382.862 432.308 383.807 433.133C384.752 433.958 385.962 434.414 387.217 434.418Z"
            fill="#2F2E41" />
        <path id="Vector_19"
            d="M348.146 224.891C348.2 224.891 348.254 224.89 348.309 224.889L396.601 223.364C397.842 223.329 399.03 222.849 399.947 222.011C400.864 221.174 401.449 220.034 401.596 218.801C407.718 169.247 390.928 155.972 383.426 152.565C382.799 152.282 382.256 151.842 381.849 151.288C381.442 150.733 381.185 150.083 381.104 149.4L380.455 144.206C380.344 143.289 379.99 142.418 379.429 141.684C367.665 126.445 351.603 121.566 343.075 120.014C342.384 119.89 341.674 119.907 340.989 120.066C340.305 120.224 339.659 120.52 339.093 120.935C338.526 121.351 338.049 121.877 337.692 122.483C337.335 123.088 337.105 123.759 337.015 124.456L336.043 131.914C335.944 132.626 335.66 133.299 335.22 133.866C310.687 165.938 338.017 213.291 343.745 222.458C344.213 223.202 344.863 223.816 345.632 224.241C346.402 224.667 347.266 224.89 348.146 224.891Z"
            fill="#0E5D84" />
        <path id="hand2"
            d="M352.65 295.324C351.166 294.284 349.928 292.93 349.024 291.36C348.12 289.789 347.571 288.039 347.417 286.233C347.262 284.428 347.505 282.61 348.129 280.908C348.752 279.207 349.742 277.663 351.027 276.385L342.057 168.059L368.764 171.733L369.272 276.981C371.276 279.283 372.366 282.239 372.336 285.29C372.306 288.341 371.157 291.275 369.108 293.536C367.059 295.797 364.252 297.228 361.219 297.558C358.185 297.887 355.136 297.093 352.65 295.324Z"
            fill="#FFB8B8" />
        <path id="Vector_20"
            d="M340.998 179.627C341.493 179.699 341.996 179.699 342.491 179.628L372.08 175.421C372.791 175.32 373.474 175.073 374.084 174.694C374.695 174.316 375.22 173.815 375.628 173.223C376.035 172.631 376.315 171.961 376.449 171.256C376.584 170.55 376.571 169.824 376.411 169.124L369.868 140.468C369.597 139.259 368.9 138.187 367.905 137.448C366.909 136.71 365.681 136.354 364.445 136.445L345.107 137.787C343.9 137.871 342.76 138.374 341.884 139.209C341.008 140.044 340.451 141.158 340.309 142.36L336.603 173.88C336.446 175.221 336.816 176.571 337.637 177.644C338.457 178.716 339.663 179.427 340.998 179.627Z"
            fill="#0E5D84" />
        <path id="Vector_21"
            d="M101.189 488.636H512.292C512.578 488.636 512.853 488.522 513.055 488.32C513.258 488.117 513.371 487.843 513.371 487.557C513.371 487.271 513.258 486.996 513.055 486.794C512.853 486.591 512.578 486.478 512.292 486.478H101.189C100.902 486.478 100.628 486.591 100.426 486.794C100.223 486.996 100.11 487.271 100.11 487.557C100.11 487.843 100.223 488.117 100.426 488.32C100.628 488.522 100.902 488.636 101.189 488.636Z"
            fill="#3F3D56" />
        <path id="Vector_22"
            d="M333.854 67.0617C335.959 60.6801 337.513 54.1298 338.499 47.4826C338.808 43.6127 337.817 39.4487 334.999 36.7786C329.725 31.7816 320.763 34.1616 315.248 38.8908C307.276 45.7256 303.301 56.2292 301.798 66.6214C300.296 77.0137 300.957 87.5817 300.595 98.0758C300.233 108.57 298.733 119.36 293.382 128.396C288.032 137.431 278.104 144.366 267.632 143.597C263.405 143.369 259.392 141.668 256.288 138.789C254.764 137.34 253.61 135.546 252.923 133.559C252.235 131.571 252.034 129.448 252.337 127.367C246.985 136.951 249.698 149.941 257.681 157.475C265.665 165.01 277.945 167.14 288.441 163.923C298.937 160.706 307.575 152.64 312.735 142.951C317.894 133.261 319.785 122.068 319.511 111.094C319.301 102.667 317.868 94.1707 319.292 85.862C320.716 77.5533 325.916 69.1169 334.116 67.1603L333.854 67.0617Z"
            fill="#2F2E41" />
        <path id="Vector_23"
            d="M370.677 77.9854C372.075 82.6554 377.422 85.5352 382.232 84.7454C387.043 83.9555 390.998 79.994 392.46 75.3434C393.782 70.5935 393.241 65.5184 390.948 61.1539C389.684 58.5377 387.917 56.1965 385.747 54.2641C383.578 52.3317 381.049 50.8461 378.304 49.8923C375.545 48.9777 372.605 48.7398 369.734 49.1985C366.864 49.6573 364.145 50.7993 361.808 52.5283C361.559 51.4792 361.035 50.5154 360.289 49.736C359.544 48.9567 358.605 48.3901 357.568 48.0944C355.48 47.5317 353.276 47.5642 351.205 48.1883C345.239 49.8886 340.069 53.6497 336.615 58.803C333.204 63.9403 330.948 69.7559 330.002 75.8491C328.374 84.9868 328.974 94.9718 334.106 102.706C339.238 110.439 349.738 115.006 358.256 111.319C358.787 108.603 357.528 105.869 356.073 103.517C354.617 101.164 352.892 98.8717 352.333 96.1621C351.773 93.4525 352.846 90.1381 355.466 89.252C357.702 88.496 360.086 89.7208 362.442 89.8687C363.78 89.9527 365.116 89.6834 366.317 89.0879C367.518 88.4924 368.541 87.5916 369.284 86.4758C370.027 85.36 370.463 84.0686 370.549 82.7309C370.635 81.3933 370.368 80.0565 369.774 78.8547L370.677 77.9854Z"
            fill="#2F2E41" />
    </g>
</svg>


<div class="features">
    <div class="feature feature-1">
        <svg class="bracket-svg" width="120" height="81" viewBox="0 0 100 81" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Group 2">
                <path id="bracket2" class="bracket2"
                    d="M16.226 70.2256C13.2271 70.2256 10.9601 69.4759 9.42498 67.9765C7.88985 66.4771 7.12229 64.3172 7.12229 61.4968V50.4653C7.12229 49.5728 6.90808 48.9302 6.47967 48.5375C6.05127 48.1091 5.4622 47.8949 4.71249 47.8949H2.94531V42.4326H4.71249C5.4622 42.4326 6.05127 42.2184 6.47967 41.79C6.90808 41.3616 7.12229 40.719 7.12229 39.8622V28.8307C7.12229 25.9746 7.88985 23.8147 9.42498 22.351C10.9601 20.8516 13.2271 20.1019 16.226 20.1019H18.9035V25.457H17.7789C16.458 25.457 15.4584 25.814 14.7801 26.528C14.1375 27.242 13.8162 28.2416 13.8162 29.5269V39.7015C13.8162 41.1296 13.6377 42.2363 13.2807 43.0217C12.9236 43.8071 12.3524 44.3962 11.567 44.7889C10.8173 45.1459 9.83554 45.4137 8.62171 45.5922V44.7353C9.83554 44.8781 10.8173 45.1459 11.567 45.5386C12.3524 45.9313 12.9236 46.5204 13.2807 47.3058C13.6377 48.0912 13.8162 49.1979 13.8162 50.626V60.8006C13.8162 62.0859 14.1375 63.0855 14.7801 63.7995C15.4584 64.5135 16.458 64.8705 17.7789 64.8705H18.9035V70.2256H16.226Z"
                    fill="#0C94B5" />
                <path id="bracket1" class="bracket1"
                    d="M65.774 70.2256C68.7729 70.2256 71.0399 69.4759 72.575 67.9765C74.1101 66.4771 74.8777 64.3172 74.8777 61.4968V50.4653C74.8777 49.5728 75.0919 48.9302 75.5203 48.5375C75.9487 48.1091 76.5378 47.8949 77.2875 47.8949H79.0547V42.4326H77.2875C76.5378 42.4326 75.9487 42.2184 75.5203 41.79C75.0919 41.3616 74.8777 40.719 74.8777 39.8622V28.8307C74.8777 25.9746 74.1101 23.8147 72.575 22.351C71.0399 20.8516 68.7729 20.1019 65.774 20.1019H63.0965V25.457H64.2211C65.542 25.457 66.5416 25.814 67.2199 26.528C67.8625 27.242 68.1838 28.2416 68.1838 29.5269V39.7015C68.1838 41.1296 68.3623 42.2363 68.7193 43.0217C69.0764 43.8071 69.6476 44.3962 70.433 44.7889C71.1827 45.1459 72.1645 45.4137 73.3783 45.5922V44.7353C72.1645 44.8781 71.1827 45.1459 70.433 45.5386C69.6476 45.9313 69.0764 46.5204 68.7193 47.3058C68.3623 48.0912 68.1838 49.1979 68.1838 50.626V60.8006C68.1838 62.0859 67.8625 63.0855 67.2199 63.7995C66.5416 64.5135 65.542 64.8705 64.2211 64.8705H63.0965V70.2256H65.774Z"
                    fill="#0C94B5" />
                <g id="star" class="star-svg">
                    <path id="Line 1" d="M41.0847 30.5237L41.0847 58.1702" stroke="#0C94B5"
                        stroke-width="5.02662" stroke-linecap="round" />
                    <path id="Line 2" d="M29.165 51.0618L53.551 38.0369" stroke="#0C94B5"
                        stroke-width="5.02662" stroke-linecap="round" />
                    <path id="Line 3" d="M29.1488 38.0374L53.0913 51.8606" stroke="#0C94B5"
                        stroke-width="5.02662" stroke-linecap="round" />
                </g>
            </g>
        </svg>
        <h2 class="feature-title">Easy to Use</h2>
        <p class="feature-description">Easy to start your project with our easy to use code standard</p>


    </div>
    <div class="feature feature-2">
        <svg width="66" height="57" viewBox="0 0 66 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 3">
                <line id="base" y1="41.0871" x2="66" y2="41.0871" stroke="#0C94B5" stroke-width="5.20847" />
                <rect id="laptop" x="4.34044" y="2.89171" width="57.2932" height="32.987" rx="2.60424"
                    stroke="#0C94B5" stroke-width="5.20847" />
                <rect id="mobile" class="mobile-svg" x="29.5146" y="16.7809" width="25.1743"
                    height="37.3274" rx="2.60424" fill="#030E14" stroke="#0C94B5" stroke-width="5.20847" />
            </g>
        </svg>
        <h2 class="feature-title">Responsive</h2>
        <p class="feature-description">Easy to start your project with our easy to use code standard</p>

    </div>
    <div class="feature feature-3">
        <svg class="academy" width="81" height="110" viewBox="0 0 81 110" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Group 4">
                <path id="rope" class="rope-svg" d="M16.045 39.3254L15.1583 72.1333" stroke="#0C94B5"
                    stroke-width="5.54187" />
                <path id="handle" class="handle-svg"
                    d="M23.1386 83.6604L7.41566 83.6604C6.88087 79.389 7.41568 71.9903 7.41561 69.9309C7.41554 67.5973 7.91897 60.1629 15.1583 60.1629C21.8967 60.1629 23.1386 65.5832 23.1386 68.5579V83.6604Z"
                    fill="#030E14" stroke="#0C94B5" stroke-width="5.54187" />
                <path id="Rectangle 14"
                    d="M10.0311 37.5949L39.4729 19.3786C40.3306 18.848 41.4145 18.848 42.2722 19.3786L71.7141 37.5949C73.461 38.6757 73.3756 41.2446 71.5607 42.2071L42.1189 57.821C41.3394 58.2343 40.4057 58.2343 39.6262 57.821L10.1844 42.2071C8.36953 41.2446 8.28414 38.6757 10.0311 37.5949Z"
                    fill="#030E14" stroke="#0C94B5" stroke-width="5.3202" />
                <path id="Vector 6"
                    d="M67.4736 45.9757V60.1629C67.4736 69.9571 59.5338 77.8969 49.7396 77.8969H32.0056"
                    stroke="#0C94B5" stroke-width="5.3202" stroke-linecap="round" />
            </g>
        </svg>
        <h2 class="feature-title">Tutorials</h2>
        <p class="feature-description">Easy to start your project with our easy to use code standard</p>


    </div>
    <div class="feature feature-4">
        <svg width="68" height="69" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 5">
                <rect id="Rectangle 15" x="3" y="3" width="51" height="51" rx="5" stroke="#0C94B5"
                    stroke-width="6" />
                <line id="Line 5" x1="12" y1="16" x2="45" y2="16" stroke="#0C94B5" stroke-width="6" />
                <line id="Line 6" x1="12" y1="28" x2="45" y2="28" stroke="#0C94B5" stroke-width="6" />
                <line id="Line 7" x1="12" y1="40" x2="45" y2="40" stroke="#0C94B5" stroke-width="6" />
                <g id="tick" class="tick-svg">
                    <rect x="26" y="28" width="41.5" height="41" rx="20.5" fill="#030E14" />
                    <path id="Vector 7"
                        d="M36 48.1429L41.1665 53.6088C41.9019 54.3868 43.1195 54.4478 43.9289 53.7471L57.5 42"
                        stroke="#0C94B5" stroke-width="6" stroke-linecap="round" />
                </g>
            </g>
        </svg>
        <h2 class="feature-title">Documentation</h2>
        <p class="feature-description">Easy to start your project with our easy to use code standard</p>
    </div>
</div>
</section>
<section class="slide-tags">
<div class="slides-transparent">
    <marquee class="slides-all" behavior="scroll" direction="left" scrollamount="12">
        <div class="slide-one">
            <p>Buttons</p>
            <p>Cards</p>
            <p>Accordion</p>
            <p>Carousel</p>
            <p>Collapse</p>
            <p>Dropdowns</p>
            <p>Modals</p>
        </div>
    </marquee>
    <marquee class="slides-all" behavior="scroll" direction="right" scrollamount="20">
        <div class="slide-one">
            <p>Pagination</p>
            <p>Progress Bar</p>
            <p>Spinners</p>
            <p>Tooltips</p>
            <p>Layouts</p>
            <p>Tables</p>
            <p>Forms</p>
            <p>Navbar</p>
        </div>
    </marquee>
</div>
</section>
<section class="subscribe-form">
  <video class="background-video" autoplay loop muted>
      <source src="../assets/particles-7959.mp4" type="video/mp4">
  </video>
  <div class="form-container">
      <p>Subscribe to our Newsletter to receive New Components, Blog post, Tutorials and Many more <span>NO
              SPAM</span>.
      </p>
      <div class="input-group">
          <form action="" class="form-enter">
              <input required="" type="text" name="text" autocomplete="off" class="input">
              <label class="user-label">Email Address</label>
              <button class="submit-btn">Subscribe</button>
          </form>
      </div>
  </div>
</section>
<Footer />
</div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
export default {
  name: 'HomeView',
  components: {
      Header,
      Footer
  },
  data(){
    return{
       
    }
  },
  computed: {
    user() {
      const store = useStore();
      return store.state.user;
    },
  },
  setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    onMounted(() => {
      
    });
    useHead({
    // Can be static or computed
    title: 'UI Assembly: Get beautiful customised Web components made of CSS.',
    meta: [
        {
        name: `UI Assembly: Get beautiful customised Web components made of CSS.`,
        content: 'Discover a treasure trove of exquisite CSS web components on our platform. Empower your web development and design projects by seamlessly integrating these captivating elements into your websites.',
        },
        ],

    })
    return {
      user,
    }
},
mounted(){
    
},
created() {
    
  },
}
</script>

<style scoped>
.container {
    max-width: 1080px;
    margin: 0 auto;
}

.hero-section {
    cursor: default;
    background-image: url("../assets/Hero Image UiAssembly.png");
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    /* background-size: 100% 100%; */
    background-position: center;
    background-size: cover;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.hero-container {
    margin-top: 10rem;
    width: 90%;
    /* max-width: 720px; */
}

.hero-title {
    max-width: 72rem;
    width: 95%;
    font-size: 6rem;
    color: #f2f2d2;
    margin-bottom: 1.6rem;
    opacity: 0;
    animation: movebanner 1s 0.5s forwards;
}

@keyframes movebanner {
    0% {transform: translateY(0);}
    100% {transform: translateY(-40px);opacity:1;}
}

.hero-description {
    font-size: 2.4rem;
    color: #f2f2f2;
    margin-bottom: 2.4rem;
    opacity: 0;
    animation: movebanner 1s 0.8s forwards;
}

.github-img {
    width: 5rem;
    cursor: pointer;
}

.btn-container {
    margin-top: 8rem;
    display: flex;
    justify-content: start;
    gap: 2rem;
    align-items: center;
    opacity: 0;
    animation: movebanner 1s 1s forwards;
}

.cta-btn {
    border: none;
    color: #fff;
    background-image: linear-gradient(10deg, #126FAD, #2F96DB);
    border-radius: 0.2rem;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 17px;
    padding: 2rem 6rem;
}

.cta-btn:hover {
    cursor: pointer;
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: shadow 2s infinite;
    animation: shadow 1.5s infinite;
    transition: all 0.3s ease-in-out;
}

@keyframes shadow {
    0% {
        box-shadow: 0 0 0 0 #05bada66;
    }

    70% {
        box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }

    100% {
        box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
}



.glowing-circle {
    position: absolute;
    top: 130px;
    right: 272px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-animation: glowing 1s ease-in-out infinite alternate;
    -moz-animation: glowing 1s ease-in-out infinite alternate;
    animation: glowing 1s ease-in-out infinite alternate;
}

@keyframes glowing {
    from {
        box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #126FAD, 0 0 40px #0ff, 0 0 50px #126FAD, 0 0 60px #126FAD, 0 0 70px #126FAD;
    }

    to {
        box-shadow: 0 0 50px #fff, 0 0 80px #2F96DB, 0 0 1100px #2F96DB, 0 0 140px #2F96DB, 0 0 1700px #2F96DB, 0 0 200px #2F96DB, 0 0 230px #2F96DB;
    }
}

/* --------------------------------------- */
.night {
    position: absolute;
    right: 0;
    width: 40%;
    height: 40%;
    transform: rotateZ(140deg);
}

.star {
    position: absolute;
    left: 50%;
    height: 4px;
    background: linear-gradient(-45deg, #5F91FF, rgba(0, 0, 255, 0));
    border-radius: 999px;
    filter: drop-shadow(0 0 6px #699BFF);
    animation: tail 5s ease-in-out infinite,
        falling 5s ease-in-out infinite;
}

@keyframes tail {
    0% {
        width: 0;
    }

    30% {
        width: 100px;
    }

    100% {
        width: 0;
    }
}

@keyframes falling {
    0% {
        opacity: 0.5;
        transform: translateX(0);
    }

    100% {
        transform: translateX(1000px);
        opacity: 1;
    }
}

.star::before,
.star::after {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    right: 0;
    height: 4px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5F91FF, rgba(0, 0, 255, 0));
    border-radius: 100%;
    transform: translateX(50%) rotateZ(45deg);
    animation: shining 3s ease-in-out infinite;
}

@keyframes shining {
    0% {
        width: 0;
    }

    50% {
        width: 30px;
    }

    100% {
        width: 0;
    }
}

.star::after {
    transform: translateX(50%) rotateZ(-45deg);
}

.star:nth-child(1) {
    top: calc(50% - 180px);
    left: calc(50% - 180px);
    animation-delay: 1s;
}

.star:nth-child(1)::before,
.star:nth-child(1)::after {
    animation-delay: 1s;
}

.star:nth-child(2) {
    top: calc(50% - 110px);
    left: calc(50% - 200px);
    animation-delay: 1.2s;
}

.star:nth-child(2)::before,
.star:nth-child(2)::after {
    animation-delay: 1.2s;
}

.star:nth-child(3) {
    top: calc(50% - 30px);
    left: calc(50% - 215px);
    animation-delay: 1.4s;
}

.star:nth-child(3)::before,
.star:nth-child(3)::after {
    animation-delay: 1.4s;
}

.star:nth-child(4) {
    top: calc(50% - -50px);
    left: calc(50% - 200px);
    animation-delay: 1.6s;
}

.star:nth-child(4)::before,
.star:nth-child(4)::after {
    animation-delay: 1.6s;
}

.star:nth-child(5) {
    top: calc(50% - -140px);
    left: calc(50% - 250px);
    animation-delay: 1.8s;
}

.star:nth-child(5)::before,
.star:nth-child(5)::after {
    animation-delay: 1.8s;
}

/* --------------- Feature Section ------------------------ */
.section-features {
    padding: 8rem;
    position: relative;
    background-color: #030E14;
}

.features {
    margin-left: auto;
    background-color: #030E14;
    max-width: 80rem;
    display: grid;
    /* column-gap: 4rem; */
    row-gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
    z-index: 2;
}

.feature-title {
    margin: 1rem 0;
    color: #f2f2f2;
    font-size: 3rem;
}

.feature-description {
    color: #DDDDDD;
    font-size: 2rem;
}

.feature {
    border: 1px solid #327C8D;
    padding: 2rem;
    border-radius: 0.6rem;
    background-color: rgb(3, 14, 20);
    transition: all 0.3s ease-in-out;
    cursor: default;
    z-index: 3;
}

.feature:hover {
    box-shadow: -5px 5px 20px 0 rgba(64, 170, 228, 0.3);
}

.feature-1,
.feature-2 {
    margin-left: 6rem;
}

.feature-3,
.feature-4 {
    margin-right: 6rem;
}

.feature-img {
    width: 80rem;
    position: absolute;
    left: -24.2rem;
    top: 10rem;
    z-index: 1;
}

.vector1 {
    transform: translateX(0px);
    animation: slide 1.2s ease-in-out infinite alternate-reverse;
}

.vector2 {
    transform: translateX(0px);
    animation: slide2 2s cubic-bezier(.52, 0, .19, .99) infinite alternate-reverse;
}

.vector3 {
    transform: translateX(0px);
    animation: slide3 5s ease-in-out infinite alternate-reverse;
}

.hand1 {
    transform-origin: 50% 50%;
    animation: rotateHand 2s ease-in-out infinite alternate-reverse;
}

@keyframes rotateHand {
    0% {
        transform: rotateZ(0deg);
    }

    50% {
        transform: rotateZ(10deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes slide {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(200px);
    }
}

@keyframes slide2 {
    0% {
        transform: translateX(-200px);
    }

    100% {
        transform: translateX(50px);
        fill: #5AB5CF;
    }
}

@keyframes slide3 {
    0% {
        transform: translateX(-120px);
    }

    100% {
        transform: translateX(110px);
    }
}

.bracket-svg {
    transform: translateX(-10px);
}

.feature-1:hover .bracket2 {
    transform: translateX(0px);
    transition: all 0.5s ease-in;
    /* transform: translateX(20px); */
    animation: bracket2 0.5s ease-in;
}

@keyframes bracket2 {
    0% {
        transform: translateX(0px);
    }

    70% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0px);
    }
}

.feature-1:hover .bracket1 {
    transform: translateX(0px);
    transition: all 0.5s ease-in;
    /* transform: translateX(20px); */
    animation: bracket1 0.5s ease-in;
}

@keyframes bracket1 {
    0% {
        transform: translateX(0px);
    }

    70% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0px);
    }
}

.feature-2:hover .mobile-svg {
    transform: translateX(0px);
    transition: all 0.5s ease-in;
    /* transform: translateX(20px); */
    animation: mobile 0.5s linear;
}

@keyframes mobile {
    0% {
        transform: translateX(0px);
    }

    80% {
        transform: translateX(-20px);
    }

    100% {
        transform: translateX(0px);
    }
}

.feature-3:hover .handle-svg {
    transform: translateY(0px);
    transition: all 0.5s ease-in;
    /* transform: translateX(20px); */
    animation: rope 0.5s linear;
}

@keyframes rope {
    0% {
        transform: translateY(0px);
    }

    80% {
        transform: translateY(15px);
    }

    100% {
        transform: translateY(0px);
    }
}

.academy {
    transform: translateY(-6px);
}

.feature-4:hover .tick-svg {
    transform: scale(1);
    transition: all 0.5s ease-in;
    /* transform: translateX(20px); */
    animation: tick 0.5s linear;
    transform-origin: bottom right 60px;
}

@keyframes tick {
    0% {
        transform: scale(1);
    }

    80% {
        transform: scale(0.2);
    }

    100% {
        transform: scale(1);
    }
}

/* ----------------------- Slides --------------------- */

.slide-tags {
    padding: 8rem 0;
    background-color: #04151E;
    font-size: 2rem;
    color: #f2f2f2;
}

.slides-all:first-child {
    margin-bottom: 4rem;
}

.slide-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8rem;
}

.slide-tags {
    position: relative;
    z-index: 10;
}

.slide-tags::before {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    top: 0;
    width: 20rem;
    height: 100%;
    background: rgb(4, 21, 30);
    background: linear-gradient(90deg, rgba(4, 21, 30, 1) 53%, rgba(4, 21, 30, 0) 100%);
}

.slide-tags::after {
    content: "";
    position: absolute;
    right: 0;
    z-index: 10;
    top: 0;
    width: 20rem;
    height: 100%;
    background: rgb(4, 21, 30);
    background: linear-gradient(90deg, rgba(4, 21, 30, 0) 0%, rgba(4, 21, 30, 1) 43%);
}

.slide-one p {
    padding: 1rem 3.2rem;
    border: 1px solid #1899DD;
    border-radius: 7px;
    transition: all 0.3s ease-in-out;
}

.slide-one p:hover {
    background-color: #0C94B5;
    color: #fff;
    cursor: default;
    animation: dance 0.3s cubic-bezier(.52, 0, .19, .99);
}

@keyframes dance {
    0% {
        transform: translateX(2px);
    }

    40% {
        transform: translateX(-8px);
    }

    80% {
        transform: translateX(6px);
    }

    100% {
        transform: translateX(0px);
    }
}

/* ------------------- Form Subscribe  --------------- */
.subscribe-form {
    position: relative;
    padding: 8rem 0;
    background-color: #030E14;
}

.subscribe-form::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 14, 20, 0.5);
    z-index: 2;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.form-container {
    max-width: 72rem;
    width: 90%;
    margin: 0 auto;
    font-size: 2rem;
    color: #f2f2f2;
    cursor: default;
}

.form-container span {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 0.1rem;
}

.form-container p {
    z-index: 3;
    position: relative;
}

.input-group {
    position: relative;
    margin-top: 4.8rem;
    z-index: 3;
}

.form-enter {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.input {
    width: 72%;
    border: solid 1px #9e9e9e;
    border-radius: 0.4rem;
    background: none;
    padding: 2rem;
    font-size: 2rem;
    color: #f5f5f5;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.user-label {
    position: absolute;
    left: 15px;
    color: #e8e8e8;
    pointer-events: none;
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input:focus,
input:valid {
    outline: none;
    border: 1.5px solid #0C94B5;
}

.input:focus~label,
input:valid~label {
    transform: translateY(-90%) scale(0.8);
    background-color: #030E14;
    padding: 0.2em 1rem;
    color: #0C94B5;
}

.submit-btn {
    cursor: pointer;
    padding: 2.1rem 6rem;
    background-color: #0C94B5;
    font-size: 2rem;
    color: #fff;
    border: none;
    border-radius: 0.4rem;
}

.footer-1,
.footer-3 {
    text-align: left;
}

.footer-2,
.footer-4 {
    text-align: right;
}



@media screen and (max-width: 1200px) {
    .feature-img {
        left: -28rem;
        width: 70rem;
        top: 20.4rem;
    }
}
@media screen and (min-width: 817px) and (max-width: 999px) {
    .feature-img{
        display: none;
    }
}
@media screen and (min-width: 440px) and (max-width: 599px) {
    .feature-1,
    .feature-2 {
        margin-left: 0;
    }
    
    .feature-3,
    .feature-4 {
        margin-right: 0;
    }
    .features{
        margin: 0 auto;
        column-gap: 4rem;
    }
    .feature-img{
        display: none;
    }
    .feature svg:not(.bracket-svg){
        width: 9rem;
        height: auto;
    }
    .bracket-svg{
        width: 15rem;
        height: auto;
    }
}

@media screen and (min-width: 200px) and (max-width: 439px) {
    .feature-1,
    .feature-2 {
        margin-left: 0;
    }
    
    .feature-3,
    .feature-4 {
        margin-right: 0;
    }
    .features{
        grid-template-columns: 1fr;
        margin-left: 0;
        column-gap: 4rem;
    }
    .section-features{
        display: flex;
        flex-direction: column-reverse;
    }
    .feature-img{
        left: -30vw;
        width: 70rem;
        top: -10rem;
        position: relative;
    }
}
@media screen and (max-width: 65rem) {
    .hero-section {
        /* background-attachment: fixed; */
        /* background-size: 100% 100%; */
        background-position: bottom right;
        height: 100vh;
    }
}
@media screen and (max-width: 425px) {
    .feature-description{
        font-size: 2.4rem;
    }
    .slide-tags::before {
        width: 8rem;
    }

    .slide-tags::after {
        width: 8rem;
    }
    .hero-container{
        margin-top: 22rem;
    }
}
@media screen and (max-width: 600px) {
    .hero-container{
        margin-top: 24rem;
    }
}
</style>
