<template>
  <section class="hero-section">
    <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
    <div class="container">
      <div class="top_header">
        <h1 class="heading">Make Your eSignature</h1>
        <div class="radio_input">
          <label>
            <input type="radio" id="value-1" name="radioBtn" value="draw" v-model="currentState">
            <span>DRAW</span>
          </label>
          <label>
            <input type="radio" id="value-2" name="radioBtn" value="type" v-model="currentState">
            <span>TYPE</span>
          </label>
          <span class="slider"></span>
        </div>
      </div>
        
      <div class="signature" v-show="currentState=='draw'">
        <div class="input_fields">
            <div id="colorPicker">
              <label for="penColor">Pen Color</label>
              <label for="penColor" class="color_pen pointer">
                <div :style="{'backgroundColor': penColor}"></div>
              </label>
              <input class="pointer" type="color" id="penColor" title="Pen Color" v-model="penColor">
            </div>
            <div id="thicknessSlider">
              <label for="penThick">Pen Thickness</label>
              <input type="range" title="Pen Thickness" id="penThick" class="sliderThick pointer" v-model="penThickness" min="1" max="10">
            </div>
          </div>
        <canvas ref="canvas" @mousedown="startDrawing" @mousemove="draw" @mouseup="stopDrawing"
          @touchstart="startDrawing" @touchmove="draw" @touchend="stopDrawing"></canvas>
        <div class="btn_set">
          <button class="btn_secondary secondary" @click="clearCanvas">Clear</button>
          <button class="btn_primary btn" @click="downloadSignature">Download Signature</button>
        </div>
      </div>
      <div class="signature" v-show="currentState=='type'">
        <div class="type_signature_container">
          <input v-model="name" type="text" placeholder="Type your Signature" @input="generateSignatures"/>
          <input type="color" id="typeSig" v-model="signatureColor" @input="generateSignatures"/>
          <label for="typeSig" class="type_signature">
            <div :style="{'backgroundColor': signatureColor}"></div>
          </label>
          <button class="reset pointer" @click="resetSignature"><i class="fa-solid fa-rotate-right"></i></button>
        </div>
        <div v-if="signatures.length && name" class="gen_sigs">
          <div v-for="(signature, index) in signatures" :key="index" class="each_signature">
            <img :src="signature" alt="Signature" draggable="false"/>
            <a :href="signature" download="signature.png" class="download_sig">Download Signature</a>
          </div>
        </div>
        <div v-else class="no_sig">
          <p>Your Signatures will appear here</p>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"

export default {
  name: "SignatureMaker",
  components: { Header, Footer },
  computed: {
    user() {
      const store = useStore();
      return store.state.user;
    },
  },
  setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      title: 'Generate your online Signature for Free: UI Assembly',
      meta: [
        {
          name: 'description',
          content: 'Craft your unique electronic signature effortlessly on any device. Utilize your mouse, trackpad, or touchscreen for seamless online signing experiences. Trust us for all your electronic signature needs.',
        },
      ],
    });
    return {
      user,
    };
  },
  data() {
    return {
      canvas: null,
      context: null,
      penColor: '#234cff',
      penThickness: 1,
      isDrawing: false,
      canvasWidth: 0,
      canvasHeight: 200,
      fromWebsitePerformance: true,
      currentState: 'draw',



      name: "",
      signatureColor: "#2e2e2e",
      fonts: [
        "Bilbo Swash Caps",
        "Caveat",
        "Dancing Script",
        "Great Vibes",
        "La Belle Aurore",
        "Qwigley",
        "Bad Script",
        "Covered By Your Grace",
        "Handlee",
        "Just Another Hand",
        "Kalam",
        "Marck Script",
        "Nothing You Could Do",
        "Sacramento",
        "Stalemate",
        "Yellowtail",
        "Courgette",
        "Fasthand"
      ],
      signatures: []
    };
  },
  mounted() {
    this.initCanvas();
    this.setCanvasSize();
    this.context.strokeStyle = '#234cff'
    window.addEventListener('resize', this.setCanvasSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setCanvasSize);
  },
  methods: {
    initCanvas() {
      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext('2d');
      this.context.strokeStyle = this.penColor;
      this.context.lineWidth = this.penThickness;
    },
    setCanvasSize() {
      const container = this.$el.querySelector('.signature');
      this.canvasWidth = container.offsetWidth;
      this.canvasHeight = container.offsetHeight;
      this.canvas.width = this.canvasWidth;
      this.canvas.height = this.canvasHeight;
    },
    startDrawing(event) {
      this.isDrawing = true;
      this.context.beginPath();
      this.context.moveTo(this.getEventX(event), this.getEventY(event));
    },
    draw(event) {
      if (this.isDrawing) {
        this.context.lineTo(this.getEventX(event), this.getEventY(event));
        this.context.stroke();
      }
    },
    stopDrawing() {
      this.isDrawing = false;
    },
    clearCanvas() {
      this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    },
    downloadSignature() {
      // Create a new canvas with a white background
      const whiteCanvas = document.createElement('canvas');
      const whiteContext = whiteCanvas.getContext('2d');
      whiteCanvas.width = this.canvasWidth;
      whiteCanvas.height = this.canvasHeight;
      whiteContext.fillStyle = '#ffffff'; // White color
      whiteContext.fillRect(0, 0, whiteCanvas.width, whiteCanvas.height);

      // Draw the signature onto the white canvas
      whiteContext.drawImage(this.canvas, 0, 0);

      // Convert the white canvas to a data URL and download it
      const image = whiteCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'signature.png';
      link.click();
    },
    getEventX(event) {
      if (event.type === 'touchstart' || event.type === 'touchmove' || event.type === 'touchend') {
        return event.touches[0].clientX - this.canvas.getBoundingClientRect().left;
      }
      return event.clientX - this.canvas.getBoundingClientRect().left;
    },
    getEventY(event) {
      if (event.type === 'touchstart' || event.type === 'touchmove' || event.type === 'touchend') {
        return event.touches[0].clientY - this.canvas.getBoundingClientRect().top;
      }
      return event.clientY - this.canvas.getBoundingClientRect().top;
    },




    generateSignatures() {
      // if(this.name != ''){
        this.signatures = this.fonts.map(font => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = 400;
          canvas.height = 100;
          ctx.fillStyle = "white";
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.font = `5rem '${font}', cursive`;
          
          // Set text color based on the selected signatureColor
          ctx.fillStyle = this.signatureColor;
          
          // Measure text width and height
          const text = this.name;
          const textMetrics = ctx.measureText(text);
          const textWidth = textMetrics.width;
          const textHeight = 30; // Assuming 40 is the desired text height
          
          // Calculate the position to center the text
          const x = (canvas.width - textWidth) / 2;
          const y = (canvas.height + textHeight) / 2;
          
          // Draw the text at the calculated position
          ctx.fillText(text, x, y);
          
          const dataURL = canvas.toDataURL("image/png");
          return dataURL;
        });
      }
    // }
    ,
    resetSignature(){
      this.name = ''
    }
  },
  watch: {
    penColor() {
      this.context.strokeStyle = this.penColor;
    },
    penThickness() {
      this.context.lineWidth = this.penThickness;
    },
  },
};
</script>

<style scoped>
  .hero-section {
    cursor: default;
    background-position: top right;
    background-size: cover;
    position: relative;
  }
  .signature{
    background-color: #fff;
    width: 100%;
    margin: 3rem auto 0;
    max-width: 900px;
    border-radius: 1rem;
    /* overflow: hidden; */
  }
  .container{
    width: 90%;
  }
  canvas {
    cursor: default;
    border-radius: 1rem;
    border: 1px solid #969699;
    /*width: 100%; */
    /* max-height: 250px; */
  }
  input[type="text"]{
    max-width: 60rem;
    width: 60vw;
    height: 5.4rem;
    padding: 0.6rem 1.6rem;
    font-size: 2rem;
    color: #303030;
    border: 2px solid #16a2ff;
    border-radius: 5px;
    display: block;
  }
  input[type=text]::-webkit-inner-spin-button, 
  input[type=text]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }
  input[type="text"]:focus{
    outline: none;
    box-shadow: 3px 3px 15px rgba(61, 177, 255, 0.5);
    transition: .1s;
    transition-property: box-shadow;
  }
  #typeSig{
    display: none;
  }
  .type_signature{
    /* width: ; */
    display: inline-block;
    width: 5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .type_signature_container{
    display: flex;
    align-items: center;
  }
  .type_signature div{
    width: 5.2rem;
    height: 5.2rem;
    background-color: #2e2e2e;
    border-radius: 0.4rem;
  }
  .no_sig{
    height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    border-radius: 0.6rem;
    border: 1px solid #d1d1d1;
    margin-bottom: 2rem;
  }
  .no_sig p{
    font-size: 2rem;
    color: #464646;
  }
  .reset{
    padding: 0.8rem 1rem;
    font-size: 3rem;
    color: #007bff;
    background-color: transparent;
    border: 2px solid #007bff;
    border-radius: 0.6rem;
  }
  #colorPicker,
  #thicknessSlider {
    display: flex;
    align-items: center;
  }
  #colorPicker label,
  #thicknessSlider label{
    font-size: 2rem;
    width: unset;
    margin-right: 1rem;
  }
  #penColor{
    display: none;
  }
  .radio_input input {
  display: none;
}

.radio_input {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  background-color: #fff;
  color: #000000;
  width: 20rem;
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio_input label {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: 600;
  color: #4b4b4b;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 1.4rem;
}
.heading{
  font-size: 4rem;
  color: #444444;
  text-align: center;
}
.top_header{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  gap: 1.6rem;
  width: 100%;
  margin: 3rem auto 0;
  max-width: 900px;
}
.top_container{
  margin-top: 2.4rem;
}
.slider {
  position: absolute;
  height: 100%;
  width: 10rem;
  z-index: 0;
  left: 0;
  top: 0;
  transition: .15s ease;
}

.radio_input label:has(input:checked) {
  color: #fff;
}

.radio_input label:has(input:checked) ~ .slider {
  background-color: #525252;
  display: inline-block;
}

.radio_input label:nth-child(1):has(input:checked) ~ .slider {
  transform: translateX(0);
}

.radio_input label:nth-child(2):has(input:checked) ~ .slider {
  transform: translateX(10rem);
}
  .color_pen div{
    width: 4rem;
    height: 4rem;
    background-color: #234cff;
    border-radius: 0.4rem;
  }
  .sliderThick{
    -webkit-appearance:none;
    width:180px;
    height:10px;
    background: linear-gradient(to right, #dddddd 0%, #dddddd 100%);
    background-size: 200px 5px;
    background-position:center;
    background-repeat:no-repeat;
    /* overflow:hidden; */
    outline: none;
  }

  .sliderThick::-webkit-slider-thumb{
    -webkit-appearance:none;
    width:24px;
    height:24px;
    border-radius: 50%;
    background:#868686;
    position:relative;
    z-index:3;
  }

  .sliderThick::-webkit-slider-thumb:after{
    content:" ";
    width:160px;
    height:10px;
    position:absolute;
    z-index:1;
    right:20px;
    top:5px;
    background: #ff5b32;
    background: linear-gradient(to right, #f088fc 1%, #AC6CFF 70%);
  }
  .gen_sigs{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .each_signature{
    border: 1px solid #dadada;
    border-radius: 0.6rem;
    box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 0.6rem 1rem;
  }
  .each_signature img{
    width: 100%;
    height: auto;
  }
  .download_sig{
    margin: 5rem auto 2rem;
    background-color: #007bff;
    color: #fff;
    padding: 1rem;
    width: 80%;
    display: block;
    font-size: 2rem;
    text-decoration: none;
    text-align: center;
    border-radius: 8rem;
  }
  .download_sig:hover{
    background-color: #1988ff;
  }
  .input_fields{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  .btn_set{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0;
  }
  @media screen and (max-width: 550px) {
      .btn_set{
        /* flex-direction: column; */
      }
      .btn_set button{
        /* width: 90%; */
      }
  }
  .btn{
    background-color: #007bff;
    margin-top: 0;
    color: #fff;
    padding-inline: 2rem;
  }
  .secondary{
    padding-block: 1.2rem;
    border-color: #007bff;
    color: #007bff;
    max-width: 120px;
    width: 50%;
  }
  @media screen and (max-width: 336px) {
    .btn_set{
        flex-direction: column;
      }
      .btn_set button{
        max-width: unset;
        width: 100%;
      }
  }
  @media screen and (max-width: 910px) {
    .each_signature{
      width: 90vw;
    }
    .gen_sigs{
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 600px) {
    .download_sig{
      font-size: 2.8rem;
    }
  }
</style>