<template>
    <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
      <div class="container">
      <h1 class="heading">URL Shortener</h1>
  
      <form @submit.prevent="shortenUrl" class="form">
        <input v-model="url" type="text" placeholder="Enter URL to shorten">
        <button class="btn_short pointer" type="submit">Shorten URL</button>
      </form>
     
      <!-- <div id="result" class="result"> -->
      <div v-if="shortenedUrl" id="result" class="result">
        <input id="shortened-url" type="text" class="output_url"  v-model="shortenedUrl" readonly>
        <img class="qrCode pointer" src="../assets/qr.png" alt="" @click="generateQR">
        <button id="copy-button" class="copy pointer" @click="copyToClipboard($event, shortenedUrl)">Copy</button>
      </div>
      <div class="guide">
        <p>Your Ultimate Free URL Shortener. Convert lengthy, cumbersome links into elegant, memorable, and traceable short URLs. Perfect for social media, blogs, emails, ads, and more. Seamlessly share on platforms like Twitter, Facebook, YouTube, Instagram, WhatsApp, SMS, and email.</p>
      </div>
      <!-- <QRCode :value="textInput" v-if="qrCodeValue" />/ -->
      <transition name="overlayPrototype">
        <div class="overLay" v-if="closePrototype" @click.self="closeModalPrototype">
            <div class="popUp">
                <i class="fa-solid fa-xmark close" @click="closeModalPrototype"></i>
                <QRCode :value="shortenedUrl" />
            </div>
        </div>
      </transition>
    </div>
    </section>
    <Footer />
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useHead } from '@vueuse/head'
  import QRCode from 'vue-qrcode';
  import Header from "../components/Header.vue"
  import Footer from "../components/Footer.vue"
  export default {
    name: "UrlShorten",
    components:{Header, QRCode, Footer},
    computed: {
      user() {
        const store = useStore();
        return store.state.user;
      },
    },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Free URL Shortener: UI Assembly',
      meta: [
        {
          name: `Free URL Shortener: UI Assembly`,
          content: 'Shorten a long link and create a short URL easy to share on sites, chat and emails. Generate it\'s qr code directly in no time.',
        },
        ],
      })
      return {
        user,
      };
    },
    data() {
      return {
        qrCodeValue: null,
        fromWebsitePerformance: true,
        closePrototype: false,
        url: "",
        shortenedUrl: "",
        accessToken: "4b1c871c4b77836904e795c6f5d46cdd84d0157b",
      };
    },
    methods: {
      shortenUrl() {
        const apiUrl = "https://api-ssl.bitly.com/v4/shorten";
        
        fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          body: JSON.stringify({ long_url: this.url }),
        })
          .then((response) => response.json())
          .then((data) => {
            this.shortenedUrl = data.link;
          })
          .catch((error) => {
            console.error("Error during URL shortening:", error);
            alert("Error occurred while shortening the URL");
          });
      },
    copyToClipboard(e, value) {
        navigator.clipboard.writeText(value)
        .then(() => {
          e.target.innerText = 'Copied'
          setTimeout(() => {
            e.target.innerText = 'Copy'
          }, 2000);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    },
    generateQR() {
      this.closePrototype = true;
      const qrCodeAPI = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      this.shortenedUrl
      )}`;
      this.qrCodeValue = qrCodeAPI;
    },
    closeModalPrototype(){
      this.closePrototype = false
    }
    },
  };
  </script>
  
  <style scoped>
  .hero-section {
  cursor: default;
  background: rgba(255, 255, 255, 0.85);
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.85) 40%, rgba(148, 255, 250, 0.85) 100%);
  min-height: 85vh;
  position: relative;
  padding-bottom: 10rem;
}
  .container{
    max-width: 720px;
    width: 90%;
    margin-top: 5rem;
  }
  .heading {
    font-size: 3.2rem;
    text-align: center;
  }
  input[type="text"]{
  max-width: 50rem;
  width: 95vw;
  height: 6.8rem;
  padding: 0.6rem 1.6rem;
  font-size: 2rem;
  color: #303030;
  border: 2px solid #007bff;
  border-radius: 5px 0 0 5px;
  display: block;
}
input[type=text]::-webkit-inner-spin-button, 
input[type=text]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type="text"]:focus{
  outline: none;
  box-shadow: 3px 3px 15px rgba(61, 177, 255, 0.5);
  transition: .1s;
  transition-property: box-shadow;
}
  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
  .btn_short{
    background-color: #007bff;
    color: #fff;
    font-size: 1.6rem;
    letter-spacing: 2px;
    height: 6.8rem;
    width: 20rem;
    padding-inline: 2rem;
    border: none;
    border-radius: 0 0.4rem 0.4rem 0;
  }
  .copy{
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    color: #007bff;
    font-size: 2rem;
    letter-spacing: 2px;
    padding: 0.6rem 1.2rem;
    background-color: #e2f0ff;
    border-radius: 0.4rem;
    background-color: transparent;
    border: none;
  }
  .qrCode{
    width: 3.2rem;
    aspect-ratio: 1/1;
    position: absolute;
    top: 1.5rem;
    right: 13rem;
  }
  .output_url{
    border-radius: 5px !important;
    /* max-width: 65rem !important; */
    width: 100% !important;
    max-width: unset !important;
    border: 1.5px solid #8f8f8f !important;
  }
  .result{
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-width: 65rem !important; */
    width: 100%;
    margin: 5rem auto 0 auto;
    position: relative;
  }
  .overLay{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popUp{
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 520px;
    position: relative;
    width: 90%;
    text-align: center;
  }
  .popUp img{
    width: 32rem;
    aspect-ratio: 1/1;
  }
  .close{
    position: absolute;
    right: -5rem;
    top: -5rem;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
  }
  .guide{
    margin-top: 3.2rem;
    padding: 2rem;
    border-radius: 0.8rem;
    background-color: transparent;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .guide p{
    font-size: 2rem;
    line-height: 150%;
  }
  </style>
  