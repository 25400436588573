<template>
  <div class="spinner" :class="{'fromComponent': size}"></div>
</template>

<script>
export default {
    name: "Loader",
    props:["size"]
}
</script>

<style scoped>
.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid rgba(0, 195, 255, 0.1);
  border-top-color: #3498db;
  animation: spin 0.5s ease-in-out infinite;
}
.fromComponent{
  width: 30px !important;
  height: 30px !important; 
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>