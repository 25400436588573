<template>
    <div class="playground">
      <div class="ball" :class="ballClass"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CssAnimation",
    data() {
      return {
        animationDuration: 2, // Default duration (in seconds)
        animationIteration: 'infinite', // Default iteration count
        animationDirection: 'normal', // Default direction
        animationTimingFunction: 'ease', // Default timing function
      };
    },
    computed: {
      ballClass() {
        return {
           'animate-ball': true,
          'infinite': this.animationIteration === 'infinite',
          'normal': this.animationDirection === 'normal',
          'ease': this.animationTimingFunction === 'ease',
        };
      },
    },
    watch: {
      animationDuration: 'applyAnimation',
      animationIteration: 'applyAnimation',
      animationDirection: 'applyAnimation',
      animationTimingFunction: 'applyAnimation',
    },
    methods: {
      applyAnimation() {
        // Force reflow to restart the animation
        this.$nextTick(() => {
          this.$refs.ball.classList.remove('animate-ball');
          this.$nextTick(() => {
            this.$refs.ball.classList.add('animate-ball');
          });
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .playground {
    width: 300px;
    height: 300px;
    position: relative;
    background-color: #f0f0f0;
  }
  
  .ball {
    width: 50px;
    height: 50px;
    background-color: #3498db;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Define animation */
  @keyframes animate-ball {
    0% {
      transform: translate(-50%, -50%) scale(1);
      background-color: #3498db;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
      background-color: #e74c3c;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      background-color: #3498db;
    }
  }
  </style>  