<!-- src/components/QrCodeGenerator.vue -->
<template>
  <section class="hero-section">
    <Header :user="user"/>
    <div class="container">
    <textarea v-model="textInput" placeholder="Enter your text here"></textarea>
    <button @click="generateQRCode" class="btn_primary btn">Generate QR Code</button><br>
    <div class="qr_img">
      <QRCode :value="textInput" v-if="qrCodeValue" />
      <button class="secondary" @click="downloadQRCode" v-if="qrCodeValue"><img src="../assets/download_icon.png" alt=""></button>
    </div>
  </div>
  </section>
  <Footer />
</template>

<script>
import { onMounted } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import QRCode from 'vue-qrcode';
import FileSaver from 'file-saver';
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"

export default {
  name:"QrCodeGenerator",
  components: {
    QRCode,
    Header,
    Footer
  },
  computed: {
    user() {
      const store = useStore();
      return store.state.user;
    },
  },
  setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'QR Code Generator: UI Assembly',
      meta: [
        {
          name: `QR Code Generator: UI Assembly`,
          content: 'QR Code Generator for URL and Text files. Download the QR code in high print quality. Get your free QR Codes now!',
        },
      ],
    })
    return {
      user,
    };
  },
  data() {
    return {
      textInput: '',
      qrCodeValue: null,
    };
  },
  methods: {
    generateQRCode() {
      // Use any QR code generation API or library you prefer here
      // For this example, we'll use an online API called "goqr.me"
      const qrCodeAPI = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
        this.textInput
      )}`;
      this.qrCodeValue = qrCodeAPI;
    },
    downloadQRCode() {
      FileSaver.saveAs(this.qrCodeValue, 'qrcode.png');
    },
  },
};
</script>

<style scoped>
.hero-section {
  cursor: default;
  background-image: url("../assets/qrScanner.png");
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  /* background-size: 100% 100%; */
  background-position: top right;
  background-size: cover;
  height: 98vh;
  position: relative;
  /* overflow: hidden; */
  /* font-size: 5rem; */
}
textarea {
  max-width: 50rem;
  width: 90%;
  height: 15rem;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 2rem;
  display: block;
}
textarea:focus{
  border: none;
  outline: none;
  box-shadow: 0 0 0 0.2rem #007bff;
}
.btn{
  background-color: #007bff;
  color: #fff;
  padding-inline: 2rem;
}
.container{
  width: 90vw;
  margin: 0 auto;
}
.qr_img{
  max-width: 30rem;
  width: 30%;
  /* height: 30rem; */
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  align-items: end;
  margin-top: 2.4rem;
  gap: 1rem;
}
.qr_img img{
  width: 100%;
  height: auto;
}
.secondary{
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
}
.secondary img{
  width: 5.5rem !important;
  height: 5rem !important;
  border-radius: 0.5rem;
}
@media screen and (max-width: 550px) {
  .qr_img{
  max-width: 60rem;
  width: 60%;
  }
}
</style>
