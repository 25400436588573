<template>
    <div class="stopWatch">
        <div id="timer">00:00:000</div>
        <div class="btn_set">
          <button id="start" class="start_btn" @click="startTimer" :disabled="startDisabled" :class="{btn_disabled: startDisabled}">Start</button>
          <button id="pause" class="rest_btn" @click="pauseTimer" :disabled="pauseDisabled" :class="{btn_disabled: pauseDisabled}">Pause</button>
          <button id="resume" class="rest_btn" @click="resumeTimer" :disabled="resumeDisabled" :class="{btn_disabled: resumeDisabled}">Resume</button>
          <button id="reset" class="rest_btn" @click="resetTimer" :disabled="resetDisabled" :class="{btn_disabled: resetDisabled}">Reset</button>
        </div>
    </div>
  </template>
  
  <script>
  export default {
      name:"CountDownTimer",
      props: [],
      components: {},
      data() {
          return {
            timerInterval:'',
            startTime: '',
            elapsedTime: 0,
            isPaused: false,
            startDisabled: false,
            pauseDisabled: false,
            resumeDisabled: false,
            resetDisabled: false
          }
      },
      mounted(){
        this.pauseDisabled= true,
        this.resumeDisabled= true,
        this.resetDisabled= true
      },
      methods: {
        startTimer(){
          this.startDisabled= true,
          this.resumeDisabled= true,
          this.pauseDisabled = false;
          this.resetDisabled = true;
          if (this.isPaused) {
              this.startTime = Date.now() - this.elapsedTime;
            } else {
              this.startTime = Date.now();
            }
  
              this.timerInterval = setInterval(this.updateTimer, 10);
        },
  
    pauseTimer(){
      var timerElement = document.getElementById("timer");
      this.startDisabled = true;
      this.pauseDisabled = true;
      this.resumeDisabled = false;
      this.resetDisabled = false;
      clearInterval(this.timerInterval);
        this.elapsedTime = Date.now() - this.startTime;
        this.isPaused = true;
    },
  
    resumeTimer(){
      var timerElement = document.getElementById("timer");
      this.startDisabled = true;
      this.pauseDisabled = false;
      this.resumeDisabled = true;
      this.resetDisabled = true;
      this.startTime = Date.now() - this.elapsedTime;
      this.timerInterval = setInterval(this.updateTimer, 10);
      this.isPaused = false;
    },
    resetTimer(){
      var timerElement = document.getElementById("timer");
      this.startDisabled = false;
      this.pauseDisabled = true;
      this.resumeDisabled = true;
      this.resetDisabled = true;
      clearInterval(this.timerInterval);
      timerElement.textContent = "00:00:000";
      this.elapsedTime = 0;
      this.isPaused = false;
    },
    updateTimer(){
      var timerElement = document.getElementById("timer");
      var elapsed = Date.now() - this.startTime;
      var minutes = Math.floor(elapsed / (1000 * 60));
      var seconds = Math.floor((elapsed % (1000 * 60)) / 1000);
      var milliseconds = Math.floor((elapsed % 1000));
      timerElement.textContent = this.formatTime(minutes) + ":" + this.formatTime(seconds) + ":" + this.formatTime(milliseconds);
    },
    formatTime(time){
      return time < 10 ? "0" + time : time;
    }
  }
  }
  </script>
  
  <style scoped>
  .stopWatch{
    /* font-size: 3rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
}
button{
      font-size: 3rem;
  }
#timer{
  letter-spacing:0.1em;
  text-align: center;
  font-size: 12rem;
  font-weight: 800;
-webkit-text-fill-color: transparent;
-webkit-text-stroke-width: 5px;
-webkit-text-stroke-color: rgba(0, 0, 0, 0);
text-shadow: 6px 6px #16d0ff, 12px 12px #000000, 16px 16px 12px #ffffff;
}
.btn_set{
  display: flex;
  gap: 2rem;
  margin-top: 5rem;
}
.btn_set button{
  padding: 1.6rem 6rem;
 }
 button {
  position: relative;
  border: none;
  font-size: 2rem;
  color: #fff; 
  text-align: center;
  /* background: linear-gradient(90deg,#03a9f4,#ffeb3b,#03a9f4); */
  background-size: 300%;
  border-radius: 1rem;
  z-index: 1;
  cursor: pointer;
}
.start_btn{
 background-color: #ff781e;
 box-shadow: 0.5rem 0.5rem 2rem 0 rgba(20, 208, 255, 0.5);
}
.rest_btn{
  background-color: #0e3064;
}
button:hover {
  animation: ani 8s linear infinite;
  border: none;
}

@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

/* button:before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg,#03a9f4,#f441a5,#ffeb3b,#03a9f4);
  background-size: 400%;
  border-radius: 1rem;
  transition: 1s;
}

button:hover::before {
  filter: blur(20px);
} */

button:active {
  background: linear-gradient(32deg,#03a9f4,#ffeb3b,#03a9f4);
}
.btn_disabled{
  cursor: not-allowed;
  opacity: 0.6;
}
@media screen and (max-width: 51rem) {
  .btn_set{
    flex-direction: column;
  }
  .btn_set button{
    padding: 4rem 30vw;
    font-size: 4rem;
  }
}
  </style>