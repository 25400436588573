<template>
    <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
      <div class="container">
            <div class="navigation_links">
              <input type="radio" id="pngToJpg" name="imageConvert" v-model="imageConvert" value="pngToJpg">
              <label for="pngToJpg" class="nav_links">PNG to JPEG</label>

              <input type="radio" id="pngToPdf" name="imageConvert" v-model="imageConvert" value="pngToPdf">
              <label for="pngToPdf" class="nav_links">PNG to PDF</label>

              <input type="radio" id="jpgToPng" name="imageConvert" v-model="imageConvert" value="jpgToPng">
              <label for="jpgToPng" class="nav_links">JPEG to PNG</label>

              <input type="radio" id="webpToPng" name="imageConvert" v-model="imageConvert" value="webpToPng">
              <label for="webpToPng" class="nav_links">WEBP to PNG</label>

              <input type="radio" id="pngToWebp" name="imageConvert" v-model="imageConvert" value="jpegToPdf">
              <label for="pngToWebp" class="nav_links">JPEG to PDF</label>
              
              <!-- <input type="radio" id="pngToBmp" name="imageConvert" v-model="imageConvert" value="bmpToPng">
              <label for="pngToBmp" class="nav_links">BMP to PNG</label> -->

              <!-- <input type="radio" id="pdfToPng" name="imageConvert" v-model="imageConvert" value="pdfToPng">
              <label for="pdfToPng" class="nav_links">PDF to PNG</label> -->
            </div>
            <div class="mobile_label">
              <h2>Image Conversion Type:</h2>
              <div class="select_fields">
                <select name="conversion_type" id="conversion" v-model="imageConvert">
                  <option value="pngToJpg">PNG to JPEG</option>
                  <option value="pngToPdf">PNG to PDF</option>
                  <option value="jpgToPng">JPEG to PNG</option>
                  <option value="webpToPng">WEBP to PNG</option>
                  <option value="jpegToPdf">JPEG to PDF</option>
                </select>
              </div>
            </div>

            <div class="images_container">
                <PngtoJpg v-if="imageConvert == 'pngToJpg'"/>
                <PngToPdf v-if="imageConvert == 'pngToPdf'"/>
                <!-- <PngToWebp v-if="imageConvert == 'pngToWebp'"/> -->
                <JpgToPng v-if="imageConvert == 'jpgToPng'"/>
                <!-- <PdfToPng v-if="imageConvert == 'pdfToPng'"/> -->
                <WebpToPng v-if="imageConvert == 'webpToPng'"/>
                <!-- <BmpToPng v-if="imageConvert == 'bmpToPng'"/> -->
                <JpegToPdf v-if="imageConvert == 'jpegToPdf'"/>

                <!-- <WebpToJpg />
                <WebpToPdf/> -->
            </div>
        </div>
    </section>
    <Footer />
  </template>
  
  <script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import PngtoJpg from "./Components/PngToJpg.vue"
import PngToPdf from "./Components/PngToPdf.vue"
// import PngToWebp from "./Components/PngToWebp.vue"
import JpgToPng from "./Components/JpgToPng.vue"
import WebpToPng from "./Components/WebpToPng.vue"
import JpegToPdf from "./Components/JpegToPdf.vue"
// import BmpToPng from "./Components/BmpToPng.vue"
// import PdfToPng from "./Components/PdfToPng.vue"
  export default {
    name: "ImageConverter",
    components:{Header,Footer, PngtoJpg, PngToPdf, JpgToPng, WebpToPng, JpegToPdf},
    computed: {
      user() {
        const store = useStore();
        return store.state.user;
      },
    },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Online Image Converter: UI Assembly',
      meta: [
        {
          name: `Online Image Converter: UI Assembly`,
          content: 'Convert your image files with this free online image converter.',
        },
        ],

      })
      return {
        user,
      };
    },
    data() {
      return {
        imageConvert: 'pngToJpg',
        fromWebsitePerformance: true,
      };
    },
  };
  </script>
  
  <style scoped>
   .hero-section {
    cursor: default;
    background: #04151E;
    min-height: 90vh;
}
.container{
        display: flex;
        align-items: flex-start;
        /* margin-top: 5rem; */
        padding: 5rem 0 0 0;
        width: 90%;
        /* border: 1px solid #e9e9e9; */
        /* background-color: #e6e6e6; */
    }
    .mobile_label{
      display: none;
    }
    .mobile_label h2{
      font-size: 2.4rem;
      color: #fff;
    }
    .navigation_links{
      height: 40rem;
        background-color: rgba(255, 255, 255, 0.03);
        padding: 1rem;
    }
    input[type= "radio"]{
        display: none;
    }
    .navigation_links label{
        display: block;
        font-size: 2rem;
        text-align: center;
        padding: 1rem 0 0 0;
        width: 20rem;
        height: 5rem;
        margin-bottom: 0.4rem;
        cursor: pointer;
        color: #fff;
        transition: all 0.15s ease-in-out;
    }
    .navigation_links label:hover{
        background-color: #04151E;
        color: #fff;
    }
    .images_container{
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        margin-left: 1rem;
        height: 40rem;
        max-width: 1280px;
        width: 75%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 2rem;
    }
    input[type='radio']:checked+.nav_links{
        color: #fff;
        background-color: #268ACD;
    }
  @media screen and (max-width: 500px) {
    .container{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .navigation_links{
      height: unset;
      display: flex;
      row-gap: 1.2rem;
      display: none;
    }
    .mobile_label{
      display: flex;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 2.4rem;
    }
    .nav_links{
      margin-bottom: 0 !important;
    }
    select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: #f1f1f1;
      border: 2px solid #ccc;
      border-radius: 4px;
      padding: 1rem;
      width: 148px;
      font-size: 2rem;
      cursor: pointer;
    }

    select:focus {
      border-color: #999;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    select option {
      padding: 1.6rem 0;
      background-color: #f1f1f1;
    }

    select option:hover {
      background-color: #e0e0e0;
    }
    .images_container{
      width: 100%;
    }
  }
  </style>
  