<template>
    <div>
      <textarea v-model="inputJSON" rows="10" cols="50"></textarea>
      <button @click="formatJSON">Format JSON</button>
      <button @click="beautifyJSON">Beautify JSON</button>
      <vue-json-pretty :data="formattedJSON" v-if="formattedJSON"></vue-json-pretty>
    </div>
  </template>
  
  <script>
  import VueJsonPretty from 'vue-json-pretty';
  
  export default {
    components: {
      VueJsonPretty,
    },
    data() {
      return {
        inputJSON: '',
        formattedJSON: null,
      };
    },
    methods: {
      formatJSON() {
        try {
          this.formattedJSON = JSON.parse(this.inputJSON);
        } catch (error) {
          this.formattedJSON = { error: 'Invalid JSON' };
        }
      },
      beautifyJSON() {
        // No need to modify the formattedJSON here, as vue-json-pretty will handle the beautification and color coding.
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any custom styling here */
  </style>
  