// store.js
import { createStore } from 'vuex';
import { getAuth, signOut } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";

export default createStore({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    clearUser({ commit }) {
        commit('clearUser');
    },
    async loginUser({ commit }, { email, password }) {
      try {
        const auth = getAuth();
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
    
        if (user.emailVerified) {
          commit('setUser', user); // Set the user object directly in the store
          return user; // Return the user object
        } else {
          alert("Email not verified. Please check your email for a verification link.");
          throw new Error("Email not verified. Please check your email for a verification link.");
        }
      } catch (error) {
        throw error;
      }
    },
    
    async logoutUser({ commit }) {
      try {
        // Replace the following lines with your Firebase logout logic
        const auth = getAuth();
        await signOut(auth);

        commit('clearUser');
      } catch (error) {
        throw error;
      }
    },
  },
});