<template>
    <div>
      <label for="primaryColor">Primary Color:</label>
      <input type="color" id="primaryColor" v-model="primaryColor" />
  
      <button @click="generateAnalogousColors">Generate Analogous Colors</button>
      
      <h2>Analogous Colors:</h2>
      <div v-for="(color, index) in computedAnalogousColors" :key="index">
        <div :style="{ backgroundColor: color }" class="color-box"></div>
        {{ color }}
    </div>

    <h2>Monochromatic Colors:</h2>
    <div v-for="(color, index) in computedMonochromaticColors" :key="index">
      <div :style="{ backgroundColor: color }" class="color-box"></div>
      {{ color }}
    </div>
    </div>
</template>
  
<script>
import { useHead } from '@vueuse/head'
  export default {
    name: "ColorPalette",
    setup() {

    useHead({
      // Can be static or computed
      title: 'CSS Gradient Generator',
      meta: [
        {
          name: `CSS Gradient Generator`,
          content: 'Unlock the magic of CSS gradients! Explore essential UX designer and developement tools for crafting unique, captivating designs. Use the gradient anywhere.',
        },
        ],

      })
    },
    data() {
      return {
        primaryColor: "#FF5733",
      };
    },
    computed: {
      computedAnalogousColors() {
        const rgb = this.hexToRgb(this.primaryColor);
        if (!rgb) return [];
  
        const analogousColors = [];
  
        for (let i = -2; i <= 2; i++) {
          const hue = (rgb.h + i * 30 + 360) % 360;
          const analogousHex = this.hslToHex({ h: hue, s: rgb.s, l: rgb.l });
          analogousColors.push(analogousHex);
        }
  
        return analogousColors;
      },
      computedMonochromaticColors() {
        const rgb = this.hexToRgb(this.primaryColor);
        if (!rgb) return [];

        const monochromaticColors = [];

        // Generate 5 monochromatic colors with different lightness levels
        for (let i = 0; i <= 4; i++) {
            let l = rgb.l - 5 * i; // Adjust lightness levels

            // Ensure that the last two colors are relatively darker
            if (i >= 3) {
            l -= 10 * (i - 2);
            }

            const monochromaticHex = this.hslToHex({ h: rgb.h, s: rgb.s, l });
            monochromaticColors.push(monochromaticHex);
        }

        return monochromaticColors;
        },
    },
    methods: {
      hexToRgb(hex) {
        // Function to convert hex color to RGB object
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
        const bigint = parseInt(hex.substring(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return this.rgbToHsl(r, g, b);
      },
      rgbToHsl(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;
  
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        const diff = max - min;
  
        let h = 0;
        let s = 0;
        let l = (max + min) / 2;
  
        if (diff !== 0) {
          s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min);
  
          switch (max) {
            case r:
              h = (g - b) / diff + (g < b ? 6 : 0);
              break;
            case g:
              h = (b - r) / diff + 2;
              break;
            case b:
              h = (r - g) / diff + 4;
              break;
          }
  
          h /= 6;
        }
  
        return { h: h * 360, s: s * 100, l: l * 100 };
      },
      hslToHex(hsl) {
        const { h, s, l } = hsl;
        const hue = h / 360;
        const sat = s / 100;
        const lum = l / 100;
  
        const hueToRgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };
        
  
        const q = lum < 0.5 ? lum * (1 + sat) : lum + sat - lum * sat;
        const p = 2 * lum - q;
  
        const r = Math.round(hueToRgb(p, q, hue + 1 / 3) * 255);
        const g = Math.round(hueToRgb(p, q, hue) * 255);
        const b = Math.round(hueToRgb(p, q, hue - 1 / 3) * 255);
  
        return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
      },

      generateAnalogousColors() {
        // This function is not needed anymore since we compute colors directly in computedAnalogousColors
      },
    },
  };
  </script>
  
  <style scoped>
  .color-box {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;
  }
  </style>  