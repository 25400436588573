<template>
  <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
  <section class="hero-section">
    <div class="container">
      <div class="left_container">
        <h1>Website Performance Checker</h1>
        <form class="form_container">
          <label for="websiteUrl">Website URL:</label><br>
          <input id="websiteUrl" type="text" v-model="websiteUrl" placeholder="Enter the website URL">
          <button class="btn_primary btn" type="button" @click="checkPerformance">Check Performance</button>
        </form>
        <div v-if="loading">Loading...</div>
        <div v-else>
          <div v-if="error">{{ error }}</div>
          <div class="result_website" v-else-if="performanceData">
            <h2>Performance Data</h2>
            <div class="analytics_container">
              <div class="analytics_data">
                <i class="fa-solid fa-gauge"></i>
                <p class="analytics_name">Page Speed</p>
                <p class="analytics_value">{{ performanceData.speed }}</p>
              </div>
              <div class="analytics_data">
                <i class="fa-regular fa-clock"></i>
                <p class="analytics_name">Loading Time</p>
                <p class="analytics_value">{{ performanceData.loadingTime }}</p>
              </div>
              <div class="analytics_data">
                <i class="fa-solid fa-code-pull-request"></i>
                <p class="analytics_name">Request Number</p>
                <p class="analytics_value">{{ performanceData.requestNumber }}</p>
              </div>
              <div class="analytics_data">
                <i class="fa-regular fa-folder-open"></i>
                <p class="analytics_name">Page Size</p>
                <p class="analytics_value"> {{ performanceData.pageSize }}</p>
              </div>
            </div>
            <!-- <img src="../assets/WebsiteImg.png"  alt="" width="500"> -->
          </div>
        </div>
      </div>
      <div class="analytics_img" v-if="performanceData">
        <img :src="performanceData.src.data" alt="" draggable="false">
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
export default {
  name:"WebsitePerformance",
  components:{Header, Footer},
  computed: {
    user() {
      const store = useStore();
      return store.state.user;
    },
  },
  setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
  useHead({
    // Can be static or computed
    title: 'Check Website Performance: UI Assembly',
    meta: [
      {
        name: `Check Website Performance: UI Assembly`,
        content: 'Conduct a Comprehensive Global Website Speed Test: Employ real browsers at consumer connection speeds for accurate insights. Receive in-depth optimization recommendations to enhance your website\'s performance. Unlock the full potential of your online presence with our free tool.',
      },
      ],
    })
    return {
      user,
    };
  },
  data() {
    return {
      fromWebsitePerformance: true,
      websiteUrl: "",
      loading: false,
      error: "",
      performanceData: null,
    };
  },
  methods: {
    checkPerformance() {
      // Clear previous result
      this.error = "";
      this.performanceData = null;

      // Validate website URL
      if (!this.websiteUrl) {
        this.error = "Please enter a valid website URL.";
        return;
      }

      this.loading = true;

      // Fetch performance data using PageSpeed Insights API
      var apiUrl =
        "https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=" +
        encodeURIComponent(this.websiteUrl) +
        "&key=AIzaSyCyWSj1kbdNyRBmX6dV5coFoxUFPE4m4bk";

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          // Extract performance data from the API response
          var lighthouseResult = data.lighthouseResult;
          this.performanceData = {
            speed: lighthouseResult.categories.performance.score * 100,
            loadingTime: lighthouseResult.audits['interactive'].displayValue,
            requestNumber: lighthouseResult.audits['network-requests'].details.items.length,
            pageSize:  String(lighthouseResult.audits['total-byte-weight'].displayValue).replace('Total size was ',''),
            src: lighthouseResult.fullPageScreenshot.screenshot
          };
          this.loading = false;
        })
        .catch(error => {
          this.error =
            "An error occurred while fetching the performance data. Please try again later.";
          console.error(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add any custom CSS styles here */
.hero-section {
  cursor: default;
  background: rgba(255, 255, 255, 0.85);
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.85) 40%, rgba(148, 255, 250, 0.85) 100%);
  min-height: 82vh;
  position: relative;
}
.container{
  /* background-color: aquamarine; */
  margin-top: 5rem;
  width: 90%;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
.left_container{
  max-width: 60rem;
}
h1{
  font-size: 3.2rem;
  color: #303030;
  margin-bottom: 2rem;
}
.form_container{
  margin-bottom: 2.4rem;
}
.form_container label{
  font-size: 2rem;
  display: block;
}
.analytics_container{
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  text-align: center;
  /* justify-content: space-between; */
  /* max-width: 50rem; */
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.analytics_data{
  padding: 2rem 1.2rem;
  box-shadow: 0 0.8rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e2e2;
  border-radius: 0.8rem;
  width: 24rem;
  height: 18rem;
}
.analytics_data i{
  font-size: 2.8rem;
  color: #007bff;
  margin-bottom: 1.6rem;
}
.analytics_name{
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.analytics_value{
  font-size: 2.4rem;
  font-weight: 600;
}
.analytics_img{
  max-width: 48rem;
  height: 100%;
  /* max-height: 72rem; */
  overflow-y: auto;
}
.analytics_img img{
  width: 100%;
  height: auto;
}
.result_website h2{
  font-size: 2rem;
  margin-bottom: 1rem;
}
input[type="text"]{
  max-width: 50rem;
  width: 95vw;
  height: 7rem;
  padding: 0.6rem 1.6rem;
  font-size: 2rem;
  color: #303030;
  border: 2px solid #16a2ff;
  border-radius: 5px;
  display: block;
}
input[type=text]::-webkit-inner-spin-button, 
input[type=text]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type="text"]:focus{
  outline: none;
  box-shadow: 3px 3px 15px rgba(61, 177, 255, 0.5);
  transition: .1s;
  transition-property: box-shadow;
}
.btn{
  background-color: #007bff;
  margin-top: 1.6rem;
  color: #fff;
  padding-inline: 2rem;
}
.result_website{

}
@media screen and (min-width: 500px) {
  .analytics_img{
    max-height: 72rem;
  }
}
@media screen and (max-width: 500px) {
    .container{
      flex-wrap: wrap;
    }
    .analytics_img{
      width: 95%;
      max-width: 75rem;
    }
}
</style>
