<template>
    <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
    <section class="hero-section">
      <div class="container">
        <div class="top_notice">
          <p>Here, you'll find a bunch of cool stuff related to JavaScript Data structures and Frontend development. Plus, you'll discover useful articles on Frontend tricks and tips, making your designs sleek and user-friendly. Whether you're a seasoned pro or just starting out, there's something here for everyone.</p>
        </div>
        <adsense
          :adClient="googleAdClient"
          :adSlot="googleAdSlot"
        />
        <div class="top_filter">
          <h1 class="heading">Blog Articles👇</h1>
          <input type="text" class="filter_search" placeholder="Search Article" v-model="filterValue" @input="filterContent">
        </div>

        <div class="article_container" v-if="filterValue==''">
          <div class="article" v-for="(article, index) in articles" :key="index">
            <img class="article_img" :src=getImagePath(article.articleImg) alt="">
            <div class="article_content">
              <h2 class="article_heading" @click="openUrl(article.articleLink)">{{ article.articleTitle }} <i class="fa-solid fa-arrow-up-right-from-square"></i></h2>
              <p  class="article_desc">{{ article.articleDesc }}</p>
            </div>
          </div>
        </div>
        <div class="article_container" v-else>
          <div class="article" v-for="(article, index) in filteredArticles" :key="index">
            <img class="article_img" :src=getImagePath(article.articleImg) alt="">
            <div class="article_content">
              <h2 class="article_heading" @click="openUrl(article.articleLink)">{{ article.articleTitle }} <i class="fa-solid fa-arrow-up-right-from-square"></i></h2>
              <p  class="article_desc">{{ article.articleDesc }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    <Footer />
</template>
  
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import VueAdsense from 'vue-adsense'

export default {
  name:"Blog",
  components:{Header, Footer, 'adsense': VueAdsense},
  setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
  useHead({
    // Can be static or computed
    title: 'Learn Website Development and Optimization with Our Tutorials: UI Assembly',
    meta: [
      {
        name: `Learn Website Development and Optimization with Our Tutorials: UI Assembly`,
        content: 'Our website offers tutorials in blog and article format to help you learn website development and optimization. Improve your website\'s performance with our expert guidance. Get started today.',
      },
      ],

    })
    return {
      user,
    }
  },
  data() {
    return {
      filterValue:'',
      filtered: false,
      fromWebsitePerformance: true,
      filteredArticles:[],
      googleAdClient: 'ca-pub-9625497794661172',
      googleAdSlot: 'DIRECT',
      articles:[
        {
          articleImg: 'article_img1.png',
          articleTitle:"Making the Figma Logo using HTML and CSS",
          articleDesc:"Hello dear developers, in this article we are going to make the most popular UI/UX design tool, Figma by using frontend language HTML and CSS. HTML is for making the skeleton of the web page and CSS is for applying Styles in it. So to write good CSS, we must first write structured HTML. So...",
          articleLink: "https://medium.com/design-bootcamp/making-the-figma-logo-using-html-and-css-53744effecaa?source=user_profile---------12----------------------------"
        },
        {
          articleTitle:"The Ultimate Guide to Testing and Debugging JavaScript Code",
          articleDesc:"JavaScript is a robust and flexible language that is commonly used to develop web applications. As these applications increase in complexity, it is essential to maintain the code’s dependability and consistency. Testing and debugging are essential processes in the software development lifecycle...",
          articleImg: 'testing_js.png',
          articleLink: "https://medium.com/@pradipkaity/the-ultimate-guide-to-testing-and-debugging-javascript-code-296d1cd91ad8?source=user_profile---------10----------------------------"
        },
        {
          articleTitle:"Everything You Need to Know About the ‘this’ Keyword in JavaScript",
          articleDesc:"The ‘this’ keyword is very important in JavaScript for figuring out the context and scope when a function is run. But, it can be hard to fully understand ...",
          articleImg: 'this_js.png',
          articleLink: "https://medium.com/@pradipkaity/everything-you-need-to-know-about-the-this-keyword-in-javascript-1a7b0195310d?source=user_profile---------9----------------------------"
        },
        {
          articleTitle:"Arrow Functions vs Regular Functions: A Comprehensive Guide",
          articleDesc:"Introduced in ECMAScript 6 (ES6), arrow functions are a powerful feature that provides a concise way to write functions in JavaScript. With several advantages over regular functions, they have become widely used in modern JavaScript development.",
          articleImg:"regular_js.png",
          articleLink: "https://medium.com/@pradipkaity/arrow-functions-vs-regular-functions-a-comprehensive-guide-ab0272b13e2d?source=user_profile---------8----------------------------"
        },
        {
          articleTitle:"Mastering the JavaScript Spread Operator: A Comprehensive Guide",
          articleDesc:"One of the most powerful features introduced in ECMAScript 6 (ES6) is the spread operator. Represented by three consecutive dots (…), the spread operator offers a multitude of possibilities for manipulating arrays, objects, and function arguments.",
          articleImg:"spread_js.png",
          articleLink: "https://medium.com/@pradipkaity/mastering-the-javascript-spread-operator-a-comprehensive-guide-f3a7422561a?source=user_profile---------7----------------------------"
        },
        {
          articleTitle:"Destructuring in JavaScript: A Comprehensive Guide with Coding Examples",
          articleDesc:"Destructuring is a JavaScript feature that allows you to extract multiple values from an array or an object and assign them to variables in a concise and elegant way. Destructuring can make your code more readable, maintainable and expressive.",
          articleImg:"destructuring_js.png",
          articleLink: "https://medium.com/@pradipkaity/destructuring-in-javascript-a-comprehensive-guide-with-coding-examples-8ccf2a4cd541?source=user_profile---------6----------------------------"
        },
        {
          articleTitle:"Mastering JavaScript Collections: The Ultimate Guide to Sets and Maps",
          articleDesc:"JavaScript is a powerful programming language that can handle various types of data. However, sometimes the built-in data structures such as arrays and objects are not enough to meet the needs of modern web development. That’s why JavaScript introduced two new data structures in ES6: sets and maps",
          articleImg:"map_js.png",
          articleLink: "https://medium.com/@pradipkaity/mastering-javascript-collections-the-ultimate-guide-to-sets-and-maps-3f7c10456c5f?source=user_profile---------5----------------------------"
        },
        {
          articleTitle:"JavaScript Short Circuiting — From Beginner to Advanced: A Complete Guide with Examples",
          articleDesc:"JavaScript short circuiting is a feature of the logical operators || (OR) and && (AND) that allows them to return a value without evaluating the whole expression.It improves the performance and readability of the code, as well as provide some useful shortcuts for conditional logic.",
          articleImg:"short_js.png",
          articleLink: "https://medium.com/@pradipkaity/javascript-short-circuiting-from-beginner-to-advanced-a-complete-guide-with-examples-505952030524?source=user_profile---------4----------------------------"
        },
        {
          articleTitle:"Design Like a Pro: The Top 10 Figma Plugins Every UI Designer Should Use — Part I",
          articleDesc:"Figma has quickly become one of the most popular design tools among UI designers. Its collaborative features and powerful functionality make it an ideal choice for designers looking to work together in real-time. With Figma, designers can share ideas, iterate on designs, and collaborate seamlessly...",
          articleImg:"figma_js.png",
          articleLink: "https://medium.com/design-bootcamp/design-like-a-pro-the-top-10-figma-plugins-every-ui-designer-should-use-part-i-edc53c17ee76?source=user_profile---------3----------------------------"
        },
        {
          articleTitle:"The Ultimate Guide to Understanding Switch Cases in JavaScript",
          articleDesc:"Switch cases are a way of performing different actions based on different conditions in JavaScript. Instead of writing multiple if-else statement based on a single value, we can use Switch cases. Switch cases can make the code more readable and concise.",
          articleImg:"switch_js.png",
          articleLink: "https://medium.com/@pradipkaity/the-ultimate-guide-to-understanding-switch-cases-in-javascript-b49a2b3927da?source=user_profile---------2----------------------------"
        },
        {
          articleTitle:"Mastering Ternary Operators in JavaScript: How to Use Multiple in a Single Statement",
          articleDesc:"One of the most concise and elegant ways to express conditional statements is by using the ternary operator. It allows you to write simple if-else conditions in a single line of code.",
          articleImg:"ternary_js.png",
          articleLink: "https://medium.com/@pradipkaity/mastering-ternary-operators-in-javascript-how-to-use-multiple-in-a-single-statement-65e5c6c01c7f?source=user_profile---------1----------------------------"
        },
        {
          articleTitle:"Understanding the Power of Mutability and Immutability in JavaScript: Real-life Code Examples",
          articleDesc:"In JavaScript, different data types have different behaviors and locations in memory. Some data types are mutable, meaning they can be changed or modified after they are created. Other data types are immutable, meaning they cannot be changed or modified once they are created.",
          articleImg:"mutable_js.png",
          articleLink: "https://medium.com/@pradipkaity/understanding-the-power-of-mutability-and-immutability-in-javascript-real-life-code-examples-54daf356a035?source=user_profile---------0----------------------------"
        },
      ]
    };
  },
  methods: {
    getImagePath(imgName) {
      return require(`../assets/${imgName}`);
    },

    filterArticles() {
      this.filteredArticles = this.articles.filter(article => {
      const titleMatch = article.articleTitle.toLowerCase().includes(this.filterValue.toLowerCase());
      const descMatch = article.articleDesc.toLowerCase().includes(this.filterValue.toLowerCase());

       return titleMatch || descMatch;
      });

      return this.filteredArticles;
    },
    filterContent(){
      this.filteredArticles = this.filterArticles(this.filterValue);

      // Use the filteredArticles array to display the results in the DOM or do anything else with it.
      console.log(this.filteredArticles);
    },
    openUrl(url){
      window.open(url);
    }
  },
  computed: {
    user() {
      const store = useStore();
      return store.state.user;
    },
  },
}
</script>
  
<style scoped>
  .hero-section {
    cursor: default;
    background-color: #000000;
    background-image: linear-gradient(180deg, #02071a 0%, #022a44 74%);
    position: relative;
    min-height: 80vh;
  }
  .container{
    margin-block: 5rem;
    width: 90%;
  }
  .top_notice{
    padding: 1.6rem 2rem;
    box-shadow: 0.2rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.2) ;
    border-radius: 0.8rem;
    background-color: rgba(255, 255, 255, 0.04);
    border-left: 1rem solid #007bff;
  }
  .top_notice p{
    color: #cccccc;
    font-size: 1.6rem;
    line-height: 150%;
  }
  p::selection,
  h1::selection,
  h2::selection{
    background-color: #98b9ff;
  }
  .heading{
    color: #007bff;
    font-size: 2.4rem;
  }
  .article_container{
    column-count: 5;
    column-width: 300px;
  }
  .article {
    margin: 0 auto 15px;
    max-width: 400px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 0.8rem;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    border: 1px solid rgba(0, 123, 255, 0.5);
  }
  .article_img{
    width: 100%;
    height: auto;
  }
  .article_content{
    padding: 0.6rem 1.2rem;
  }
  .article_heading{
    margin: 1rem 0;
    font-size: 2rem;
    transition: all 0.2s ease-in-out;
    color: #f1f1f1;
  }
  .article_desc{
    font-size: 1.6rem;
    line-height: 150%;
    color: #c2c2c2;
  }
  .article_desc::selection{
    background-color: rgba(0, 0, 0, 0.5);
    color: #007bff;
  }
  .article:hover .article_heading{
    color: #268ACD;
    cursor: pointer;
  }
  .article:hover{
    box-shadow: 0 0.8rem 0.8rem 0 rgba(0, 0, 0, 0.1);
  }
  .top_filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7.2rem 0 2rem 0;
    flex-wrap: wrap;
  }
  input[type="text"]{
    max-width: 40rem;
    width: 50vw;
    height: 4rem;
    padding: 0.6rem 1rem;
    font-size: 2rem;
    color: #303030;
    border: 1px solid #268ACD;
    border-radius: 5px;
    display: block;
  }
  input[type="text"]::placeholder{
    font-size: 1.6rem;
    color: #acacac;
  }
  input[type="text"]:focus{
    outline: none;
    transition: .1s;
    transition-property: box-shadow;
  }
  @media screen and (max-width: 425px) {
    .top_notice p,
    .article_desc{
      font-size: 2.4rem;
    }
    .article_heading{
      font-size: 3rem;
      color: #268ACD
    }
  }
</style>