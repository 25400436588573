<template>
  <nav :class="{'fromWebsitePerformance': fromWebsitePerformance}">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
    <div class="navbar container">
        <router-link to="/" class="logo_image"><img class="logo" src="../assets/imgToMock.png" alt="">UI Assembly</router-link>
        <div class="menu-list">
            <router-link to="/blog" class="link">Blog</router-link>
            <router-link to="/allComponents" class="link">Components</router-link>
            <span class="tools link" @mouseover="showList()" @mouseleave="hideMenu()">Tools <span class="awesome_icon"><img src="../assets/DropDown.png" alt=""></span>
            <!-- <a href="#" class="link login">Login</a> -->
                <div class="tool-list showMenu">
                    <div class="menu_left">
                        <!-- <router-link to="/countdowntimer" class="link_menu"><img src="../assets/CountDown.png" alt="">Count Down Timer</router-link> -->
                        <router-link to="/sipcalculator" class="link_menu"><img src="../assets/SipCalculator.png" alt="">SIP Calculator</router-link>
                        <router-link to="/lyricsfinder" class="link_menu"><img src="../assets/LyricsFinder.png" alt="">Lyrics finder</router-link>
                        <router-link to="/bmicalculator" class="link_menu"><img src="../assets/BMIcalculator.png" alt="">BMI Calculator</router-link>
                        <router-link to="/qrcodegenerator" class="link_menu"><img src="../assets/QRGenerator.png" alt=""><span class="popular">QR Code Generator</span></router-link>
                        <router-link to="/signaturemaker" class="link_menu"><img src="../assets/SignatureMaker.png" alt=""><span class="popular">Your Signature Maker</span></router-link>
                        <router-link to="/websiteperformance" class="link_menu"><img src="../assets/WebsitePerformance.png" alt="">Website performance Analyzer</router-link>
                        <router-link to="/imagetoprototype" class="link_menu"><img src="../assets/ImagePrototype.png" alt=""><span class="popular">Image to prototype</span></router-link>
                        <router-link to="/cssGradient" class="link_menu"><img src="../assets/gradientHere.png" alt=""><span class="popular">CSS Gradient Maker</span></router-link>
                    </div>
                    <div class="menu_right">
                        <router-link to="/UrlShorten" class="link_menu"><img src="../assets/urlShort.png" alt=""><span class="popular">Shorten Url Link</span></router-link>
                        <router-link to="/passwordgenerator" class="link_menu"><img src="../assets/PasswordGenerator.png" alt="">Password Generator</router-link>
                        <router-link to="/CodeEditor" class="link_menu"><img src="../assets/cssMini.png" alt=""><span class="popular">Code Editor</span></router-link>
                        <router-link to="/minifyCss" class="link_menu"><img src="../assets/codeEdit.png" alt=""><span class="popular">Minify CSS</span></router-link>
                        <!-- <router-link to="/textToSpeech" class="link_menu"><img src="../assets/soundWave.png" alt=""><span class="popular">Speech To Text Generator</span></router-link> -->
                        <router-link to="/checkDiff" class="link_menu"><img src="../assets/diffCheck.png" alt=""><span class="popular">Compare Text & Code</span></router-link>
                        <router-link to="/imageConverter" class="link_menu"><img src="../assets/WhiteBoard.png" alt=""><span class="popular">Image Converter</span></router-link>
                        <router-link to="/imageCompress" class="link_menu"><img src="../assets/compress.png" alt=""><span class="popular">Compress Image</span></router-link>
                        <!-- <router-link to="/whiteboard" class="link_menu"><img src="../assets/WhiteBoard.png" alt=""><span class="popular">White Board</span></router-link> -->
                        <!-- <router-link to="/imageeditor" class="link_menu"><img src="../assets/ImageEditor.png" alt="">Image Editor</router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/UnitConverter.png" alt=""><span class="upcoming">Unit Converter</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/ROICalculator.png" alt=""><span class="upcoming">Marketing ROI calculator</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/AdsRoiCalculate.png" alt=""><span class="upcoming">Ads ROI calculator</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/SavePassword.png" alt=""><span class="upcoming">Save Your Password</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/DailyJournal.png" alt=""><span class="upcoming">Daily Journal</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/ReceipeOrganise.png" alt=""><span class="upcoming">Recipe organizer</span></router-link> -->
                    </div>
                </div>
            </span>
            <!-- <router-link to="/registration" class="link">Register</router-link> -->
            <router-link to="/login" class="btn_login" v-if="!user || !user.emailVerified">Log In</router-link>
            
            <div class="user_profile" v-if="user && user.emailVerified" @mouseover="showLogOut()" @mouseleave="hideLogOut()">
                <span v-if="user.emailVerified && user.photoURL" class="profile_image"><img :src="user.photoURL" :alt="user.displayName" class="rounded" width="40" height="40"/></span>
                <span v-else-if="user.emailVerified && user.displayName" class="profile_name">{{ user.displayName.split(' ').slice(0, 2).map(part => part[0].toUpperCase()).join('') }}</span>
                <span v-else-if="user.emailVerified" class="profile_email">{{ user.email.substring(0, 1).toUpperCase() }}</span>
                <div class="logout_hide" v-if="user.emailVerified">
                    <div class="logout_hide_inside">
                        <button @click="$router.push('/userprofile')" class="btn_profile">User Profile</button>
                        <button @click="logout" class="btn_logout">Log Out</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mobile_header">
            <div class="hamburger" >
                <i class="fas fa-bars" @click="showMenu()"></i>

                <div class="hamburger-menu-list">
                    <router-link to="/blog" class="link">Blog</router-link>
                    <router-link to="/allComponents" class="link">Components</router-link>
                    <a href="#" class="tools link" >Tools <span class="awesome_icon"><img src="../assets/DropDown.png" alt=""></span></a>
                    <!-- <a href="#" class="link login">Login</a> -->
                    <div class="hamburger-tool-list">
                        <!-- <router-link to="/countdowntimer" class="link_menu"><img src="../assets/CountDown.png" alt="">Count Down Timer</router-link> -->
                        <router-link to="/sipcalculator" class="link_menu"><img src="../assets/SipCalculator.png" alt="">SIP Calculator</router-link>
                        <router-link to="/lyricsfinder" class="link_menu"><img src="../assets/LyricsFinder.png" alt="">Lyrics finder</router-link>
                        <router-link to="/bmicalculator" class="link_menu"><img src="../assets/BMIcalculator.png" alt="">BMI Calculator</router-link>
                        <router-link to="/qrcodegenerator" class="link_menu"><img src="../assets/QRGenerator.png" alt=""><span class="popular">QR Code Generator</span></router-link>
                        <router-link to="/signaturemaker" class="link_menu"><img src="../assets/SignatureMaker.png" alt=""><span class="popular">Your Signature Maker</span></router-link>
                        <router-link to="/websiteperformance" class="link_menu"><img src="../assets/WebsitePerformance.png" alt="">Website performance Analyzer</router-link>
                        <router-link to="/imagetoprototype" class="link_menu"><img src="../assets/ImagePrototype.png" alt=""><span class="popular">Image to prototype</span></router-link>
                        <router-link to="/cssGradient" class="link_menu"><img src="../assets/gradientHere.png" alt=""><span class="popular">CSS Gradient Maker</span></router-link>
                        <router-link to="/UrlShorten" class="link_menu"><img src="../assets/urlShort.png" alt=""><span class="popular">Shorten Url Link</span></router-link>
                        <router-link to="/passwordgenerator" class="link_menu"><img src="../assets/PasswordGenerator.png" alt="">Password Generator</router-link>
                        <router-link to="/CodeEditor" class="link_menu"><img src="../assets/cssMini.png" alt=""><span class="popular">Code Editor</span></router-link>
                        <router-link to="/minifyCss" class="link_menu"><img src="../assets/codeEdit.png" alt=""><span class="popular">Minify CSS</span></router-link>
                        <!-- <router-link to="/textToSpeech" class="link_menu"><img src="../assets/soundWave.png" alt=""><span class="popular">Speech To Text Generator</span></router-link> -->
                        <router-link to="/checkDiff" class="link_menu"><img src="../assets/diffCheck.png" alt=""><span class="popular">Compare Text & Code</span></router-link>
                        <router-link to="/imageConverter" class="link_menu"><img src="../assets/WhiteBoard.png" alt=""><span class="popular">Image Converter</span></router-link>
                        <router-link to="/imageCompress" class="link_menu"><img src="../assets/compress.png" alt=""><span class="popular">Compress Image</span></router-link>
                        <!-- <router-link to="/whiteboard" class="link_menu"><img src="../assets/WhiteBoard.png" alt=""><span class="popular">White Board</span></router-link> -->
                        <!-- <router-link to="/imageeditor" class="link_menu"><img src="../assets/ImageEditor.png" alt="">Image Editor</router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/UnitConverter.png" alt=""><span class="upcoming">Unit Converter</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/ROICalculator.png" alt=""><span class="upcoming">Marketing ROI calculator</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/PickUpLine.png" alt=""><span class="upcoming">Pickup line generator</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/AdsRoiCalculate.png" alt=""><span class="upcoming">Ads ROI calculator</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/SavePassword.png" alt=""><span class="upcoming">Save Your Password</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/DailyJournal.png" alt=""><span class="upcoming">Daily Journal</span></router-link>
                        <router-link to="/about" class="link_menu"><img src="../assets/ReceipeOrganise.png" alt=""><span class="upcoming">Recipe organizer</span></router-link> -->
                    </div>
                </div>
            </div>
            <router-link to="/login" class="btn_login btn_login_mobile" v-if="!user">Log In</router-link>
            <div class="link user_profile user_profile_mobile" v-if="user" @click="showLogOutMobile()">
                <!-- <span v-if="user.email">{{ user.email.substring(0, 2).toUpperCase() }}</span>
                <small @click="logout" class="logout_hide">Sign out</small> -->



                <span v-if="user.emailVerified && user.photoURL" class="profile_image"><img :src="user.photoURL" :alt="user.displayName" class="rounded" width="40" height="40"/></span>
                <span v-else-if="user.emailVerified && user.displayName" class="profile_name">{{ user.displayName.split(' ').map(part => part[0].toUpperCase()).join('') }}</span>
                <span v-else-if="user.emailVerified" class="profile_email">{{ user.email.substring(0, 1).toUpperCase() }}</span>
                <div class="logout_hide hide_mobile" v-if="user.emailVerified">
                    <div class="logout_hide_inside">
                        <button @click="$router.push('/userprofile')" class="btn_profile">User Profile</button>
                        <button @click="logout" class="btn_logout">Log Out</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </nav>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: "Header",
    components: {
        
    },
    props:['fromWebsitePerformance', 'fromPrototype', 'user'],
    setup() {
        const store = useStore();
        const router = useRouter();

        const logout = async () => {
            await store.dispatch('logoutUser');
            router.push({ name: 'login' }); // Adjust the route name as needed
        };

        return {
            logout,
        };
    },
    methods:{
        showList() {
            let menuList = document.querySelector('.tool-list');
            let awesome_icon = document.querySelector('.awesome_icon');
            menuList.classList.remove('showMenu');
            awesome_icon.classList.add('rotate_icon');
        },
        showLogOut(){
            let logout = document.querySelector('.logout_hide')
            logout.classList.add('logout_show');
        },  
        showLogOutMobile(){
            let logout = document.querySelector('.hide_mobile')
            logout.classList.toggle('logout_hide_mobile');
            setTimeout(() => {
                logout.classList.remove('logout_hide_mobile');
            }, 2000);
        },
        hideLogOut(){
            let logout = document.querySelector('.logout_hide')
            logout.classList.remove('logout_show');
        },  
        hideMenu(){
            let menuList = document.querySelector('.tool-list');
            let awesome_icon = document.querySelector('.awesome_icon');
            menuList.classList.add('showMenu');
            awesome_icon.classList.remove('rotate_icon');
        },
        showMenu(){
            let hamburger = document.querySelector('.hamburger-menu-list');
            hamburger.classList.toggle('menuShow');
        }
    }
}
</script>

<style scoped>
.container {
    max-width: 1080px;
    margin: 0 auto;
}
nav{
    padding-inline: 0 !important;
}
.user_profile{
    cursor: pointer;
    position: relative;
}
.user_profile .profile_image{

}
.user_profile .profile_name{
    font-size: 2.4rem;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    padding: 1rem 1.2rem;
    width: 5.2rem;
    text-align: center;
    display: inline-block;
}
.user_profile .profile_email{
    font-size: 2.4rem;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    padding: 0.36rem 1.5rem;
}
.mobile_header{
    display: none;
}
.btn_login{
    background: linear-gradient(140deg, #0782cf 0%, #42edf0 50%, #e9ff99 100%);
    color: #0a0341;
    font-weight: 400;
    padding: 0.6rem 3.2rem;
    letter-spacing: -1px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}
.btn_login:hover{
    background: linear-gradient(200deg, #0782cf 0%, #42edf0 50%, #e9ff99 100%);
}
.btn_login::before{
    background-color: transparent !important;
}
.logout_show{
    opacity: 1 !important;
    visibility: visible !important;
}
.logout_hide{
    opacity: 0;
    visibility: hidden;
    width: 15rem;
    position: absolute;
    top: 30px;
    right: -20px;
    padding-top: 2rem;
    color: #134f94;
    border-radius: 6px;
    z-index: 10;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease-in-out;
}
.logout_hide_mobile{
    opacity: 1;
    visibility: visible;
    right: -6px;
    top: 32px;
    width: 17rem;
}
.logout_hide_mobile button{
    font-size: 2.4rem;
}
.logout_hide_inside{
    padding: 1rem;
    background-color: #FFF;
    backdrop-filter: blur(10);
    border-radius: 0.8rem;
}
.fromWebsitePerformance{
    background-color: #091b41;
}
.btn_profile,
.btn_logout{
    background-color: transparent;
    color: #134f94;
    border: none;
    font-size: 1.6rem;
    cursor: pointer;
    padding: 0.6rem;
    border-radius: 0.4rem;
    width: 100%;
}
.btn_profile:hover,
.btn_logout:hover{
    background-color: rgba(7, 130, 207, 0.08);
}
.btn_profile{
    margin-bottom: 8px;
}
.logo_image{
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 2.4rem;
    padding-inline: 0;
}
.navbar {
    /* padding: 4rem 0; */
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rounded{
    border-radius: 50%;
}
.awesome_icon img{
    transform: rotate(0);
    width: 1.4rem;
    height: 1.2rem;
    transition: all 0.2s ease-in-out;
}
.logo {
    height: 4rem;
    margin-right: 1rem;
}
.menu-list {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    position: relative;
}
.hamburger{
    display: none;
    color: #fff;
    font-size: 2.4rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.8rem;
    padding: 1rem 2rem;
    z-index: 19;
}
.btn_login_mobile,
.user_profile_mobile{
    display: none;
}
.hamburger-menu-list{
    position: absolute;
    width: 92vw;
    height: 0vh;
    visibility: hidden;
    opacity: 0;
    top: 12rem;
    right: 4vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4rem 2rem 4rem 4rem;
    gap: 2rem;
    border-radius: 2rem;
    overflow-y: auto;
    transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(1rem);
}
.menuShow{
    height: 85vh;
    visibility: visible;
    opacity: 1;
}
.hamburger-menu-list .link,
.hamburger-menu-list .link_menu{
    color: #fff;
    font-size: 3.2rem;
    text-decoration: none;
}
.hamburger-tool-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.hamburger-tool-list img{
    width: 3.2rem;
    margin-right: 1.6rem;
}
.link_menu{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 1.4rem !important;
    font-weight: normal;
    text-decoration: none;
    padding: 1rem 0 1rem 0.8rem;
    color: #09576a;
    border-radius: 0.2rem;
}
.link_menu .popular{
    font-size: 1.4rem;
}
/* .link_menu img{
    width: 2rem;
} */
.menu-list .link {
    color: #fff;
    text-decoration: none;
    font-size: 2rem;
    z-index: 10;
    position: relative;
    padding: 0.6rem 2.4rem;

}

/* .menu-list .link:not(.login, .tools)::before {
    content: "";
    position: absolute;
    top: 0px;
    width: 100%;
    height: 4.2rem;
    left: 0;
    transform: skew(0deg) scale(0);
    transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
} */
nav a{
    font-weight: normal;
}

.menu-list .link:hover::before {
    background-color: rgba(255, 255, 255, 0.15);
    transform: skew(20deg) scale(1);
}

.login {
    color: #60bffd !important;
    border: 2px solid #2F96DB;
    border-radius: 4px;

}

.tools {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: default;
}

.tools span {
    font-size: 2rem;
}

.rotate_icon {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
}

.showMenu {
    opacity: 0;
    visibility: hidden;
}

.tool-list {
    position: absolute;
    top: 50px;
    right: 0;
    background: rgba(255, 255, 255, 0.85);
    background: linear-gradient(70deg, rgba(255, 255, 255, 0.85) 60%, rgba(186, 255, 252, 0.85) 100%);
    backdrop-filter: blur(10px);
    border-radius: 0.6rem;
    /* width: 80rem; */
    display: grid;
    grid-template-columns: 32rem 32rem;
    column-gap: 4.8rem;
    padding: 2rem;
    z-index: 10;
    transition: all 0.3s ease-in-out;
    box-shadow: 0.4rem 0.4rem 1rem 0.4rem rgba(0, 0, 0, 0.1);
}

.tool-list a {
    /* display: block;
    font-size: 1.6rem;
    font-weight: normal;
    text-decoration: none;
    padding: 1rem 0 1rem 0.8rem;
    color: #09576a;
    border-radius: 0.2rem; */
}

.tool-list a span {
    position: relative;
}

.tool-list a .upcoming::before {
    position: absolute;
    right: -5rem;
    top: -9px;
    content: 'Upcoming';
    background-color: #298DD1;
    color: #fff;
    padding: 2px 6px;
    border-radius: 20px;
    font-size: 0.9rem;
}

.tool-list a .popular::before {
    position: absolute;
    right: -3rem;
    top: -9px;
    /* content: 'Hot'; */
    background-color: #ff4538;
    color: #fff;
    padding: 2px 6px;
    border-radius: 20px;
    font-size: 1rem;
}

.tool-list a:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(47, 150, 219, 0.25);
    /* box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5); */
}
.tool-list img{
    width: 2rem;
    height: auto;
    margin-right: 8px;
}

.tool-list a:hover img {
    animation: wobel 0.2s forwards ease-in-out;
}

@keyframes wobel {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(0.8);
    }

    40% {
        transform: scale(1.2);
    }

    90% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.menu_left a:last-child,
.menu_right a:last-child {
    margin-bottom: 0;
}

.menu_left i,
.menu_right i {
    font-size: 2rem;
    margin-right: 1rem;
    color: #134f94;
    transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
}

@media screen and (max-width: 530px) {
    .menu-list{
        display: none;
    }
    .mobile_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
    }
    .hamburger{
        display: inline-block;
        margin-right: 2rem;
    }
    .user_profile_mobile{
        display: inline-block;
    }
    .user_profile_mobile span{
        font-size: 3rem;
        color: #fff;
    }
    .btn_login_mobile
    {
        font-weight: 800;
        display: flex;
        align-items: center;
    }
    nav a.router-link-exact-active::before {
      background-color: transparent !important;
    }
    .link_menu,
    .popular{
        font-size: 2.4rem !important;
    }
    .logo_image{
        font-size: 3rem;
    }
    .logo {
        height: 5.4rem;
    }
}
@media screen and (min-width: 816px) and (max-width: 970px) {
    .navbar .logo_image,
    .menu-list .link,
    .btn_login{
        font-size: 1.6rem;
    }
}
</style>