<template>
  <section class="hero-section">
    <Header :user="user"/>
    <div class="container">
      <div class="search_fields">
        <h1>Lyrics Finder</h1>
        <form id="search-form">
            <input type="text" id="song-input" placeholder="Enter song name" v-model="songTitle">
            <input type="text" id="artist-input" placeholder="Enter artist name" v-model="artistName"><br>
            <button type="submit" class="submit btn_primary" @click="searchSong">Search</button>
            <button type="reset" class="reset btn_secondary" @click="resetResult">Reset</button>
        </form>
      </div>

      <div class="song_result" v-if="foundSong">
        <div class="img_container">
          <img :src="imgUrlRes" alt="" id="thumbnail" class="thumbnail" draggable="false">
        </div>
        <p id="songName" :title="songNameRes">Song Name: <strong>{{ songNameRes }}</strong></p>
        <p id="artistName" :title="artistNameRes">Artist Name: <strong>{{ artistNameRes }}</strong></p>
        <p id="lyrics-container">Lyrics of {{ songNameRes }}: <a target="_blank" :href="lyriscsUrlRes">Visit Here</a></p>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
export default {
    name:"LyricsFinder",
    components:{Header, Footer},
    computed: {
      user() {
        const store = useStore();
        return store.state.user;
      },
    },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Find Lyrics of any Song.',
      meta: [
        {
          name: `Find Lyrics of any Song.`,
          content: 'Never hustle for a lyrics again: Use our lyrics finder tool just to do that.',
        },
        ],

      })
      return {
        user,
      };
    },
    data(){
      return{
        artistName: '',
        songTitle: '',
        foundSong: false,
        songNameRes: '',
        artistNameRes: '',
        lyriscsUrlRes: '',
        imgUrlRes: ''
      }
    },
    methods:{
      searchSong(e){
        e.preventDefault();
        if(this.artistName != '' || this.songTitle != ''){
          this.artistName = document.querySelector('#artist-input').value;
          this.songTitle = document.querySelector('#song-input').value;
          this.getLyrics(this.artistName, this.songTitle);
        }
        // console.log(this.artistName, this.songTitle);
      },
      async getLyrics(artistName, songTitle) {
        const url = `https://geniurl.p.rapidapi.com/search/top?q=${encodeURIComponent(artistName + ' - ' + songTitle)}&artist=${encodeURIComponent(artistName)}&song=${encodeURIComponent(songTitle)}`;
        const options = {
          method: 'GET',
          headers: {
            'X-RapidAPI-Key': 'f52843da0emsheb1da8e61855162p1c1fd7jsnb6c8df57368c',
            'X-RapidAPI-Host': 'geniurl.p.rapidapi.com'
          }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        const lyrics = this.extractLyricsFromResponse(result);
        this.displayLyrics(lyrics);
      } catch (error) {
          this.displayError('Failed to fetch lyrics. Please try again later.');
      }
    },
    extractLyricsFromResponse(response) {
      console.log(response)
      // Extract the lyrics from the response
      if (response.url) {
        const lyrics = response;
        return lyrics;
      } else {
        return 'Lyrics not found for the given song and artist.';
      }
  },
  resetResult(){
    this.artistName = '';
    this.songTitle = '';
    this.foundSong = false
  },
  displayLyrics(lyrics) {
      this.foundSong = true;
      this.songNameRes = lyrics.meta.title;
      this.artistNameRes = lyrics.meta.artists;
      this.imgUrlRes = lyrics.resources.image;
      this.lyriscsUrlRes = lyrics.url
  },

    displayError(errorMessage) {
      this.foundSong = true;
      document.getElementById('lyrics-container').textContent = errorMessage;
    }
  }
}
</script>

<style scoped>
.hero-section {
    cursor: default;
    background-image: url("../assets/lyricsFinder.jpg");
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    /* background-size: 100% 100%; */
    background-position: top;
    background-size: cover;
    height: 98vh;
    position: relative;
    /* overflow: hidden; */
    /* font-size: 5rem; */
}
.search_fields{
  max-width: 480px;
  width: 90%;
  padding: 2rem;
  background: rgb(78,162,240);
  background: linear-gradient(146deg, rgba(78,162,240,0.45) 0%, rgba(245,245,245,0.45) 100%);
  backdrop-filter: blur(2px);
}
.container{
  display: flex;
  justify-content: flex-start;
  width: 90%;

}
input[type='text']{
  margin-bottom: 1rem;
  border: 2px solid #ccc;
  border-radius: 5px;
  color: #555;
  outline: none;
  width: 100%;
  height: 5rem;
  font-size: 2rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

input[type='text']:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
  h1 {
    font-size: 2.4rem;
    color: #9bd7ff;
    margin: 0 0 1.6rem 0;
  }
  .submit{
    box-shadow: 0.8rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.5);
    width: 50%;
  }
  .reset{
    margin-left: 1rem;
    width: 45%;
  }

  #lyrics-container {
    margin-top: 20px;
    text-align: left;
    white-space: pre-wrap;
  }
  .song_result{
    width: 90%;
    max-width: 480px;
    padding: 1rem;
    background: rgb(142,174,255);
    background: linear-gradient(146deg, rgba(142,174,255,1) 0%, rgba(255,218,218,1) 100%);
  }

.song_result p{
  font-size: 2rem;
  color: #414141;
}
.song_result strong{
  color: #007bff;
  font-weight: 800;
  font-size: 2.4rem;
  width: 30rem;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.img_container{
  width: 100%;
  height: 60rem;
  overflow: hidden;
  margin-bottom: 1rem;
}
.img_container img{
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}
@media screen and (max-width: 51rem) {
  .container {
    width: 90vw;
    margin: 0 auto;
    flex-direction: column;
    gap: 2rem;
  }
}
</style>