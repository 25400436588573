<template>
  <section class="hero-section">
    <Header :fromWebsitePerformance="fromWebsitePerformance"/>
    <div class="container">
      <h1>Prototype Generator</h1>
      <div class="input_handlers">
        <div class="pick_color">
          <label for="shadow">Body Color</label>
          <input type="color" v-model="bodyColor" id="shadow" class="body_color">
        </div>
        <div class="pick_color">
          <p>Shadow Color</p>
          <!-- <input type="checkbox" id="shadowColor" @change="shadowColorChange" v-model="shadowColor" :disabled="outputOne=='' || outputTwo==''"> -->
          <input type="checkbox" id="shadowColor" @change="shadowColorChange" v-model="shadowColor">
          <label for="shadowColor" class="toggleSwitch" :class="{'disableSlider': outputOne=='' || outputTwo==''}"></label>
        </div>
        
        <input class="slider range_slider" title="Upload 2 Images to change shadow Amount" type="range" v-model="shadowValue" min="0" max="100" step="2" @input="shadow" >

        <button @click="downloadAsImage" class="btn_primary btn">Download</button>
      </div>


      <div class="prototype_container" ref="downloadDiv">
        <div class="left_prototype prototype">
          <!-- <img src="../assets/mobile_frame.png" alt="" class="mobile_frame"> -->
          <div class="image_preview">
            <!-- <img src="../assets/blurLayer.png" alt="" class="blur_layer"> -->
            <div class="input_image" v-if="outputOne==''">
              <input type="file" ref="fileInputOne" class="upload_file" accept="image/*" :id="fileInputIdOne" @change="loadFileOne">
              <label :for="fileInputIdOne" class="upload_label" id="upload_label_one">Upload <br>Image</label>
            </div>
            <div class="image_uploaded" v-else>
              <img :src="outputOne" draggable="false" />
            </div>
          </div>
          <img src="../assets/blurLayer.png" alt="" class="blur_layer">
        </div>
        <div class="right_prototype prototype">
          <!-- <img src="../assets/mobile_frame.png" alt="" class="mobile_frame"> -->
          <div class="image_preview">
            <!-- <img src="../assets/blurLayer.png" alt="" class="blur_layer"> -->
            <div class="input_image" v-if="outputTwo==''">
              <input type="file" ref="fileInputTwo" class="upload_file" accept="image/*" :id="fileInputIdTwo" @change="loadFileTwo">
              <label :for="fileInputIdTwo" class="upload_label" id="upload_label_two">Upload <br>Image</label>
            </div>
            <div class="image_uploaded" v-else>
              <img :src="outputTwo" draggable="false" />
            </div>
          </div>
          <img src="../assets/blurLayer.png" alt="" class="blur_layer">
        </div>
      </div> 
    </div>
  </section>
</template>

<script>
import Header from "../components/Header.vue"
import html2canvas from 'html2canvas';
export default {
  name:"ImageToPrototypeOld",
  components:{Header},
  data() {
    return {
      fromWebsitePerformance: true,
      shadowValue: 0,
      shadowColor: false,
      bodyColor: "#429EFF",
      outputOne: "",
      outputTwo: "",
      fileInputIdOne: "file_1",
      fileInputIdTwo: "file_2",
      base64Image: null
    };
  },
  methods: {
    loadFileOne(event) {
      let label = document.querySelector('#upload_label_one')
      const output = this.$refs.fileInputOne.nextElementSibling.nextElementSibling;
      const file = event.target.files[0];
      this.outputOne = URL.createObjectURL(file);
      label.style.display = 'none';
    },
    loadFileTwo(event) {
      let label = document.querySelector('#upload_label_two')
      const output = this.$refs.fileInputTwo.nextElementSibling.nextElementSibling;
      const file = event.target.files[0];
      this.outputTwo = URL.createObjectURL(file);
      label.style.display = 'none';
    },
    shadow() {
      console.log(this.shadowValue)
      const translateX = this.shadowValue;
      const scaleY = Number(1+(this.shadowValue / 500));
      // return shadowAmount;
      const shadowBody = document.querySelectorAll(".blur_layer");
      if(this.shadowColor==false){
        shadowBody.forEach((element) => {
          element.style.transform = `translatex(${translateX}px) scaleY(${scaleY})`
          // element.style.boxShadow = `15px 15px 30px 0 rgba(0, 0, 0, ${shadowAmount})`;
        });
      }else{
        shadowBody.forEach((element) => {
          element.style.boxShadow = `15px 15px 30px 0 rgba(255, 255, 255, ${shadowAmount})`;
        });
      }
    },
    shadowColorChange(){
      // const shadowAmount = this.shadowValue / 100;
      const translateX = this.shadowValue / 10;
      const scaleY = this.shadowValue / 200;
      // this.shadow();
      // const shadowBody = document.querySelectorAll(".image_preview");
      const shadowBody = document.querySelectorAll(".blur_layer");

      if(this.shadowColor==false){
        shadowBody.forEach((element) => {
          element.style.transform = `translatex(${translateX}) scaleY(${scaleY})`
          // element.style.boxShadow = `15px 15px 30px 0 rgba(0, 0, 0, ${shadowAmount})`;
        });
        console.log("Black");
      }else{
        shadowBody.forEach((element) => {
          element.style.boxShadow = `15px 15px 30px 0 rgba(255, 255, 255, ${shadowAmount})`;
        });
        console.log("White");
      }
    },
    // downloadAsImage(){
    //   var container = document.querySelector(".prototype_container"); /* full page */
    //   html2canvas(container, { allowTaint: true }).then(function (canvas) {

    //     var link = document.createElement("a");
    //     document.body.appendChild(link);
    //     link.download = "html_image.jpg";
    //     link.href = canvas.toDataURL();
    //     link.target = '_blank';
    //     link.click();
    //   });
    // },

    async downloadAsImage() {
      const divToDownload = this.$refs.downloadDiv;
      // const divToDownload = document.querySelector(".prototype_container");;

      // Use html2canvas to convert the div content to an image
      const canvas = await html2canvas(divToDownload, { useCORS: true });

      // Convert the canvas to a data URL
      const dataUrl = canvas.toDataURL('image/png');

      // Create a temporary link element and trigger the download
      const link = document.createElement('a');
      link.download = 'div_as_image.png';
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },


  },
  watch: {
    bodyColor() {
      const prototype = document.querySelector(".prototype_container");
      prototype.style.backgroundColor = this.bodyColor;
    },
  }
};
</script>

<style scoped>
/* Add any custom CSS styles here */
.hero-section {
  cursor: default;
  background: rgba(255, 255, 255, 0.85);
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.85) 40%, rgba(148, 255, 250, 0.85) 100%);
  min-height: 82vh;
  position: relative;
  padding-bottom: 10rem;
}
.input_handlers{
  margin-block: 2rem;
  max-width: 80rem;
  width: 90%;
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  background-color: #3e676e;
  border-radius: 0.4rem;
  box-shadow: 0.4rem 0.4rem 1rem 0 rgba(0, 0, 0, 0.4);
}
.range_slider{
  width: 35%;
}
.range_slider{
  position: relative;
  display: inline-block;
}

.range_slider[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -2.4rem;
  left: 0;
}
.disableSlider{
  cursor: not-allowed !important;
}
.disableSlider::-webkit-slider-thumb {
  cursor: not-allowed;
}
h1{
  font-size: 3.2rem;
  margin-block: 2rem;
}
.pick_color{
  padding: 0.4rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #fff;
  gap: 1rem;
  height: 5rem;
}
.body_color {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 3.9rem;
  height: 4.2rem;
  border: none;
  cursor: pointer;
}
/* To hide the checkbox */
#shadowColor {
  display: none;
}
.toggleSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50px;
  height: 30px;
  /* background-color: rgb(82, 82, 82); */
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: .2s;
}
.toggleSwitch::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  left: 5px;
  background-color: transparent;
  border-radius: 50%;
  transition-duration: .2s;
  /* box-shadow: 5px 2px 7px rgba(8, 8, 8, 0.26); */
  border: 5px solid #429EFF;
}
#shadowColor:checked+.toggleSwitch::after {
  transform: translateX(100%);
  transition-duration: .2s;
  background-color: #429EFF;
  /* border: none; */
}
/* Switch background change */
#shadowColor:checked+.toggleSwitch {
  /* background-color: rgb(148, 118, 255); */
  transition-duration: .2s;
}
.body_color::-webkit-color-swatch {
  border-radius: 50%;
  border: 2px solid #ffffff;
}
.body_color::-moz-color-swatch {
  border-radius: 50%;
  border: 7px solid #000000;
}
.prototype_container {
  display: flex;
  background-color: #fff;
  justify-content: space-around;
  /* gap: 48px; */
  align-items: center;
  max-width: 108rem;
  height: 72rem;
  margin: 0 auto;
  border: 1px solid #be9a91;
  border-radius: 24px;
  margin-top: 3.2rem;
  overflow: hidden;
  /* box-shadow: 15px 15px 20px 0 rgba(0, 0, 0, 0.5); */
}
.left_prototype{
  border-radius: 3.2rem;
}
.right_prototype{
  border-radius: 3.2rem;
}
.btn{
  padding: 1rem 1.6rem;
  background-color: #007bff;
  color: #fff;
  margin-top: 0;
}
.box {
  /* position: relative;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
}
/* You can keep the existing background-color style for the .box element */
/* .box {
  
} */
.left_prototype,
.right_prototype {
  /* background-color: rgba(255, 255, 255, 0); */
  /* background-color: rgb(255, 255, 255); */
  /* background-image: url("../assets/mobile_frame.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */

  position: relative;
  width: 30rem;
  height: 60rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile_frame{
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.blur_layer{
  position: absolute;
  top: 0;
  left: 24px;
  transform: scaleY(1.1);
  /* z-index: -1; */
  width: 100%;
  height: auto;
}

.upload_file {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.input_image{ 
  width: 14rem;
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload_label {
  border-radius: 50%;
  border: 1px dashed #696969;
  padding: 5rem 4.5rem;
  font-size: 1.6rem;
  text-align: center;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.image_uploaded{
  width: 100%;
  height: 100%;
  border-radius: 3.2rem;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
.image_preview {
  width: 91.5%;
  height: 96.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.2rem;
  background-color: #ffffff;
  position: relative;
  z-index: 10;
}
.image_uploaded img {
  width: 29rem;
  height: 59rem;
  object-fit: cover;
  object-position: center;
  z-index: 10;
}
.frame_image {
  width: auto;
  height: 90%;
  z-index: 2;
}
</style>
