<template>
  <div class="image-converter" v-if="!convertedImageUrl">
        <input type="file" id="upload" ref="imageInput" class="uploadFile" accept="image/png" @change="convertImage">
        <div class="labels">
            <i class="fa-solid fa-cloud-arrow-up upload"></i>
            <label for="upload" class="upload_label1">Upload File</label>
            <label for="upload" class="upload_label1"><span>(PNG to JPEG)</span></label>
            <label for="upload" class="upload_label2"> Drag and Drop to Upload</label>
        </div>
    </div>
    <div class="converted_image" v-if="convertedImageUrl">
      <div class="img_container">
        <img v-if="convertedImageUrl" :src="convertedImageUrl" alt="Converted Image">
      </div>
      <div class="details">
        <img src="../../assets/loaderSpin.gif" alt="" v-if="spinner" class="spinner">
        <div v-if="!spinner">
          <p class="fileName" :title="fileName"><span>File Name:</span> {{ fileName }}</p>
          <p><span>File Size: </span>{{ fileSize }}</p>
          <a v-if="downloadUrl" :href="downloadUrl" download="converted_image.jpeg">
              <button class="btn pointer">Download Converted Image</button>
          </a>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: "PngToJpg",
    components:{},
    data() {
      return {
        fromWebsitePerformance: true,
        convertedImageUrl: '',
        downloadUrl: '',
        fileName: '',
        fileSize: 0,
        spinner: true
      };
    },
    methods: {
      convertImage() {
        const file = this.$refs.imageInput.files[0];
        console.log(file);
        this.fileName = file.name;
        this.fileSize = file.size > 1024 ? `${Number((file.size / 1024).toFixed(2))}KB` : `${file.size}B`
        if (!file) return;
  
        const reader = new FileReader();
        reader.onload = (event) => {
          const imgDataUrl = event.target.result;
          const img = new Image();
          img.src = imgDataUrl;
  
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
  
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
  
            canvas.toBlob((blob) => {
              const convertedUrl = URL.createObjectURL(blob);
              this.convertedImageUrl = convertedUrl;
              setTimeout(() => {
                this.spinner = false;
              }, 4000);
              
              // Set download link
              this.downloadUrl = convertedUrl;
            }, "image/jpeg", 0.8);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
  };
</script>

<style scoped>
 
</style>