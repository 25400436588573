<template>
  <div>
    <section class="hero-section">
      <Header :user="user"/>
        <div class="container">
          <div class="input_fields">
            <div class="amount_invested">
              <label for="investmentAmount">Investment Amount (per month): {{ range }} INR</label>
              <input type="number" id="investmentAmount" place min="500" max="100000" v-model="range" @input="updateInvestmentAmount">
            </div>
            <input type="range" id="investmentAmountSlider" class="slider slider1" min="500" max="100000" step="500" @input="updateInvestmentAmount" v-model="range">
            <!-- <span id="investmentAmountValue">{{ range }}</span> INR -->

            <div class="amount_invested">
              <label for="investmentPeriod">Investment Period (in years): {{ period }} years</label>
              <input type="number" id="investmentPeriod" min="1" max="50" @input="updateInvestmentPeriod" v-model="period">
            </div>
            <input type="range" id="investmentPeriodSlider" class="slider slider1" min="1" max="50" @input="updateInvestmentPeriod" v-model="period">
            <!-- <span id="investmentPeriodValue">{{ period }}</span> years -->

            <div class="amount_invested">
              <label for="expectedReturn">Estimated Return (per year): {{ returnPercent }}%</label>
              <input type="number" id="expectedReturn" min="0" max="100" @input="updateExpectedReturn" v-model="returnPercent">
            </div>
            <input type="range" id="expectedReturnSlider" class="slider slider1" min="0" max="100" @input="updateExpectedReturn" v-model="returnPercent">
            <!-- <span id="expectedReturnValue">{{ returnPercent }}</span> % -->

            <button @click="calculateReturn" class="btn_primary">Calculate</button>
          </div>

            <div id="result" class="result-container" v-if="showReturn">
              <h1>SIP Calculator</h1>
              <p>Total Investment Amount:</p>
              <p class="value_res"><i class="fa-sharp fa-solid fa-indian-rupee-sign"></i> {{ numberWithCommas(totalInvestment) }}</p>
              <p>Estimated Return:</p>
              <p class="value_res"><i class="fa-sharp fa-solid fa-indian-rupee-sign"></i> {{ numberWithCommas(estReturn) }}</p>
              <p>Total Value:</p>
              <p class="value_res"><i class="fa-sharp fa-solid fa-indian-rupee-sign"></i> {{ numberWithCommas(totalValue) }}</p>
            </div>
        </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head'
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
export default {
    name:"SipCalculator",
    components: {Header, Footer},
    computed: {
    user() {
      const store = useStore();
      return store.state.user;
    },
  },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'Simple SIP Calculator: UI Assembly',
      meta: [
        {
          name: `Simple SIP Calculator: UI Assembly`,
          content: 'Empower Your Investments with SIP Calculator: Estimate potential returns when allocating funds into systematic investment plans. Maximize your financial strategy with this indispensable tool for optimizing returns on investment.',
        },
        ],

      })
      return {
        user,
      };
    },
    data(){
      return{
        range: 0,
        period: 0,
        returnPercent: 0,
        totalInvestment: 0,
        estReturn: 0,
        totalValue: 0,
        showReturn: false
      }
    },
    methods:{
      updateInvestmentAmount() {
        this.range = parseInt(document.getElementById('investmentAmount').value);
      },

      updateInvestmentPeriod() {
        this.period = parseInt(document.getElementById('investmentPeriod').value);
      },

      updateExpectedReturn() {
        this.returnPercent = parseInt(document.getElementById('expectedReturn').value);
      },

      calculateReturn() {
        if(this.period > 0 && this.returnPercent > 0){
          this.showReturn = true;
          this.totalInvestment = (parseInt(this.range) * 12 * parseInt(this.period)).toFixed(2);
          this.totalValue = (parseInt(this.range) * ((1 + parseInt(this.returnPercent) / 1200) ** (parseInt(this.period) * 12) - 1) * (1 + parseInt(this.returnPercent) / 1200) / (parseInt(this.returnPercent) / 1200)).toFixed(2);
          this.estReturn = (this.totalValue - this.totalInvestment).toFixed(2);
        }
        },

        numberWithCommas(price) {
          let dollarIndianLocale = Intl.NumberFormat('en-IN');
          return dollarIndianLocale.format(price)
        }
    }
}
</script>

<style scoped>
.hero-section {
    cursor: default;
    background-image: url("../assets/luxuryLife.jpg");
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    /* background-size: 100% 100%; */
    background-position: top;
    background-size: cover;
    height: 98vh;
    position: relative;
    /* overflow: hidden; */
    /* font-size: 5rem; */
}
.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin: 20px 10rem;
}
.input_fields{
  width: 50%;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 3rem 2rem 2rem;
}
label {
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0.2rem;
  left: 0;
  font-size: 1.6rem;
  padding: 0.3rem 1.6rem;
  border-radius: 0.2rem;
  color: #cacaca;
}
button {
 width: 100%;
}
.amount_invested{
  position: relative;
}
.amount_invested:not(:first-child){
  margin-top: 4.8rem;
}

input[type="number"]{
  width: 100%;
  background-color: #212121;
  height: 7rem;
  padding: 3rem 1rem 1rem;
  font-size: 2rem;
  color: #fff;
  border: 2px solid #16a2ff;
  border-radius: 5px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type="number"]:focus{
  background-color: #212121;
  outline-color: rgb(0, 255, 255);
  box-shadow: 3px 3px 15px rgba(61, 177, 255, 0.5);
  transition: .1s;
  transition-property: box-shadow;
}
.slider1{
  margin-top: 1rem;
}
.result-container {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(25px);
  width: 50%;
  border: 1px solid #ccc;
  padding: 2rem 2.4rem;
}
.result-container p:not(.value_res){
  font-size: 2rem;
  color: #081b24;
}
.value_res{
  font-size: 2.4rem;
  font-weight: 800;
  color: #16a2ff;
  margin-bottom: 2rem;
}
h1 {
  font-size: 3.2rem;
  border-bottom: 1px solid #747474;
  margin-bottom: 2rem;
}
@media screen and (max-width: 51rem) {
  .container {
    width: 90vw;
    margin: 0 auto;
    flex-direction: column;
    gap: 2rem;
  }
  .input_fields{
    width: 100%;
  }
  .result-container {
    width: 100%;
  }
  button{
    margin-top: 5rem;
  }
  .slider{
    margin-top: 2rem;
  }
}
@media screen and (max-width: 425px) {
  label{
    font-size: 2.2rem;
  }
  input[type="number"]{
    height: 12rem;
    font-size: 2.8rem;
    padding: 6.8rem 1rem 1rem;
  }
}
</style>
