<template>
  <section class="hero-section">
    <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
    <div class="container">
      <div v-if="user" class="user_container">
        <div class="loader" v-if="loaderShow">
          <Loader />
        </div>
        <div class="image" :class="{'img_not_present' : !user.photoURL}">
          <img v-if="user.photoURL" :src="user.photoURL" :alt="user.displayName" class="rounded" draggable="false"/>
          <div class="user_name" v-if="!user.photoURL">{{ user.displayName.split(' ').map(part => part[0].toUpperCase()).join('') }}</div>
        </div>
        <div>
          <p class="name_data">{{ user.displayName }}</p>
          <p class="email_data">{{ user.email }}</p>
          <p class="email_data">{{ location }}</p>
          <p class="email_data">{{ biodata }}</p>
          <p class="email_data">{{ website }}</p>
          <p class="email_data">{{ company }}</p>
          <p class="email_verified" v-if="user.emailVerified">Email Verified</p>
          <p class="email_verified" v-if="!user.emailVerified">Email Not Verified</p>
          <p class="user_from">Member Since: <strong>{{ userFrom }}</strong></p>
        </div>
      </div>
      <div class="action_btns">
        <button class="edit_profile" @click="editUserData()"><i class="fa-solid fa-gear"></i>Edit Profile</button>
        <button class="btn_logout" @click="logout()"><i class="fa-solid fa-arrow-right-from-bracket"></i>Log Out</button>
      </div>
    </div>
    <transition name="overlayPrototype">
         <div class="overLay" v-if="closeCssModal">
          <!-- <div class="overLay"> -->
            <div class="popUp">
              <div class="loader_popup" v-if="loaderShow">
                <Loader />
              </div>
                <i class="fa-solid fa-xmark close" @click="closeCssModalFun"></i>
                <h1>Edit your Profile</h1>
                <div class="input_fields">
                  <div class="input-group">
                    <label for="loction">Location</label>
                    <input type="text" id="loction" v-model="locationEdit" placeholder="Add Location" autocomplete="off">
                  </div>
                  <div class="input-group">
                    <label for="bio">Bio</label>
                    <textarea name="bio" id="bio" rows="6" placeholder="Add your short Bio" v-model="biodataEdit" autocomplete="off"></textarea>
                  </div>
                  <div class="input-group combined_group">
                    <div>
                      <label for="website">Website</label>
                      <input type="text" id="website" v-model="websiteEdit" placeholder="Add your Website" autocomplete="off">
                    </div>
                    <div>
                      <label for="company">Company</label>
                      <input type="text" id="company" v-model="companyEdit" placeholder="Add your Company" autocomplete="off">
                    </div>
                  </div>
                  <button @click="update()" class="sign">Edit</button>
                </div>
            </div>
        </div>
      </transition>
  </section>
  <Footer />
</template>
  
  <script>
  import Loader from "../components/Loader.vue"
  import { computed, mounted } from 'vue';
  import { useStore } from 'vuex';
  import { ref, onMounted } from 'vue';
  import Header from "../components/Header.vue"
  import Footer from "../components/Footer.vue"
  import { onAuthStateChanged } from "firebase/auth";
  import { getAuth, updateProfile } from 'firebase/auth';
  import { db } from "../main"
  import { useRouter } from 'vue-router';
  import { collection, getDocs, getDoc, doc, updateDoc, where } from "firebase/firestore";
  
  export default {
    name: "UserProfile",
    components:{Header, Footer, Loader},
    computed: {
        user() {
          const store = useStore();
          return store.state.user;
        },
    },
    data() {
      return{
        fromWebsitePerformance: true
      }
    },
    setup() {
        const store = useStore(); // Access the Vuex store
        const user = computed(() => store.state.user);
        const loaderShow = ref(false)
        const userFrom = ref("")
        const users = ref([]);
        const uid = ref("")
        const location = ref("")
        const locationEdit = ref("")
        const biodata = ref("")
        const biodataEdit = ref("")
        const website = ref("")
        const websiteEdit = ref("")
        const company = ref("")
        const companyEdit = ref("")
        const auth = getAuth();
        const closeCssModal = ref(false)
        const router = useRouter();
        onMounted(()=>{
          onAuthStateChanged(auth, (user) => {
            if (user) {
              const timestamp = parseInt(user.metadata.createdAt);
              const date = new Date(timestamp);
              const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              userFrom.value = formattedDate;
              const uid = user.uid;
              console.log(uid)
            } else {
              console.log("Not Found");
            }
          });
          // ----------------- Fetching Users from Firestore -------------------
          // const querySnapshot = await getDocs(collection(db, "users"))
          // querySnapshot.forEach((user) => {
          //   const newPosts = JSON.parse(JSON.stringify({...user.data(), id: user.id}))
          //   posts.value.push(JSON.parse(JSON.stringify(newPosts)));
          //   console.log(users.value)
          // });
        })
        onMounted(async()=>{
          loaderShow.value = true;
          onAuthStateChanged(auth, async(user) => {
            if (user) {
              const docRef = doc(db, "users", user.uid);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                location.value = docSnap.data().location;
                biodata.value = docSnap.data().bio;
                website.value = docSnap.data().website;
                company.value = docSnap.data().company;
              }
            }
            loaderShow.value = false;
          });
        })
        const update = async() =>{
          loaderShow.value = true;
          const washingtonRef = doc(db, "users", auth.currentUser.uid);
          await updateDoc(washingtonRef, {
            location : locationEdit.value ? locationEdit.value : "",
            bio : biodataEdit.value ? biodataEdit.value : "",
            website: websiteEdit.value ? websiteEdit.value : "",
            company: companyEdit.value ? companyEdit.value : ""
          });
          loaderShow.value = false;
          closeCssModal.value = false;

          onAuthStateChanged(auth, async(user) => {
            if (user) {
              const docRef = doc(db, "users", user.uid);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                location.value = docSnap.data().location;
                biodata.value = docSnap.data().bio;
                website.value = docSnap.data().website;
                company.value = docSnap.data().company;
              }
            }
          });
        }
        const logout = async () => {
            await store.dispatch('logoutUser');
            router.push({ name: 'login' }); // Adjust the route name as needed
        };
        const editUserData = () =>{
          locationEdit.value = location.value
          biodataEdit.value = biodata.value
          websiteEdit.value = website.value
          companyEdit.value = company.value
          closeCssModal.value = true
          setTimeout(() => {
            document.querySelector('#loction').focus();
          }, 100);
        }
        const closeCssModalFun = () =>{
          closeCssModal.value = false
        }
        return {
            user,
            userFrom,
            users,
            location,
            uid,
            locationEdit,
            editUserData,
            biodata,
            website,
            websiteEdit,
            company,
            companyEdit,
            closeCssModal,
            closeCssModalFun,
            biodataEdit,
            loaderShow,
            update,
            logout
        };
    },
    methods: {
    
    },
  };
  </script>
  
  <style scoped>
  .hero-section {
      cursor: default;
      min-height: 80vh;
      background-color: #fafafa;
  }
  .container{
    width: 90%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block: 3.2rem;
  }
  .image{
    width: 13rem;
    height: 13rem;
    border-radius: 0.8rem;
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
  }
  .image .user_name{
    font-size: 4.8rem;
    color: #696969;
    font-weight: 800;
  }
  .img_not_present{
    background-color: rgba(0, 0, 0, 0.04);
  }
  .image img{
    width: 100%;
    height: 100%;
  }
  .user_container {
    position: relative;
    max-width: 660px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 2rem;
    background-color: #fff;
    border-radius: 0.6rem;
    box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .loader{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .user-photo img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .name_data{
    font-size: 2.4rem;
    font-weight: 800;
    margin-bottom: 0.6rem;
  }
  .email_data{
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 1.2rem;
  }
  .user_from{
    font-size: 1.6rem;
    
  }
  .email_verified{
    font-size: 1.8rem;
    display: inline-block;
    padding: 0.6rem 1.6rem;
    border-radius: 10rem;
    color: rgb(45, 143, 110);
    background-color: rgb(211, 255, 238);
    margin-bottom: 1.2rem;
  }
  .action_btns{
    margin-top: 2.4rem;
    display: flex;
    justify-content: center;
  }
  .edit_profile{
    display: flex;
    align-items: center;
    cursor: pointer;
    background-image: radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );
    padding: 1.6rem 2.8rem;
    text-align: center;
    font-size: 1.6rem ;
    color: rgba(17, 24, 39, 1);
    border: none;
    border-radius: 0.6rem;
    font-weight: 600;
    margin-right: 1.6rem;
    color: #FFF;
  }
  .edit_profile i{
    font-size: 2.4rem;
    margin-right: 1rem;
  }
  .btn_logout{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.6rem;
    border: 2px solid rgba(83,138,214,1);
    font-size: 1.6rem;
    background-color: transparent;
    padding: 1.6rem 2.8rem;
  }
  .btn_logout i{
    color: rgba(83,138,214,1);
    font-size: 2.4rem;
    margin-right: 1rem;
  }
  .overlayPrototype-enter-from,
.overlayPrototype-leave-to{
  opacity: 0;
  visibility: hidden;
}
.overlayPrototype-enter-active,
.overlayPrototype-leave-active {
  transition: all 0.5s ease;
}
.overLay{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popUp{
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 520px;
    position: relative;
    width: 90%;
  }
  .loader_popup{
    position: absolute;
    right: 60px;
    top: 10px;
  }
  .popUp h1{
    margin: 0 0 20px 0;
    font-size: 2.4rem;
    padding-bottom: 0.6rem;
  }
  .close{
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 24px;
    color: #007bff;
    cursor: pointer;
  }
.input-group {
  margin-top: 0.25rem;
  margin-top: 1.6rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.combined_group{
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
.combined_group div{
  width: 50%;
}
.combined_group div input{
  width: 100%;
}
.input-group label {
  display: block;
  font-size: 1.6rem;
  color: #2a3846;
  margin-bottom: 8px;
}
.input-group input::placeholder,
.input-group textarea::placeholder{
  color: #9ba6b1;
  font-size: 16px !important ;
}
.input-group input,
.input-group textarea {
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgb(194, 200, 209);
  outline: 0;
  padding: 0.75rem 1rem;
  font-size: 16px;
  color: rgba(55, 65, 81, 1);
}
.input-group input:focus,
.input-group textarea:focus {
  border-color: rgba(83,138,214,1);
}
.sign {
  cursor: pointer;
  display: block;
  width: 100%;
  background-image: radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% );
  padding: 1.2rem;
  text-align: center;
  font-size: 1.6rem;
  color: rgba(17, 24, 39, 1);
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  margin-top: 2rem;
}
@media screen and (max-width: 650px){
  .popup h1{
    
  }
}
  </style>
  