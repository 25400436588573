<template>
    <section class="hero-section">
      <Header :fromWebsitePerformance="fromWebsitePerformance" :user="user"/>
      <div class="container">
        <p class="info" @click="openCssGuideleine">How to use</p>
        <div class="gradient-container">
          <div class="gradient" :style="{ background: gradientStyle }"></div>
          <div class="controls">
            <div class="color-controls">
              <div class="color-input" v-for="(colorInput, index) in colorInputs" :key="index">
                <input type="color" class="color add_color" v-model="colorInput.color">
                <button class="delete-color" @click="deleteColor(index)" :disabled="colorInputs.length == 1" :class="{'disableAdd': colorInputs.length == 1}"><i class="fa-solid fa-xmark delete_icon"></i></button>
              </div>
              <button id="addColor" @click="addColorInput" :disabled="colorInputs.length >= 6" :class="{'disableAdd': colorInputs.length >= 6}"><i class="fa-solid fa-plus add_plus"></i></button>
            </div>
            <div class="direction-controls">
              <label>Gradient Direction (<span>{{ gradientDegree }}</span> degrees):</label>
              <input type="range" class="direction-slider slider" min="0" max="360" step="5" v-model="gradientDegree">
            </div>
            <div class="css_output">
              <textarea class="textArea" id="cssCode" rows="6" readonly v-model="cssCode"></textarea>
              <p @click="copyTextCss($event, cssCode)" class="copy">Copy</p>
            </div>
          </div>
        </div>
      </div>
      <transition name="overlayPrototype">
        <div class="overLay" v-if="closeCssModal" @click.self="closeCssModalFun">
            <div class="popUp">
                <i class="fa-solid fa-xmark close" @click="closeCssModalFun"></i>
                <h1>How to Use</h1>
                <p>Click on <span>Plus Icon</span> to add Maximum 6 Colors.</p>
                <p>Click on the particular <span>Color box</span> to change the Color.</p>
                <p>Use the <span>Cross icon</span> to delete the color (Hover over the color).</p>
                <p>Adjust the <span>Slider</span> to Change degree of Rotation of the Gradient.</p>
                <p class="last">Click <span>Copy Button</span> to copy the Generated CSS.</p>
            </div>
        </div>
      </transition>
    </section>
    <Footer />
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useHead } from '@vueuse/head'
  import Header from "../components/Header.vue"
  import Footer from "../components/Footer.vue"
  export default {
    name: "CssGradient",
    components:{Header, Footer},
    computed: {
      user() {
        const store = useStore();
        return store.state.user;
      },
    },
    setup() {
    const store = useStore(); // Access the Vuex store
    const user = computed(() => store.state.user);
    useHead({
      // Can be static or computed
      title: 'CSS Gradient Generator: UI Assembly',
      meta: [
        {
          name: `CSS Gradient Generator: UI Assembly`,
          content: 'Create beautiful Gradients in the web and use it in your website. Get the generated CSS code and make stumming websites.',
        },
        ],
      })
      return {
        user,
      };
    },
    data() {
      return{
            fromCss: true,
            colorInputs: [
          {
            color: "#3370FF",
          },
        ],
        gradientDegree: 0,
        closeCssModal: false,
        }
    },
    computed: {
      gradientStyle() {
        const numColors = this.colorInputs.length;
        let gradientColors = "";
  
        if (numColors === 1) {
          gradientColors = this.colorInputs[0].color;
        } else {
          for (let i = 0; i < numColors; i++) {
            const position = (i / (numColors - 1)) * 100;
            gradientColors += `${this.colorInputs[i].color} ${position}%, `;
          }
          gradientColors = gradientColors.slice(0, -2); // Remove the last comma and space
        }
  
        const gradientStyle = numColors === 1 ? `${gradientColors}` : `linear-gradient(${this.gradientDegree}deg, ${gradientColors})`;
  
        return gradientStyle;
      },
      cssCode() {
        return `background: ${this.gradientStyle};`;
      },
    },
    mounted(){
      this.openCssModal()
    },
    methods: {
      addColorInput() {
        let colors = ['#6AEEF0', '#E7FF8F', '#F5A356', '#BC87E8', '#FFB494'];
        let random = Math.round(Math.random()*4);
        if (this.colorInputs.length < 6) {
          this.colorInputs.push({
            color: colors[random],
          });
        }
      },
      openCssModal(){
        setTimeout(() => {
            this.closeCssModal = true
        }, 1000);
      },
      openCssGuideleine(){
        this.closeCssModal = true
      },
      closeCssModalFun(){
        this.closeCssModal = false
      },
      deleteColor(index) {
        this.colorInputs.splice(index, 1);
      },
      copyTextCss(e, value) {
        navigator.clipboard.writeText(value)
        .then(() => {
          e.target.innerText = 'Copied'
          setTimeout(() => {
            e.target.innerText = 'Copy'
          }, 2000);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .hero-section {
      cursor: default;
      background: #03001C;
      /* background: linear-gradient(92.99deg,rgba(1,72,186,0.05) 1.35%,rgba(1,165,255,0.05) 104.47%); */
      /* position: relative; */
      min-height: 90vh;
  }
  .gradient-container{
    display: flex;
    align-items: flex-start;
    gap: 2.4rem;
  }
  .color-controls{
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.8rem;
    row-gap: 4rem;
    align-items: flex-start;
    margin-bottom: 5.6rem;
  }
  .color-input{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .color-input:hover .delete-color{
    bottom: -4rem;
  }
  .direction-controls{
    display: flex;
    flex-direction: column;
  }
  .direction-controls label{
    font-size: 1.6rem;
    color: #797979;
    margin-bottom: 2.4rem;
  }
  .direction-controls label span{
    font-weight: 500;
    color: #3370FF;
  }
  #addColor{
    margin-top: 5px;
    width: 6rem;
    height: 6rem;
    /* background-color: #8CABFF; */
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }
  .disableAdd{
    cursor: not-allowed !important;
  }
  .delete_icon{
    font-size: 2rem;
  }
  .delete-color{
    position: absolute;
    z-index: 0;
    bottom: 4px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background-color: rgba(252, 80, 80, 0.1);
    border: none;
    color: #fc5050;
    width: 4rem;
    height: 4rem;
    border-radius: 4px;
  }
  .add_plus{
    font-size: 3.2rem;
    color: #4477CE;
  }
  .heading{
    display: inline-block;
    font-size: 2.8rem;
    color: #fff;
    margin-bottom: 2rem;
  }
  .info{
    font-size: 1.8rem;
    background-color: #f1f8ff;
    display: inline-block;
    padding: 0.4rem 3.2rem;
    border-radius: 10rem;
    color: #2580ca;
    cursor: pointer;
    margin-bottom: 1.2rem;
  }
  .gradient{
    max-width: 720px;
    width: 40%;
    border-radius: 12px;
    aspect-ratio: 1/1;
    /* height: 200px; */
}
.container{
  width: 90%;
  margin-block: 3.2rem;
}
.add_color {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    width: 7.2rem;
    height: 7.2rem;
    border: none;
    cursor: pointer;
    z-index: 2;
  }
.add_color::-webkit-color-swatch {
    border-radius: 6px;
    border: 2px solid #fff;
  }
  .add_color::-moz-color-swatch {
    border-radius: 50%;
    border: 7px solid #000000;
  }
  .direction-slider{
    max-width: 30rem;
    width: 100%;
    background-color: rgba(37, 128, 202, 0.2);
  }
  .controls{
    width: 52%;
  }
  .direction-slider::-webkit-slider-thumb {
  width: 4rem;
  height: 4rem;
  border: 3px solid #fff;
  border-radius: 50%;
}
.textArea{
  margin-top: 5rem;
  border-radius: 0.6rem;
  width: 100%;
  min-height: 15rem;
  resize: vertical;
  padding: 10px;
  font-size: 2rem;
  color: #c2c2c2;
  border: 1px solid #4477CE;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: default;
}
.css_output{
  position: relative;
}
.copy{
  position: absolute;
  bottom: 1.2rem;
  right: 1.2rem;
  background-color: #dde9ff;
  color: #4477CE;
  font-size: 1.6rem;
  border-radius: 4px;
  padding: 0.4rem 1.6rem;
  cursor: pointer;
}
.textArea:active,
.textArea:focus{
  outline: none;
  border: 1px solid #4477CE;
}
  /* Styles as per your previous CSS */
  .overlayPrototype-enter-from,
.overlayPrototype-leave-to{
  opacity: 0;
  visibility: hidden;
}
.overlayPrototype-enter-active,
.overlayPrototype-leave-active {
  transition: all 0.5s ease;
}
.overLay{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popUp{
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 520px;
    position: relative;
    width: 90%;
  }
  .close{
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 24px;
    color: #007bff;
    cursor: pointer;
  }
  .popUp h1{
    margin: 0 0 20px 0;
    font-size: 2.4rem;
    border-bottom: 1px solid #c9c9c9;
    padding-bottom: 0.6rem;
  }
  .popUp p{
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
  .popUp .last{
    margin-bottom: 0;
  }
  .popUp span{
    color: #007bff;
    font-weight: 700;
  }
  @media screen and (max-width: 1024px) {
    .delete-color{
        bottom: -4rem;
    }
  }
  @media screen and (max-width: 700px) {
    .gradient-container{
      flex-direction: column;
    }
    .gradient{
        aspect-ratio: 16/9;
        width: 100%;
        max-width: unset;
    }
    .controls{
        width: 100%;
    }
  }
  @media screen and (max-width: 425px) {
    .add_color {
      width: 12.2rem;
      height: 12.2rem;
    }
    #addColor{
      width: 10rem;
      height: 10rem;
    }
    .delete_icon{
      font-size: 4rem;
    }
    .textArea{
      font-size: 2.8rem;
      color: #fff;
    }
    .copy{
      font-size: 2rem;
    }
    .direction-controls label{
        font-size: 2.4rem;
    }
    .direction-slider{
      max-width: unset;
    }
    .popUp h1{
      font-size: 3rem;
    }
    .popUp p{
      font-size: 2.4rem;
    }
  }
  </style>
  